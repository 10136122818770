
import React from 'react'
import { getAnalytics, logEvent } from "firebase/analytics";

interface AnalyticsEvent {
    name: string;
  }

const FirebaseAnalytics: React.FC<AnalyticsEvent> = ({name}) => {

	const analytics = getAnalytics()
	window.addEventListener('CookieScriptCategory-performance', function() {
		(window as any)._ic.allowPerformance = true;
		logEvent(analytics, name);
	})

    return null
}


export default FirebaseAnalytics
