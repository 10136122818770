import React, { Suspense } from 'react'
import {
	Box,
	Grid,
	Button,
	TextField,
	LinearProgress,
	Typography,
	makeStyles,
} from '@material-ui/core'
import { Wifi, PlusCircle } from 'mdi-material-ui'
import useApi from '~/hooks/use-api'
import ErrorBoundary from '~/components/error-boundary'
import PasswordGenerator from './credentials/password-generator'
import PasswordUpdate from './credentials/password-updater'
import DevicesList from './credentials/devices-list'
import { Route, Routes, useNavigate } from 'react-router-dom'
import {useSelector} from "react-redux";
import {RootState} from "~/store";

const useStyles = makeStyles((theme) => {
	return {
		center: {
			justifyContent: "center",
		},
		full:{
			width: '100%'
		},
		infoGrid:{
			marginBottom: '20px',
		},
		grid: {
			display: 'grid'
		},
		logo: {
			display: 'grid',
			marginBottom: '10px',
		},
		deviceList:{
			marginTop: '20px',
			marginBottom: '10px'

		}
	}
})

const UserInfo: React.FC = () => {
	const classes = useStyles()
	const profileInfo = useSelector( (state: RootState) => { return state?.user?.user;  } );

	return (
		<>
			<Grid container spacing={2} className={classes.infoGrid}>

				<Grid item xs={12} className={classes.logo}>
					<Wifi color='primary' style={{ fontSize: 100 }} />
				</Grid>
				<Grid item xs={12}>
					<TextField
						className={classes.full}
						label='Nome rete'
						defaultValue='NanaBianca'
						InputProps={{
							readOnly: true,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
				<TextField
						className={classes.full}
						label='Username'
						defaultValue={profileInfo?.Email}
						InputProps={{
							readOnly: true,
						}}
					/>
				</Grid>
			</Grid>
		</>
	)
}

const NetworkTab: React.FC = () => {
	const navigate = useNavigate()
	const classes = useStyles()
	function handleClick() {
		navigate('credentials')
	}

	return (
		<Grid container justifyContent={"center"}>
			<Grid item xs={12} md={6}>
			<Box padding={1}>
				<ErrorBoundary>
					<Suspense fallback={<LinearProgress />}>
						<UserInfo/>
					</Suspense>
				</ErrorBoundary>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant='subtitle1'>
							Crea una password per ogni tuo dispositivo.
							Per la tua sicurezza ti consigliamo di cambiare la password dei tuoi dispositivi una volta al mese.
							Ogni responsabilità sulla tua navigazione internet rimarrà a tuo carico. Fai il bravo :-)
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='subtitle1'>
							Segui le <a href="https://docs.nanabianca.it/connessione-alla-rete" target="blank">istruzioni</a> per connetterti
						</Typography>
					</Grid>
					<Grid item xs={12}  className={classes.deviceList}>
						<DevicesList />
					</Grid>
					<Grid item xs={12} className={classes.grid}>
						<Button onClick={handleClick}>
							<PlusCircle color="primary" />
							  Aggiungi dispositivo
						</Button>
					</Grid>

				</Grid>
			</Box>
			</Grid>
		</Grid>
	)
}

const NetworkSwitch: React.FC = () => (
	<Routes>
		<Route element={<NetworkTab />} path='/' />
		<Route element={<PasswordGenerator />} path='credentials' />
		<Route element={<PasswordUpdate />} path='credentials/update/:documentId' />
	</Routes>
)

export default NetworkSwitch
