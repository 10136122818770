import {
	makeStyles,
	Typography,
	Button,
	Box,
	Grid,
	DialogProps,
	Snackbar,
	IconButton,
	Divider,

} from '@material-ui/core'
import { Wifi } from 'mdi-material-ui'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import Generate from 'generate-password'
import clsx from 'clsx'
import PropTypes, { InferProps } from 'prop-types'
import React, { useCallback, useState, useEffect } from 'react'
import { Device } from '~/models'
import { DEVICES } from '~/constants'
import SuccessDialog from '~/components/success-dialog'
import { Close } from 'mdi-material-ui'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles(() => ({
	password: {
		width: '100%',
		justifyContent: 'center',

	},
	user: {
		color: '#FFFFFF !important',
	},
	box: {
		border: '1px solid #FFFFFF',
		borderRadius: '15px',
		fontFamily: 'Monospace',
		fontWeight: 700,
		fontSize: '10px',
		letterSpacing: '0',
		lineHeight: '0px',
		padding: '5px',
		paddingLeft: '25px!important',
		display: 'grid',
		alignItems: 'center',
		minHeight: '55px',
	},
	grid:{
		minHeight: '55px',
		marginTop: '10px'
	},
	fullwidth: {
		width: '100%',
	},
	dialog: {
		padding: '25px',
	},
	btnEnd: {
		position: 'relative',
		bottom: '0px',
		width: '95%',
		marginTop: '80px',
	},
	infoColor:{
		color: '#FA0056',
		textDecoration: 'none'
	}
}))


const setTokenData = async (
	deviceName: string,
	deviceIcon: string,
	documentId: string | null
) => {
	const user = await firebase.auth().currentUser
	if (!user) return ''

	const documentRef = firebase
		.firestore()
		.collection('network-credentials')
		.doc(user.uid)
		.collection('credentials-list')

	const pwd = Generate.generate({
		excludeSimilarCharacters: true,
		length: 6,
		lowercase: true,
		numbers: true,
		strict: true,
		uppercase: true,
	})

	const data = {
		password: pwd,
	}
	if (documentId === null) {
		const deviceInfo = {
			deviceName: deviceName,
			username: user.email,
			deviceIcon: deviceIcon,
		}
		const newData = { ...deviceInfo, ...data }

		await documentRef.doc().set(newData)

	} else {
		await documentRef.doc(documentId).update(data)

	}

	return pwd
}

const PasswordManagerProps = {
	deviceName: PropTypes.string.isRequired,
	deviceIcon: PropTypes.string.isRequired,
	username: PropTypes.string,
	documentId: PropTypes.string,
}

const PasswordManager: React.FC<DialogProps & InferProps<typeof PasswordManagerProps>> = ({
	children = null,
	deviceName,
	deviceIcon,
	username = null,
	documentId = null,
	...rest
}) => {
	const classes = useStyles()
	const navigate = useNavigate()
	const [password, setPassword] = useState('')
	const [copySnackBar, setCopySnackBar] = React.useState(false)
	const [snackMessage, setSnackMessage] = React.useState('')

	const copyToClipboard = () => {
		if (password !== '') {
			if (navigator !== undefined && navigator.clipboard !== undefined) {
				navigator.clipboard.writeText(password)
				setSnackMessage('Copiato!')
				setCopySnackBar(true)
			} else {
				// navigator.clipboard is undefined on mobile without https
				setSnackMessage(
					'Error: Unable to save to clipboard on insecure protocol'
				)
				setCopySnackBar(true)
			}
		}
	}

	const handleShowPasswordDialogClose = useCallback(async () => {
		navigate(-1)
	}, [navigate])

	const handleCloseSnack = () => {
		setCopySnackBar(false)
	}

	useEffect(() => {
		if (rest.open) {
			setTokenData(
				deviceName,
				deviceIcon,
				documentId).then(result => {
					setPassword(result)
				})
		}
	}, [rest.open])


	return (
		<>
			<SuccessDialog {...rest}
				onClose={handleShowPasswordDialogClose}

				className='passwordDialog'
			>
				<Box className="passwordTitle">
					Ecco il tuo <span className='passwordTitleBlack'>username</span>

				</Box>
				<Box className="passwordTitle" marginBottom={2}>
					E la tua <span className='passwordTitleBlack'>password</span>
				</Box>

				<Grid
					container
					justifyContent='center'
					className={classes.password}

				>
					<Grid item xs={12} className={classes.grid}>
					<Typography variant='subtitle2'>Rete</Typography>
						<Box className={classes.box}>
							<h2>{<Wifi  fontSize='small'/>} NanaBianca</h2>
						</Box>
					</Grid>
					<Grid item xs={12}  className={classes.grid}>
					<Typography variant='subtitle2'>Username</Typography>
						<Box className={classes.box}>
							<h2>{username}</h2>
						</Box>
					</Grid>
					<Grid item xs={12}  className={classes.grid}>
					<Typography variant='subtitle2'>Password</Typography>
						<Box className={classes.box}>
							<h1 style={{letterSpacing: '6px'}}>{password}</h1>
						</Box>
					</Grid>
					<Grid item xs={12} className='passwordInfo'>
						<Typography variant='subtitle2'>
							<span className={classes.infoColor}>1</span> Copia la password
						</Typography>

					</Grid>
					<Grid item xs={12} >
						<Typography variant='subtitle2'>
						<span className={classes.infoColor}>2</span> Cerca la rete WIFI
							&quot;<span className={classes.infoColor}>NanaBianca</span>&quot;
						</Typography>


					</Grid>
					<Grid item xs={12}>
						<Typography variant='subtitle2'>

						<span className={classes.infoColor}>3</span> Inserisci il tuo nome utente e la tua
							password
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='subtitle2'>

						<span className={classes.infoColor}>4</span> Sei pronto per navigare 😎
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.btnEnd} >
					<Typography variant='subtitle2' ><a href="https://docs.nanabianca.it/network" target="blank" className={classes.infoColor}>Hai bisogno di aiuto?</a></Typography>
						<Button
							onClick={copyToClipboard}
							className={classes.fullwidth}
							variant='contained'
							color='secondary'
						>COPIA LA PASSWORD</Button>
					</Grid>

				</Grid>

			</SuccessDialog>
			<Snackbar
				open={copySnackBar}
				autoHideDuration={3000}
				message={snackMessage}
				onClose={handleCloseSnack ?? undefined}
				action={
					!!handleCloseSnack && (
						<IconButton
							aria-label='close'
							color='inherit'
							onClick={handleCloseSnack}
							size='small'
						>
							<Close fontSize='small' />
						</IconButton>
					)
				}
			/>
		</>
	)


}



export default PasswordManager
