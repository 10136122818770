import axios from 'axios'

import ApiAdapter from './adapter'
import { apiRequestInterceptor, apiResponseInterceptor } from './interceptors'
import {API_ADMIN_URL, API_BASE_URL} from '~/settings'

const API_TIMEOUT = 55 * 1000

const ApiInstance = axios.create({
	adapter: ApiAdapter,
	baseURL: API_BASE_URL,
	timeout: API_TIMEOUT,
})

ApiInstance.interceptors.request.use(apiRequestInterceptor)
ApiInstance.interceptors.response.use(apiResponseInterceptor)

export const AdminApiInstance = axios.create({
	adapter: ApiAdapter,
	baseURL: API_ADMIN_URL,
	timeout: API_TIMEOUT,
})

AdminApiInstance.interceptors.request.use(apiRequestInterceptor)
AdminApiInstance.interceptors.response.use(apiResponseInterceptor)

export default ApiInstance
