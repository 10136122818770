import { DialogProps, IconButton, Typography } from '@material-ui/core'
import { Close } from 'mdi-material-ui'
import React, { useCallback } from 'react'

import Flex from '~/components/flex'
import FullScreenDialog from '~/components/full-screen-dialog'
import Header from '~/components/header'

const SuccessDialog: React.FC<DialogProps> = (props) => {
	const { children, onClose, ...rest } = props

	const handleOnCloseClick = useCallback(
		(event) => {
			onClose?.(event, 'escapeKeyDown')
		},
		[onClose]
	)

	return (
		<FullScreenDialog {...rest} maxWidth='md' onClose={onClose}>
			<Flex bgcolor='primary.main' color='primary.contrastText'>
				<Header
					color='transparent'
					startButton={
						<IconButton
							aria-label='close'
							color='inherit'
							edge='start'
							onClick={handleOnCloseClick}
						>
							<Close />
						</IconButton>
					}
				/>

				<Flex alignItems='center' justifyContent='center' p={12}>
					<Typography variant='h1'>{children}</Typography>
				</Flex>
			</Flex>
		</FullScreenDialog>
	)
}

export default SuccessDialog
