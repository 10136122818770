import { Dialog, useMediaQuery, useTheme, DialogProps } from '@material-ui/core'
import React from 'react'

const FullScreenDialog: React.FC<DialogProps> = (props) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	return <Dialog maxWidth='xs' {...props} fullScreen={fullScreen} />
}

export default FullScreenDialog
