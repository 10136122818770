import React, {
	useState,
	useEffect,
	Suspense,
} from 'react'
import Header from '~/components/header'
import {
	Grid,
	TextField,
	Box,
	Backdrop,
	CircularProgress,
	Button,
	makeStyles,
	LinearProgress,
} from '@material-ui/core'
import ErrorBoundary from '~/components/error-boundary'
import UserAvatar from '~/components/user-avatar'
import ApiInstance from '~/api'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "~/store";
import {setUser} from "~/store/user/actions";

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%',
	},
	grid: {
		padding: theme.spacing(3),
	},
	button: {
		//padding: theme.spacing(3),
		width: '100%',
		marginTop: '50px',
	},
}))

const ProfileEdit: React.FC = () => {
	const classes = useStyles()
	const [loading, setLoading] = useState(true)
	const dispatch = useDispatch();

	const profileInfo = useSelector( (state: RootState) => { return state?.user?.user;  } );
	const [name, setName] = useState(profileInfo?.GuessedFirstName)
	const [surname, setSurname] = useState(profileInfo?.GuessedLastName)
	const [email, setEmail] = useState(profileInfo?.Email)

	useEffect(() => {
		setName(profileInfo?.GuessedFirstName)
		setSurname(profileInfo?.GuessedLastName)
		setEmail(profileInfo?.Email)
		setLoading(false)
	}, [profileInfo])

	async function handleClick() {
		if (profileInfo) {
			setLoading(true)

			profileInfo.FullName = `${name} ${surname}`;
			profileInfo.GuessedFirstName = `${name}`;
			profileInfo.GuessedLastName = `${surname}`;
			try {
				const response = await ApiInstance.post(
					'/profile',
					profileInfo
				)
				if (response !== undefined){
					setLoading(false);
					dispatch(setUser(profileInfo));
				}
			} catch (error) {
				setLoading(false)
			}
		}
	}

	const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value)
	}
	const handleSurname = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSurname(event.target.value)
	}

	return (
		<>
			<Header title='Dati Personali' className={"modalHeader"}/>
			<Backdrop open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Box padding={5}>
				<ErrorBoundary>
					<Suspense fallback={<LinearProgress />}>
						<UserAvatar />
					</Suspense>
				</ErrorBoundary>

				<form className={classes.form}>
					<Grid
						container
						justifyContent='center'
						className={classes.grid}
						spacing={4}
					>
						<Grid item xs={12}>
							<TextField
								className={classes.form}
								id='name'
								label='Nome'
								value={name}
								onChange={handleName}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								className={classes.form}
								id='surname'
								label='Cognome'
								value={surname}
								onChange={handleSurname}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								className={classes.form}
								label='Email'
								value={email}
								InputProps={{
										readOnly: true,
									}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								className={classes.button}
								onClick={handleClick}
								variant='contained'
								color='secondary'
							>
								Salva Modifiche
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</>
	)
}

export default ProfileEdit
