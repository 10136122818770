import {
	BookingsActionTypes,
	BookingsEvents,
	BookingsResources,
	SET_BOOKINGS_EVENTS,
	SET_BOOKINGS_RESOURCES
} from './types'

export function setBookingResources(bookings: BookingsResources): BookingsActionTypes {
	return {
		type: SET_BOOKINGS_RESOURCES,
		payload: bookings
	}
}

export function setBookingEvents(bookings: BookingsEvents): BookingsActionTypes {
	return {
		type: SET_BOOKINGS_EVENTS,
		payload: bookings
	}
}
