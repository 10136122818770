import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as NbWifiCastSvg } from '~/assets/images/icons/devices/nb-wifi-cast.svg'

function NbWifiCast(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbWifiCastSvg />
		</SvgIcon>
	)
}

export default NbWifiCast
