import moment, { Moment } from 'moment'

type momentPropTypeFunction = (
	props: { [key: string]: any },
	propName: string,
	componentName: string
) => Error | null

const momentRequiredPropType: momentPropTypeFunction = (
	props,
	propName,
	componentName
) => {
	const prop = props[propName]

	if (!moment.isMoment(prop))
		return new Error(
			`Invalid prop \`${propName}\` supplied to \`${componentName}\`: not a moment instance.`
		)

	if (!(prop as Moment).isValid())
		return new Error(
			`Invalid prop \`${propName}\` supplied to \`${componentName}\`: invalid moment instance.`
		)

	return null
}

const momentPropType: momentPropTypeFunction & {
	isRequired: momentPropTypeFunction;
} = (props, propName, componentName) => {
	const prop = props[propName]
	if (!prop) return null

	return momentRequiredPropType(props, propName, componentName)
}

momentPropType.isRequired = momentRequiredPropType

export { momentPropType }
