import { IconButton, IconButtonProps, Typography } from '@material-ui/core'
import PropTypes, { InferProps } from 'prop-types'
import React from 'react'

import Flex from './flex'

const LabelButtonProps = {
	children: PropTypes.element.isRequired,
	component: PropTypes.elementType,
	label: PropTypes.string.isRequired,
	to: PropTypes.string,
}

const LabelButton: React.FC<
	IconButtonProps & InferProps<typeof LabelButtonProps>
> = ({ children, label, ...rest }) => (
	<Flex alignItems='center' flex={0}>
		<IconButton color='inherit' {...rest}>
			{React.cloneElement(children, {
				color: 'inherit',
				fontSize: 'inherit',
			})}
		</IconButton>

		<Typography align='center' color='textPrimary' variant='subtitle2'>
			{label}
		</Typography>
	</Flex>
)

LabelButton.propTypes = LabelButtonProps

export default LabelButton
