import {Tabs, Tab, IconButton, Tooltip, Box, Grid, Hidden} from '@material-ui/core'
import { FacebookMessenger, Qrcode, Bell } from 'mdi-material-ui'
import React, { useCallback, Suspense } from 'react'
import { useLocation } from 'react-router-dom'
import NetworkBanner from '~/components/network-banner'

import useApi from '~/hooks/use-api'
import useDashboardBookingsTab, {
	ACTIVE_BOOKINGS_TAB,
	INACTIVE_BOOKINGS_TAB,
} from '~/hooks/use-dashboard-bookings-tab'

import Header from './header'
import ErrorBoundary from './error-boundary'
import moment from "moment";
import {useSelector} from "react-redux";
import {RootState} from "~/store";
import {NbQrCodeIcon} from "~/components/icons/devices/nb-qr-code-icon";
import NotificationsIcon from "~/components/icons/notifications";
import {Route, useNavigate} from "react-router-dom";
import { string } from 'prop-types'

const getTitleFromPath = (path: string, salutation: string): string|null => {
	switch (path) {
		case '/bookings':
			return 'Prenotazioni'
		case '/events':
			return 'Eventi'
		case '/profile':
			return 'Profilo'
		case '/network':
			return 'Internet Access'
		case '/dailypass':
			return 'Postazione'
		case '/food':
			return null
		case '/':
		default: {
			if (salutation) return `Ciao ${salutation}`
			return 'Ciao'
		}
	}
}

const DashboardHeaderFallback: React.FC<{ title?: string }> = (props) => {
	const location = useLocation()
	const navigate = useNavigate()

	const [bookingsTab, setBookingsTab] = useDashboardBookingsTab()

	const handleBookingsTabChange = useCallback(
		(event, value) => {
			setBookingsTab(value)
		},
		[setBookingsTab]
	)

	const navigateTo = (url: string) => {
        navigate(url)
	}

	return (
		<>
		<Box paddingX={2}>
			<Grid container style={{marginTop: '8px'}}>
				<Grid item className={"headerBarDateContainer"}>
					{moment().format("ddd D MMM")}
				</Grid>
			</Grid>
		</Box>

		<Header
			title={props.title}
			startButton={null}
			endButton={
				<>
					{/* <Tooltip aria-label='help' placement='bottom' title='Aiuto'>
						<IconButton
							aria-label='help'
							href='http://ff.nanabianca.it/2020'
							rel='noopener noreferrer'
							target='_blank'
						>
							<FacebookMessenger color={"primary"}/>
						</IconButton>
					</Tooltip> */}
					
						<Box marginLeft={2} marginTop={2} marginBottom={1}>
						<Tooltip aria-label='Notifications' placement='bottom' title='Notifications'>
							<IconButton
								onClick={() => navigateTo('/notifications')}
								aria-label='Notifications'
							>
								<NotificationsIcon color="primary" />
							</IconButton>
						</Tooltip>
						</Box>

					<Hidden smUp>
						<Box marginLeft={2} marginTop={2} marginBottom={1}>
						<Tooltip aria-label='Membership card' placement='bottom' title='Membership card'>
							<IconButton
								onClick={() => navigateTo('/profile/qrcode')}
								aria-label='Membership card'
							>
								<NbQrCodeIcon/>
							</IconButton>
						</Tooltip>
						</Box>
					</Hidden>
					{
					/*
					<Tooltip
						aria-label='qr-code'
						placement='bottom'
						title='Codice QR'
					>

						<IconButton aria-label='qr-code' edge='end'>
							<Qrcode />
						</IconButton>
					</Tooltip>
					*/}
				</>
			}
		>
			{location.pathname === '/' && (
				<NetworkBanner/>
			)}

			{location.pathname === '/bookings' && (
				<>
					<Tabs
						indicatorColor='primary'
						onChange={handleBookingsTabChange}
						textColor='primary'
						value={bookingsTab}
						variant='fullWidth'
					>
						<Tab label='Attive' value={ACTIVE_BOOKINGS_TAB} />
						<Tab label='Passate' value={INACTIVE_BOOKINGS_TAB} />
					</Tabs>
				</>
			)}
		</Header>
		</>
	)
}

const DashboardHeader: React.FC = () => {
	const location = useLocation()
	/*
	const { data } = useApi<{ Salutation: string }>(
		'/profile?_resource=Coworker'
	)
	*/
	const profileInfo = useSelector( (state: RootState) => { return state?.user?.user;  } );

	const title = getTitleFromPath(location.pathname, profileInfo ? profileInfo.Salutation : '' );


	return (
		<>
			{title != null && <DashboardHeaderFallback title={title} />}
		</>
	)
}

const DashboardHeaderSuspense: React.FC = () => (
	<ErrorBoundary>
		<Suspense fallback={<DashboardHeaderFallback />}>
			<DashboardHeader />
		</Suspense>
	</ErrorBoundary>
)

export default DashboardHeaderSuspense
