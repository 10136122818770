import React, { Suspense, useEffect, useCallback } from 'react'
import {useNavigate} from 'react-router-dom'
import Header from '~/components/header'
import { Notification } from '~/models'
import {
    Grid,
    Box,
    makeStyles,
    LinearProgress,
    IconButton,
    Typography,
    Divider,
    Card,
    CardMedia,
} from '@material-ui/core'
import {ArrowLeft} from 'mdi-material-ui'
import ErrorBoundary from '~/components/error-boundary'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import {GetNotifications, SetUserLastNotifyTabOpen} from '~/services/notifications-service'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    notificationReadMore: {
        fontSize: '16px',
        textAlign: 'right',
        fontWeight: 600,
    },
    notificationLink: {
        color: '#FA0056',
        textDecoration: 'none'
    },
    divider:{
        marginTop: '9px'
    },
    notificationImage:{
        boxShadow: '0px 0px 0px 0px',
        marginTop: '10px',
        marginBottom: '10px',
    }
}))


const getParam = (param:string )=> {
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
}
const isHighlighted = ((guid: string)=> {
    const notification_id = getParam('notification_id')
    return notification_id === guid
})

const HeaderBackButton: React.FC = () => {
	const navigate = useNavigate()
	const handleClick = useCallback(() => {
        if (getParam('notification_id')){
            navigate('/')
        }
        else{
            navigate(-1)
        }
	}, [navigate])

	return (
		<IconButton color='inherit' edge='start' onClick={handleClick}>
			<ArrowLeft color="primary"/>
		</IconButton>
	)
}

const NotificationItem: React.FC<Notification> = (notification) => {
    const classes = useStyles()
    const getDescription = () => {
	    console.log(notification.title, notification.content.length)
       if (notification.content.length >=400) {
            return `${notification.content.slice(0, 400)}...`
        }
        return notification.content
    }
    const getDate = () => {
        let date = ''
        if (notification.pubDate) {
            date = moment(notification.pubDate).format('DD MMMM YYYY')
        }
        return date
    }

    return (
        <>

        <Grid item xs={12} sm={12} md={12} zeroMinWidth  >
            <Box padding={1} className={isHighlighted(notification.guid)? 'shake-horizontal': ''}>
                <Typography gutterBottom className='notificationDate' >
                    {getDate()}
                </Typography>
                <Typography component="div" className='notificationTitle' >
                    {notification.title}
                </Typography>
               {notification.imgUrl &&(
                    <Card className={classes.notificationImage}>
                        <CardMedia
                            component='img'
                            image={notification.imgUrl}
                            alt="Notification image"
                        />
                    </Card>
                )}
                <Typography variant="body2" className='notificationBody'>
                    {getDescription()}
                </Typography>
                {notification.link && notification.type !== 'SYSTEM' && (
                    <Typography className='notificationReadMore'>
                        <a href={notification.link} className={classes.notificationLink} target='_blank'>Leggi di piú</a>
                    </Typography>
                )}
            </Box>
            <Divider className={classes.divider}/>
        </Grid>
        </>
    )
}

const NotificationsTab: React.FC = () => {
    const classes = useStyles()
    const [notifications, setNotifications] = React.useState<Notification[]>([]);

    useEffect(() => {
        (async () => {
            const nots = await GetNotifications()
            setNotifications(nots)
            await SetUserLastNotifyTabOpen()
        })()

    }, [])
    return (
        <>
            <Header title='Centro notifiche' className={"modalHeader"} startButton={<HeaderBackButton/>} />
            <ErrorBoundary>
                <Suspense fallback={<LinearProgress />}>
                    <Box padding={2} paddingBottom={16}>
                        <Grid container spacing={2} className='notificationsGridContainer' >
                            {notifications
                                .map((value: Notification) => (
                                    <NotificationItem key={value.link} {...value} />
                                ))}
                        </Grid>
                    </Box>
                </Suspense>
            </ErrorBoundary>

        </>

    )
}

export default NotificationsTab
