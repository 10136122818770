import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as NbWifiPrinterSvg } from '~/assets/images/icons/devices/nb-wifi-printer.svg'

function NbWifiPrinter(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbWifiPrinterSvg />
		</SvgIcon>
	)
}

export default NbWifiPrinter
