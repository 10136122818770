import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter } from 'react-router-dom'


import Root from '~/routes'

import AppProvider from './provider'
import './side-effects'


const DEFAULT_TITLE = 'Nana Bianca'

const App: React.FC = () => (
	<>
	<BrowserRouter>
		<Helmet
			defaultTitle={DEFAULT_TITLE}
			titleTemplate={`${DEFAULT_TITLE} | %s`}
		/>
			<AppProvider>
				<Root />
			</AppProvider>

	</BrowserRouter>
	</>
)

export default App
