import {Backdrop, Button, CircularProgress, LinearProgress, makeStyles} from '@material-ui/core'
import React, {useState} from 'react'
import { Suspense } from 'react'
import useApi from "~/hooks/use-api";
import ErrorBoundary from "~/components/error-boundary";

const useStyles = makeStyles((theme) => ({
	root: {
		border: 'none',
		height: '100vh',
		width: '100vw',
		maxWidth: '1000px',
		margin: "0 auto",
	},
}))

interface StrookaAuthenticationToken {
	token: string;
}

export const FoodLogin: React.FC = () => {
	return (
		<ErrorBoundary>
			<Suspense fallback={<LinearProgress />}>
				<_FoodLogin></_FoodLogin>
			</Suspense>
		</ErrorBoundary>
	)
}

const _FoodLogin: React.FC = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const redirectUrl = urlParams.get('redirect')
	const {data} = useApi<StrookaAuthenticationToken>('/food/token')
	const authentication_url = data.token? `https://food.nanabianca.it/customer/autologin/?token=${data.token}&redirect=${redirectUrl}` : ""
	if(authentication_url) {
		window.location.href = authentication_url
	}
	return (
		<>
			{ authentication_url &&
				<Button onClick={() => {window.location.href = authentication_url}}>
					Proceed to the store
				</Button>
			}
		</>
	)
}

const FoodTab: React.FC = () => {
	return (
		<ErrorBoundary>
			<Suspense fallback={<LinearProgress />}>
				<FoodTabLogin></FoodTabLogin>
			</Suspense>
		</ErrorBoundary>
	)
}

const _FoodTabLogin: React.FC = () => {
	const classes = useStyles()
	const [loading, setLoading] = useState(true)
	const [foodUrl, setFoodUrl] = useState('')

	const {data} = useApi<StrookaAuthenticationToken>('/food/token')
	const authentication_url = data.token? `https://food.nanabianca.it/customer/autologin/?token=${data.token}` : ""
	const FOOTER_BAR_HEIGHT = 76
	const height = window.innerWidth < 700? window.innerHeight - FOOTER_BAR_HEIGHT: '100vh'
	const width = '100%'
	return (
		<>
			<Backdrop open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{ authentication_url &&
				<iframe
					src={authentication_url}
					onLoad={() => {
						setFoodUrl('//food.nanabianca.it')
					}}
					style={{'display': 'none'}}></iframe>
			}
			{ foodUrl &&
				<iframe
					className={classes.root}
					src={foodUrl}
					title="food"
					onLoadStart={() => {setLoading(true)}}
					onLoad={() => {setLoading(false)}}
					style={{width, height}}></iframe>
			}
		</>
	)
}
const FoodTabLogin = React.memo(_FoodTabLogin)

export default FoodTab

