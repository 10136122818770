import  {Notification} from '~/models/index'

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const UNSET_NOTIFICATIONS = 'UNSET_NOTIFICATIONS';


export interface NotificationsState {
	notifications: Notification[] | null;
	loading: boolean;
}

interface SetNotificationsAction {
	type: typeof SET_NOTIFICATIONS;
	payload: Notification[];
}

interface UnsetNotificationsAction {
	type: typeof UNSET_NOTIFICATIONS;
	payload: null;
}

export type NotificationsActionTypes = SetNotificationsAction | UnsetNotificationsAction;
