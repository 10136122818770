import { NotificationsState, SET_NOTIFICATIONS, UNSET_NOTIFICATIONS, NotificationsActionTypes} from './types'

const initialState: NotificationsState = {
	notifications: null,
	loading: true
}

export default function notificationStates (
	state = initialState,
	action: NotificationsActionTypes
): NotificationsState {
	switch (action.type){
		case SET_NOTIFICATIONS:
			return {
				notifications: action.payload,
				loading: false
			}
		case UNSET_NOTIFICATIONS:
			return {
				notifications: null,
				loading: true
			}
		default:
			return state
	}
}
