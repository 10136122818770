const DEBUG = process.env.NODE_ENV === 'development'

// room

const GYM_ROOM_ID = 1314310009
const INVADER_ROOM_ID = 1375911539
const LEMMINGS_ROOM_ID = 1381781426
const QBERT_ROOM_ID = 1381781425
const SCHOOL_ROOM_ID = 477391921
const SNAKE_ROOM_ID = 1414933150
const SPACE_ROOM_ID = 773020248
const SUPER_MARIO_ROOM_ID = 258062806
const TETRIS_ROOM_ID = 1381781427
const DONKEY_KONG_ROOM_ID = 1381781429
const DEFENDER_ROOM_ID = 1415064642
const PACMAN_ROOM_ID = 1375884514
const CISCO_ROOM_ID = 1414907422
const RUST_CB_ID = 1383242862
const PYTHON_CB_ID = 1383220839
const PHP_CB_ID = 1383242865
const PERL_CB_ID = 1383242866
const JAVA_CB_ID = 1383242861
const COBOL_CB_ID = 1383242864
const FROGGER_ID = 1375911540
const STARTUPITALIA_ID = 1414929709
const ARKANOID_ID = 1381781424

// device

const DEVICES = {
	DESKTOP: 'DESKTOP',
	LAPTOP: 'LAPTOP',
	PHONE: 'PHONE',
	TABLET: 'TABLET',
	GAME: 'GAME',
	CAST: 'CAST',
	PRINTER: 'PRINTER',
	BOT: 'BOT',
}

const GDPR_COOKIES_NAME ={
	CookiesConsent: 'CookiesConsent',
	NecessaryConsent: 'CMP-NecessaryConsent',
	StatisticsConsent: 'CMP-StatisticsConsent',
	MarketingConsent: 'CMP-MarketingConsent',
}

const DEFAULT_COOKIES_VALUE={
	CookiesConsent: false,
	NecessaryConsent: true,
	StatisticsConsent: false,
	MarketingConsent: false,
}

const CB_RESOURCE_TYPE_ID = 1383153772
const LARGE_MR_RESOURCE_TYPE_ID = 243598355
const SMALL_MR_RESOURCE_TYPE_ID = 243598356

const DAILYPASS_FIRST_FLOOR_ID = 1414898607
const DAILYPASS_SECOND_FLOOR_ID = 1414898506
const DAILYPASS_THIRD_FLOOR_ID = 1414898608

const AUTH_PROVIDER_ID = {
	GOOGLE: 'google.com',
	MICROSOFT: 'microsoft.com',
	FACEBOOK: 'facebook.com',
	APPLE: 'apple.com'
}

export {
	DEBUG,
	GYM_ROOM_ID,
	INVADER_ROOM_ID,
	LEMMINGS_ROOM_ID,
	QBERT_ROOM_ID,
	SCHOOL_ROOM_ID,
	SNAKE_ROOM_ID,
	SPACE_ROOM_ID,
	SUPER_MARIO_ROOM_ID,
	TETRIS_ROOM_ID,
	DONKEY_KONG_ROOM_ID,
	DEFENDER_ROOM_ID,
	PACMAN_ROOM_ID,
	CISCO_ROOM_ID,
	RUST_CB_ID,
	PYTHON_CB_ID,
	PHP_CB_ID,
	PERL_CB_ID,
	JAVA_CB_ID,
	COBOL_CB_ID,
	DEVICES,
	GDPR_COOKIES_NAME,
	DEFAULT_COOKIES_VALUE,
	CB_RESOURCE_TYPE_ID,
	LARGE_MR_RESOURCE_TYPE_ID,
	SMALL_MR_RESOURCE_TYPE_ID,
	DAILYPASS_FIRST_FLOOR_ID,
	DAILYPASS_SECOND_FLOOR_ID,
	DAILYPASS_THIRD_FLOOR_ID,
	FROGGER_ID,
	STARTUPITALIA_ID,
	AUTH_PROVIDER_ID,
	ARKANOID_ID
}
