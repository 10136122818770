import React, { useEffect, useState } from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { Bell, CheckboxBlankCircle } from 'mdi-material-ui'
import {GetNotifications, GetUserLastNotifyTabOpen} from '~/services/notifications-service'
import moment from 'moment'


const NotificationsIcon: React.FC<SvgIconProps> = ({
    ...props
}) => {
    const [unread, setUnread] = useState(false)
    useEffect(() => {
        (async () => {
            const lastNotification = await GetNotifications()
            const date = await GetUserLastNotifyTabOpen()
            if (date && date < moment.unix(lastNotification[0].createDate)){
                setUnread(true)
            }

        })()
    }, [])
    
    return (
        <>
        <Bell {...props}/>
        {unread && (
            <SvgIcon className={"unreadDot"}>
                <CheckboxBlankCircle fontSize='small' color='secondary'></CheckboxBlankCircle>
            </SvgIcon>
        )}
        
</>
    )
}


export default NotificationsIcon