import React, { useState, useCallback, useEffect } from 'react'
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	Input,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Typography,
	makeStyles,
	Dialog,
	DialogContent,
	DialogActions,
	Card,
	CardMedia,
	Collapse,
	CardActionArea,
	Chip,
	DialogTitle,
	DialogContentText,
	Divider,

} from "@material-ui/core";
import ExtendedFab from '~/components/extended-fab'
import { useNavigate } from 'react-router-dom'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment, { Moment } from 'moment'
import ApiInstance from "~/api";
import ErrorSnackbar from "~/components/error-snackbar";
import CalendarDatePicker from '~/components/calendar-date-picker'
import { DAILYPASS_FIRST_FLOOR_ID, DAILYPASS_SECOND_FLOOR_ID, DAILYPASS_THIRD_FLOOR_ID } from "~/constants";
import SuccessDialog from '~/components/success-dialog'
import CircleBadge from '~/components/circle-badge'
import zIndex from '@material-ui/core/styles/zIndex';
import { FormatColorReset } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
	input:{
		display:'none'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1
	},
	card: {
		borderRadius: '10px',
		minWidth: 275,
		maxWidth: 360
	},
	cardMedia: {
		height: '185px',
	},
	imageContainer:{
		padding: '10px',
	},
	cardTitle: {
		fontFamily: 'Mono45-Headline',
		fontSize: '28px',
		fontWeight: 'bold',
		letterSpacing: '0',
		lineHeight: '34px',
		paddingLeft: '14px',
		padding: '5px',
	},
	floorName:{
		fontFamily: 'Mono45-Headline',
		fontSize: '24px',
		fontWeight: 'bold',
		color: '#0C0832',
		letterSpacing: '0',
		lineHeight: '29px',
	},
	listItemCard: {
	},
	selectedCard: {
		backgroundColor: 'rgb(10, 36, 235)',
		color: 'white'
	},
	badge: {
		marginLeft: '10px',
		paddingTop: '4px',
	},
	extendedFab: {
		[theme.breakpoints.up('md')]: {
			position: 'sticky',
			
		},
		width:'92%',
		zIndex: 10,
	},
}))

class DailyPassDesk {
	Id: number
	FloorId: number
	Name: string
	ResourceType: any
	ResourceTypeName: string
	HasImage: boolean
	IsAvailable: boolean
	ImageUrl: string
	constructor(Id: number, FloorId: number, Name: string, ResourceType: any, ResourceTypeName: string, HasImage: boolean, IsAvailable: boolean, ImageUrl: string) {
		this.Id = Id
		this.FloorId = FloorId
		this.Name = Name
		this.ResourceType = ResourceType
		this.ResourceTypeName = ResourceTypeName
		this.HasImage = HasImage
		this.IsAvailable = IsAvailable
		this.ImageUrl = ImageUrl
	}
}


const searchDailyPassResources = async(startDate: Date, endDate: Date, floorIds: number[]) =>{
	const response = await ApiInstance.get('/bookings/search', {
		cache: false,
		params: {
			type: JSON.stringify(floorIds),
			start: startDate,
			end: endDate
		},
	})
	return response.data.Resources
	
}

const DailyPassBooking: React.FC <{credits: string}>= (props) => {
	const navigate = useNavigate()

	const [selectedDate, setSelectedDate] = useState(null)
	const [datePickerOpen, setDatePickerOpen] = useState(false)
	const [loadingResources, setLoadingResources] = useState(true)
	const [dailyDesk, setDailyDesk] = useState<DailyPassDesk[]>()
	const [selectedDesk, setSelectedDesk] = useState<number | null>(null)
	const [success, setSuccess] = useState(false)
	const [totalDesks, setTotalDesks] = useState(0)

	const [dialogOpen, setDialogOpen] = useState(false)
	const [dialogLoading, setDialogLoading] = useState(false)

	const floorIds = [DAILYPASS_FIRST_FLOOR_ID, DAILYPASS_SECOND_FLOOR_ID, DAILYPASS_THIRD_FLOOR_ID]

	const [resourceBookingPrice, setResourceBookingPrice] = useState<
		string | null
	>(null)

	const [error, setError] = useState<any>(null)

	const [opener, setOpener] = useState(new Map<number, boolean>()
		.set(DAILYPASS_FIRST_FLOOR_ID, false)
		.set(DAILYPASS_SECOND_FLOOR_ID, false)
		.set(DAILYPASS_THIRD_FLOOR_ID, false))

	const [bookingDeskPerDay, setBookingDeskPerDay]=  useState(new Map<string, number>())

	const classes = useStyles()

	const getResources = async (startDate: Date, endDate: Date) => {
		setLoadingResources(true)
		
		const resources: DailyPassDesk[] = []
		for (const floorId of floorIds) {
			const response = await searchDailyPassResources(startDate, endDate, [floorId])
			
			for (const value of response) {
		
				let imageUrl = ""
				if (value.HasImage) {
					imageUrl = `//nanabianca.spaces.nexudus.com/en/publicresources/getimage/${value.Id}?h=400`
				}
				const desk = new DailyPassDesk(
					value.Id,
					floorId,
					value.Name,
					value.ResourceType,
					value.ResourceTypeName,
					value.HasImage,
					value.IsAvailable,
					imageUrl,
				)
				if (desk.IsAvailable) {
					resources.push(desk)
				}
			}
		}
		setLoadingResources(false)
		return resources
	}

	const handleDatePicker = (isOpen: boolean) => {
		setDatePickerOpen(isOpen)
	}

	const handleErrorClose = useCallback(() => {
		setError(null)
	}, [])

	const handleSuccessClose = useCallback(() => {
		navigate('/bookings')
	}, [navigate])

	const handleDateChange = useCallback(async (date) => {
		if (date?.isValid()) {
			setSelectedDate(date.toDate())
			const resources = await getResources(date.startOf('day').format('YYYY-MM-DDT08:30:00'), date.startOf('day').format('YYYY-MM-DDT19:00:00'))
			setDailyDesk(resources)
		}
	}, [])

	const newBooking = useCallback(async () => {
		if (selectedDate && selectedDesk) {
			setLoadingResources(true)
			const path = '/bookings/newBookingJson'
			const date = moment(selectedDate)

			const data = {
				cache: false,
				Booking: {
					Resource: {
						Id: selectedDesk,
					},
					FromTime: (date.startOf('day')).format('YYYY-MM-DDT08:30:00'),
					ToTime: (date.startOf('day')).format('YYYY-MM-DDT19:00:00'),
				},
			}

			try {
				await ApiInstance.post(path, data)
				setDialogOpen(false)
				setSuccess(true)
			} catch (error) {
				console.log(error)
				setError(error)
			} finally {
				setDialogLoading(false)
				setLoadingResources(false)
			}
		}
	}, [selectedDate, selectedDesk])

	const handleConfirmButton = useCallback(async () => {
		if (selectedDate && selectedDesk) {
			setLoadingResources(true)
			const date = moment(selectedDate)
			const path = '/bookings/getBookingPrice'
			const data = {
				cache: false,
				Booking: {
					Resource: {
						Id: selectedDesk,
					},
					FromTime: (date.startOf('day')).format('YYYY-MM-DDT08:30:00'),
					ToTime: (date.startOf('day')).format('YYYY-MM-DDT19:00:00'),
				},
			}

			try {
				const response = await ApiInstance.post(path, data)
				if (!response.data.IsAvailable) {
					setLoadingResources(false)
					setError(response.data.Message || 'La stanza non è disponibile per la prenotazione.')
					return
				}
				if (response.data.PriceDecimal > 0) {
					console.log(props.credits)
					if (Number(props.credits) >= response.data.PriceDecimal){
						newBooking()
					}else{
						setLoadingResources(false)
						setResourceBookingPrice(response.data.Price)
						//setDialogOpen(true)
						setError('Hai finito il tuo credito ore')
						console.log("Credito esaurito")
						return
					}
				}
				newBooking()
			} catch (error) {
				setLoadingResources(false)
				setError(error)
			}
		}
	}, [newBooking, selectedDesk, selectedDate])

	const handleBooking = (deskId: number) => {
		setSelectedDesk(deskId)
	}

	const getMonthBookings = async () =>{
		setLoadingResources(true)
		const desks: DailyPassDesk[] = []
		try{
			const response = await searchDailyPassResources(moment().toDate(), moment().endOf('month').toDate(), floorIds)

			response.map(function(d: DailyPassDesk){
				desks.push(d)
			})
			
		}catch{
			setError('Errore connessione con Nexudus')
		}
		const path = "/bookings/fullCalendarBookings"
		const params = {
			group: 'desk',
			start: moment(),
			end: moment().endOf('month'),
		}	
		try{
			const response = await ApiInstance.post(path,params)
			const matchs=  new Map<string, number>()
			response.data.forEach((booking:any) => {
				desks.forEach(desk => {
					if(desk.Id === booking.resourceId){
						
						const momentDate = moment(booking.start).format('DD/MM/YYYY')
						const deskQuantinty = matchs.get(momentDate)

						if (deskQuantinty !== undefined && deskQuantinty >=1 ){
							matchs.set(momentDate, deskQuantinty+1)
						}else if(deskQuantinty === undefined || deskQuantinty === null){
							matchs.set(momentDate, 1)
						}
					
					}
				});
			});
			setTotalDesks(desks.length)
			setBookingDeskPerDay(new Map(matchs))

		}
		catch{
			setError('Errore connessione con Nexudus')
		}

		
		setLoadingResources(false)
		setDatePickerOpen(true)
	}

	const shouldDisableDate = useCallback((date) => {
		if (totalDesks && bookingDeskPerDay){
			const bookedDesk = bookingDeskPerDay.get(date.format('DD/MM/YYYY'))
			if (bookedDesk !== undefined && bookedDesk >= totalDesks){
				return true
			}
		}
		return date.weekday() >= 5
	}, [bookingDeskPerDay, totalDesks])

	const desksItem = (desks: DailyPassDesk[], open: boolean, deskSelected: number | null) => {
		if (desks.length > 0) {
			return (
				<>
					<Collapse in={open} timeout="auto">

						<Grid container spacing={2}>
							{desks.map((desk, key) => (
								<Grid item xs={12} sm={4} key={key} className={classes.listItemCard} >
									<Box paddingTop={3} paddingLeft={1} paddingRight={1}>
										<Card className={classes.card} onClick={() => handleBooking(desk.Id)}>
											<CardActionArea className={desk.Id === deskSelected ? classes.selectedCard : ''}>
												<Typography variant="h5" component="h2" className={classes.cardTitle}>
													{desk.Name}
												</Typography>
												<Box className={classes.imageContainer}>
												{desk.HasImage && (
													<CardMedia
														className={classes.cardMedia}
														image={desk.ImageUrl}
														title=''
													/>
												)}

												</Box>

											</CardActionArea>
										</Card>
									</Box>
								</Grid>
							))}
						</Grid>
					</Collapse>

				</>)
		} else {
			return null
		}
	}


	const handleExpand = (floorId: number) => {
		const state = opener.get(floorId)
		setSelectedDesk(null)
		setOpener(new Map(opener.set(floorId, !state)))
	}

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
	}, [])

	const floorItem = useCallback((floorName: string, floorId: number) => {
		if (dailyDesk) {
			const isOpen = opener.get(floorId) || false
			const desks = dailyDesk.filter(desk => desk.FloorId === floorId)
			return (
				<>

					<ListItem button onClick={() => handleExpand(floorId)} disabled={desks.length <= 0}>
						<ListItemText primary={(
							<>
								<Grid container>
									<Grid item style={{paddingTop: '2px'}}>
										<Typography className={classes.floorName}>{floorName} 
										</Typography>		
									</Grid>
									<Grid item>
										<CircleBadge  className={classes.badge} text={String(desks.length)} fillColor={desks.length>=1? null: '#C2C2C2'}/>
									</Grid>
								</Grid>
							</>
						)}
						/>
						{opener.get(floorId) ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
					{desksItem(desks, isOpen, selectedDesk)}
				</>
			)
		}

	}, [opener, dailyDesk, selectedDesk])

	useEffect(() => {
		getMonthBookings()
    }, [])

	if (success)
		return (
			<SuccessDialog open onClose={handleSuccessClose}>
				<>
					Super!
					<Typography variant='h6'>
					La tua postazione
					sará disponibile il giorno {moment(selectedDate).format('DD/MM/YYYY')} dalle ore 8.30 alle 19.00
					</Typography>
					
				</>
			</SuccessDialog>
		)


	return (
		<>
			<Backdrop open={loadingResources} className={classes.backdrop}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<Box padding={2}>
				<Typography variant='subtitle1'>Non farti sfuggire la tua postazione.
				La data che selezionerai sarà da intendere come ticket giornaliero (9:00 - 19:00)
				</Typography>

			</Box>
			<Box padding={1} paddingBottom={0}>

				<CalendarDatePicker
					disableToolbar
					label="Seleziona la data"
					onChange={handleDateChange}
					shouldDisableDate={shouldDisableDate}
					value={selectedDate}
					open={datePickerOpen}
					onOpen={() => handleDatePicker(true)}
					onClose={() => handleDatePicker(false)}
					autoOk={true}
					showTodayButton= {false}
					okLabel = ''
					cancelLabel=''
					clearLabel=''
					todayLabel=''
					
				/>
			</Box>
			{selectedDate && dailyDesk && (
				<>
					<Box padding={2}>
						<Typography variant="subtitle2">Adesso scegli la postazione
						</Typography>
						<Divider/>
					</Box>
					<List>
						<Box padding={1} paddingBottom={0}>
							{floorItem('Piano Terra', DAILYPASS_FIRST_FLOOR_ID)}
							<Divider/>
							{floorItem('Primo Piano', DAILYPASS_SECOND_FLOOR_ID)}
							<Divider/>
							{floorItem('Secondo Piano', DAILYPASS_THIRD_FLOOR_ID)}
						</Box>
					</List>

					<ExtendedFab
						className={classes.extendedFab}
						disabled={!selectedDesk}
						onClick={handleConfirmButton}
					>
						<span>
							Conferma
						</span>
					</ExtendedFab>

					<Dialog
						disableBackdropClick={dialogLoading}
						disableEscapeKeyDown={dialogLoading}
						onClose={handleDialogClose}
						open={dialogOpen}
					>
						<DialogTitle>Hai finito il tuo credito</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Le ore non comprese nel tuo piano ti verrano fatturate a
								fine mese. Il prezzo per questa prenotazione è di{' '}
								<Typography color='primary' variant='inherit'>
									{resourceBookingPrice}
								</Typography>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								disabled={dialogLoading}
								onClick={handleDialogClose}
							>
								Annulla
							</Button>
							<Button
								color='secondary'
								disabled={dialogLoading}
								onClick={newBooking}
							>
								Sì, conferma
								{dialogLoading && (
									<CircularProgress
									/>
								)}
							</Button>
						</DialogActions>
					</Dialog>
				</>
			)}


			<ErrorSnackbar error={error} onClose={handleErrorClose} />
		</>
	)
}


export default DailyPassBooking

