import {
	List,
	ListItem,
	ListSubheader,
	makeStyles,
	Box,
} from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import React, {useCallback } from 'react'
import {Link, useNavigate} from 'react-router-dom'

import useApi from '~/hooks/use-api'
import useDashboardBookingsTab, {
	ACTIVE_BOOKINGS_TAB,
	INACTIVE_BOOKINGS_TAB,
} from '~/hooks/use-dashboard-bookings-tab'

import BookingsListItem from './list-item'
import {readNexudusDateTime} from "~/components/calendar-time-range-picker";
import {Plus} from "mdi-material-ui";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as BookMeetingRoomIconSVG } from '~/assets/images/icons/booking-meeting.svg'
import { ReactComponent as BookDailyPassIconSVG } from '~/assets/images/icons/booking-dailypass.svg'
import { ReactComponent as BookEventRoomIconSVG } from '~/assets/images/icons/booking-event.svg'
import {useSelector} from "react-redux";
import {RootState} from "~/store";
import { DAILYPASS_FIRST_FLOOR_ID, DAILYPASS_SECOND_FLOOR_ID, DAILYPASS_THIRD_FLOOR_ID } from "~/constants";

const getDateParamsFromBookingsTab = (
	bookingsTab?: string
): { start: string; end: string } => {
	const now = moment().startOf('hour')

	switch (bookingsTab) {
		case INACTIVE_BOOKINGS_TAB:
			return {
				end: now.format(),
				start: now.clone().subtract(1, 'year').format(),
			}
		case ACTIVE_BOOKINGS_TAB:
		default:
			return {
				end: now.clone().add(1, 'year').format(),
				start: now.format(),
			}
	}
}

const useStyles = makeStyles((theme) => ({
	list: {
		paddingBottom: theme.spacing(16),
	},
	subList: {
		flex: 1,
	},
	noBookings: {
		minHeight: '70vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign : 'center',
		color: '#00096D',
		fontSize: '2rem',
		flexDirection:'column'
	},
	noBookingFalseButton : {
		width: '1em',
		height: '1em',
		borderRadius: '.5em',
		display: 'inline-block',
		fill: '#ffffff',
		color: '#ffffff',
		background: '#0A24EB',
		verticalAlign: 'middle',
		marginLeft: '10px'
	},
	noBookingIcons : {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		textAlign : 'center',
		flexDirection: 'row',
		'& button': {
			border: 'none',
			background: 'transparent',
		}
	},
	noBookingIconDesc : {
		display: 'block',
		color: '#B9BDDB',
		fontSize: '1.2rem',
		margin: '0 15px'
	}
}))

const BookingsList: React.FC = () => {
	const profileInfo = useSelector( (state: RootState) => { return state?.user?.user;  } );

	const classes = useStyles()
	const navigate = useNavigate()
	const [bookingsTab] = useDashboardBookingsTab()
	const navigateTo = (url: string) => {
		navigate(url)
	}

	const { start, end } = getDateParamsFromBookingsTab(bookingsTab)

	const { data } = useApi<any[]>('/bookings/fullCalendarBookings', {
		params: { end, showAll: false, start },
	})

    const canBookFlexi = useCallback (() => { 
      return (profileInfo && profileInfo.TariffNames !==null && profileInfo.TariffNames.search("Flexi") > -1)
    },[profileInfo])

	const getResourceType = (resourceTypeId: number) =>{
		switch(resourceTypeId){
			case DAILYPASS_FIRST_FLOOR_ID:
				return 'desk'
			case DAILYPASS_SECOND_FLOOR_ID:
				return 'desk'
			case DAILYPASS_THIRD_FLOOR_ID:
				return 'desk'
			default:
				return null
		}
	}

	let bookings = data.map((value) => ({
		id: value.id,
		resourceId: value.resourceId,
		resourceName: value.resourceName,
		resourceType: getResourceType(value.resourceTypeId),
		coworkerId: value.coworkerId,
		coworkerFullName: value.coworkerFullName,
		startDate: readNexudusDateTime(value.start),
		endDate: readNexudusDateTime(value.end),
	}))
	if (bookingsTab === INACTIVE_BOOKINGS_TAB) {
		bookings.reverse()
	} else {
		bookings = bookings.filter((value) => value.startDate >= moment(start))
	}
	
	const groupedBookings = _.groupBy(bookings, (value) =>
		moment(value.startDate).calendar(undefined, {
			lastDay: '[Ieri]',
			lastWeek: 'dddd [scorso]',
			nextDay: '[Domani]',
			nextWeek: '[Prossimo] dddd',
			sameDay: '[Oggi]',
			sameElse: 'LL',
		})
	)

	if (!bookings.length) {
		return (
			<Box paddingX={2}>
				<div className={classes.noBookings}>
					<p>
						Non hai<br />prenotazioni attive.<br />Per prenotare la tua room<br /> premi su
						<SvgIcon className={classes.noBookingFalseButton}>
							<Plus></Plus>
						</SvgIcon>
					</p>
					<div className={classes.noBookingIcons}>
						<button onClick={()=>{navigateTo('/bookings/meeting')}}>
							<SvgIcon>
								<BookMeetingRoomIconSVG />
							</SvgIcon>
							<span className={classes.noBookingIconDesc}>Meeting<br />Room</span>
						</button>
						{( canBookFlexi() &&
							
							<button onClick={()=>{navigateTo('/bookings/dailypass')}}>
								<SvgIcon>
									<BookDailyPassIconSVG />
								</SvgIcon>
								<span className={classes.noBookingIconDesc}>Flexi<br />Desk</span>
							</button>
							
							)}

					</div>
				</div>
			</Box>
		)
	}

	return (
		<>
			<List className={classes.list} disablePadding>
				{Object.keys(groupedBookings).map((section) => (
					<ListItem key={`section-${section}`} disableGutters divider>
						<List className={classes.subList}
							  subheader={<ListSubheader>{section}</ListSubheader>}>
							{groupedBookings[section].map((item: any) => (
								<BookingsListItem
									key={`item-${section}-${item.id}`}
									{...item}
									button
									disabled={item.coworkerId !== profileInfo?.IdString}
									component={Link}
									to={`/bookings/${item.id}`}
								/>
							))}
						</List>
					</ListItem>
				))}
			</List>
		</>
	)
}

export default BookingsList
