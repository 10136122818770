import React, { useEffect, useState } from 'react'
import ApiInstance from '~/api'
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
} from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { StripeCustomer, StripePaymentMethod } from '~/models/stripe'
import { PlusCircleOutline } from 'mdi-material-ui'

const WalletPaymentMethodListItem: React.FC<{
	paymentMethod: StripePaymentMethod;
	customer: StripeCustomer | null;
	setDefaultPaymentMethod: any | null;
}> = ({ children, paymentMethod, customer, setDefaultPaymentMethod }) => {
	const cardPreview = `XXXX XXXX XXXX ${paymentMethod.card.last4}`
	const cardExpiracy = `${paymentMethod.card.exp_month} / ${paymentMethod.card.exp_year}`
	const isDefaultMethod =
		customer?.invoice_settings.default_payment_method === paymentMethod.id
	return (
		<ListItem>
			<ListItemText primary='Card' secondary={paymentMethod.card.brand} />
			<ListItemText primary='Number' secondary={cardPreview} />
			<ListItemText
				primary='Name'
				secondary={paymentMethod.billing_details.name}
			/>
			<ListItemText primary='Expiracy' secondary={cardExpiracy} />
			{!isDefaultMethod && setDefaultPaymentMethod && (
				<Button onClick={() => setDefaultPaymentMethod(paymentMethod)}>
					Make Default
				</Button>
			)}
		</ListItem>
	)
}

export default WalletPaymentMethodList

function WalletPaymentMethodList(): JSX.Element {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState<{
		paymentMethods: StripePaymentMethod[];
		customer: StripeCustomer | null;
	}>({
		customer: null,
		paymentMethods: [],
	})

	useEffect(() => {
		setLoading(true)
		ApiInstance.get('/stripe/customer').then((response) => {
			const customer = response.data as StripeCustomer
			ApiInstance.get('/stripe/wallet/list').then((response) => {
				setData({
					paymentMethods: response.data.data,
					customer: customer,
				})
				setLoading(false)
			})
		})
	}, [])

	const setDefaultPaymentMethod = (paymentMethod: StripePaymentMethod) => {
		setLoading(true)
		ApiInstance.post('/stripe/wallet/method/default', {
			defaultPaymentId: paymentMethod.id,
		}).then((response) => {
			if (data.customer != null) {
				data.customer.invoice_settings.default_payment_method =
					paymentMethod.id
				setData({
					customer: data.customer,
					paymentMethods: data.paymentMethods,
				})
			}
			setLoading(false)
		})
	}

	return (
		<>
			<Backdrop open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Box padding={1}>
				<List
					subheader={
						<ListSubheader>Your payment methods</ListSubheader>
					}
				>
					<ListItem
						button
						component='a'
						onClick={() => {
							navigate('/profile/wallet/add')
						}}
					>
						<ListItemIcon>
							<PlusCircleOutline />
						</ListItemIcon>
						<ListItemText primary='Add new card' />
					</ListItem>
					{data.paymentMethods.map((value) => {
						return (
							<WalletPaymentMethodListItem
								key={value.id}
								paymentMethod={value}
								customer={data.customer}
								setDefaultPaymentMethod={
									setDefaultPaymentMethod
								}
							/>
						)
					})}
					{data.paymentMethods.length < 1 && (
						<ListItem>
							You have no payment methods please register a new
							payment method
						</ListItem>
					)}
				</List>
			</Box>
		</>
	)
}
