import { FirebaseAuthentication, SignInResult } from '@robingenz/capacitor-firebase-authentication';
import { Backdrop, Box, Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import {
	getAuth,
	GoogleAuthProvider,
	OAuthProvider,
	signInWithCredential,
	FacebookAuthProvider,
	signInWithPopup,
} from 'firebase/auth';
import { AccountCircle, Apple, CellphoneMessage, Facebook, Google, MicrosoftWindows } from 'mdi-material-ui'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import backgroundImage from '~/assets/images/piazza-cestello.png'
import { ReactComponent as NbLogo } from '~/assets/images/nb-logo.svg'
import ErrorSnackbar from '~/components/error-snackbar'
import Flex from '~/components/flex'
import { DEBUG, AUTH_PROVIDER_ID } from '~/constants'
import {isWebView, isIos} from '~/services/utils-service'

const useStyles = makeStyles((theme) => ({
	background: {
		backgroundColor: '#0A24EB',
		backgroundPosition: 'center',
		backgroundImage: `url(${backgroundImage})`,
		backgroundSize: 'cover',
		minWidth: '100%',
		minHeight: '100%',
		position: 'absolute'
	},
	button: {
		fontFamily: 'halyard-display',
		textTransform: 'none',
		marginBottom: theme.spacing(1),
		width: '100%',
	},
	buttonApple: {
		minWidth: '210px',
		minHeight: '40px',
		fontFamily: 'halyard-display',
		textTransform: 'none',
		backgroundColor: 'white',
		borderRadius: 5,
		margin: '4px',
	},
	buttonGrid: {
		display: 'grid',
		justifyContent: 'center',
	},
	buttonGridItem: {
		display: 'grid',
	},
	buttonTest: {
		marginTop: theme.spacing(3),
	},
	loadingBackdrop: {
		color: theme.palette.common.white,
		zIndex: theme.zIndex.modal,
	},
	logo: {
		fill: theme.palette.common.white,
		maxWidth: 256,
	},
	hidden: {
		display: 'none',
	}
}))

const getDataFromUserId = async (userId: string) => {
	const documentRef = firebase.firestore().collection('users').doc(userId)

	const document = await documentRef.get()

	if (document?.exists) {
		return document.data()
	}
	// Wait for it to be created

	const documentSnapshot = await new Promise<firebase.firestore.DocumentSnapshot>((resolve, reject) =>{
		console.log('CREATE DOCUMENT');
		
		// Set up an observer for document changes
		const observer = documentRef.onSnapshot(
		  (documentSnapshot) => {
			if (documentSnapshot.exists) {
			  observer();
			  resolve(documentSnapshot);
			}
		  },
		  (error) => {
			observer();
			console.error(error)
			reject(error);
		  }
		);
	  });
	
	return documentSnapshot.data()
}

const signInWithProvider = async (AuthProvider: any, credentials?: Credentials | null): Promise<SignInResult | null> => {
	if (!AuthProvider) {
		if (credentials == null) {
			throw new Error("In case of missing auth provider you have to supply credentials");
		}
		const userCredentials = await firebase
			.auth()
			.signInWithEmailAndPassword(credentials?.username as string, credentials?.password as string)
		return userCredentials as SignInResult
	}
	let siginResult = null
	let credential = null
	// let customToken = ''
	if (AuthProvider.PROVIDER_ID === AUTH_PROVIDER_ID.GOOGLE) {
		siginResult = await FirebaseAuthentication.signInWithGoogle()
		credential = GoogleAuthProvider.credential(siginResult?.credential?.idToken);

	} else if (AuthProvider.providerId === AUTH_PROVIDER_ID.MICROSOFT) {

		// Temp disable Microsoft login on Android

		/* credential = AuthProvider.credential({
			'accessToken': siginResult?.credential?.accessToken,
			'idToken': siginResult?.credential?.idToken,
			'rawNonce': siginResult?.credential?.nonce
		}); */

		//siginResult = await FirebaseAuthentication.signInWithMicrosoft()
		return null
	} else if (AuthProvider.PROVIDER_ID === AUTH_PROVIDER_ID.FACEBOOK) {

		siginResult = await FirebaseAuthentication.signInWithFacebook()
		const accessToken = siginResult?.credential?.idToken
		if (accessToken) {
			credential = FacebookAuthProvider.credential(accessToken);
		}
	} else if (AuthProvider.providerId === AUTH_PROVIDER_ID.APPLE) {

		siginResult = await FirebaseAuthentication.signInWithApple()
		credential = AuthProvider.credential({
			idToken: siginResult.credential?.idToken,
			rawNonce: siginResult.credential?.nonce,
		});
	}

	if (credential !== null) {
		try {
			const auth = getAuth();
			await signInWithCredential(auth, credential);

		} catch (error) {
			console.log('Auth error: ', error)
			throw error
		}
	}
	return siginResult
}


class Credentials {
	public username: string;
	public password: string;
	constructor(username: string, password: string) {
		this.username = username
		this.password = password
	}
}

const Login: React.FC = () => {
	const navigate = useNavigate()
	const classes = useStyles()
	const redirectURL = useSearchParam('redirect')
	const inviteToTeam = useSearchParam('inviteToTeam');
	const switchURL = useSearchParam('switch_url')

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)

	const handleLoginClick = useCallback(async (AuthProvider, credentials?: Credentials | null) => {
		setLoading(true)
		let user = null
		try {
			if (AuthProvider && AuthProvider.providerId === AUTH_PROVIDER_ID.MICROSOFT) {
				// TEMP Disable Microsoft Capacitor Login
				const auth = getAuth();
				const result = await signInWithPopup(auth, AuthProvider);
				user = result.user
			} else {
				const siginResult = await signInWithProvider(AuthProvider, credentials)
				if (!siginResult) {
					return
				}
				user = siginResult.user
			}

			if (user && user.uid) {
				await getDataFromUserId(user.uid)
			}
		} catch (error) {
			setError((error as Error).message)
			return
		} finally {
			setLoading(false)
		}
		const urlParams = new URLSearchParams(window.location.search);
		const redirectUrl = urlParams.get('redirect')
		if (inviteToTeam) {
			navigate('/joinTeam' + inviteToTeam, { replace: true })
		} if (redirectUrl != null) {
			navigate(redirectUrl, { replace: true })
		} else {
			navigate('/', { replace: true })
		}

	},
		[navigate]
	)

	const handleGoogleLoginClick = useCallback(
		() => handleLoginClick(firebase.auth.GoogleAuthProvider),
		[handleLoginClick]
	)

	const handleFacebookLoginClick = useCallback(
		() => handleLoginClick(firebase.auth.FacebookAuthProvider),
		[handleLoginClick]
	)

	const handleAppleLoginClick = useCallback(
		() => handleLoginClick(new OAuthProvider('apple.com')),
		[handleLoginClick]
	)

	const handleMicrosoftLoginClick = useCallback(
		() => {
			const microsoftOathProvider = new OAuthProvider('microsoft.com');
			microsoftOathProvider.setCustomParameters({
				prompt: 'consent',
				login_hint: 'user@firstadd.onmicrosoft.com'
			});
			microsoftOathProvider.setCustomParameters({
				tenant: 'common'
			});
			handleLoginClick(microsoftOathProvider)
		},
		[handleLoginClick]
	)

	const handleTestLoginClick = useCallback((user) => {
		handleLoginClick(null, new Credentials(user, 'password'))
	}, [handleLoginClick]
	)

	const smsClick = () => {
		const queryString = window.location.search
		navigate(`/phone${queryString}`)
	}

	const showTestUsers = () => {
		return DEBUG
	}

	const handleErrorSnackbarClose = useCallback(() => {
		setError(null)
	}, [])


	useEffect(() => {
		if (switchURL)
			smsClick()
	}, [])
	return (
		<>
			<Backdrop className={classes.loadingBackdrop} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Flex className={classes.background}>
				<Flex
					alignItems='center'
					justifyContent='space-around'
					margin={6}
				>
					<Flex color='white' flex={0}>

						<Grid container color='white' spacing={3}>
							<Grid item xs={12} xl={12} className='loginLogoContainer'>
								<Box paddingBottom={10}  >
									<NbLogo className={classes.logo} title='logo' />
								</Box>
							</Grid>
							<Grid item xs={12} xl={12}>
								<Typography variant='h2' gutterBottom className='loginTitle' >
									WE <span style={{color: '#FA0056'}}> TRY TO </span>
									 BUILD THE FUTURE
								</Typography>
							</Grid>
							<Grid item xs={12} xl={12} className={classes.buttonGrid}>
								<Grid container alignItems="center" className={classes.buttonGrid}>
									<Grid item xs={12} xl={12} className={classes.buttonGridItem}>
										<Button
											className={classes.button}
											disabled={loading}
											onClick={handleGoogleLoginClick}
											startIcon={<Google />}
											variant='contained'
										>
											Sign in with Google
										</Button>
									</Grid>
									<Grid item xs={12} xl={12} className={classes.buttonGridItem}>
										<Button
											className={classes.button}
											disabled={loading}
											onClick={handleFacebookLoginClick}
											startIcon={<Facebook />}
											variant='contained'
										>
											Sign in with Facebook
										</Button>
										{!isWebView() && (
											<Grid item xs={12} xl={12} className={classes.buttonGridItem}>
												<Button
													className={classes.button}
													disabled={loading}
													onClick={handleMicrosoftLoginClick}
															startIcon={<MicrosoftWindows />}
													variant='contained'>
													Sign in with Microsoft
												</Button>
											</Grid>
										)}
									</Grid>
									{isWebView() && isIos() && (
										<Grid item xs={12} xl={12} className={classes.buttonGridItem}>
										<Button
											className={classes.buttonApple}
											startIcon={<Apple />}
											variant='contained'
											onClick={handleAppleLoginClick}
										>
											Sign in with Apple
										</Button>
									</Grid>)}
									<Grid item xs={12} xl={12}>
										{switchURL && redirectURL && (
											<Button
												className={classes.button}
												onClick={() => smsClick()}
												size='large'
												startIcon={<CellphoneMessage />}
												variant='contained'
											>
												Sign in with SMS
											</Button>)}

										<Typography>
											By logging in you agree to our <a href="https://sites.google.com/view/nanabianca-legal/privacy-policy" target="_blank" style={{ "color": "white" }} rel="noreferrer">Privacy Policy</a>
										</Typography>
									</Grid>
									<Grid item xs={12} xl={12}>
										{showTestUsers() && (
											<Grid container>
												<Grid item>
													<Button className={classes.buttonTest} color='inherit' disabled={loading}
														onClick={() => { handleTestLoginClick('test@nanabianca.it') }}
														size='large' startIcon={<AccountCircle />}>
														T0
													</Button>
												</Grid>
												<Grid item>
													<Button className={classes.buttonTest} color='inherit' disabled={loading}
														onClick={() => { handleTestLoginClick('test1@nanabianca.it') }}
														size='large' startIcon={<AccountCircle />}>
														T1
													</Button>
												</Grid>
												<Grid item>
													<Button className={classes.buttonTest} color='inherit' disabled={loading}
														onClick={() => { handleTestLoginClick('test2@nanabianca.it') }}
														size='large' startIcon={<AccountCircle />}>
														T2
													</Button>
												</Grid>
												<Grid item>
													<Button className={classes.buttonTest} color='inherit' disabled={loading}
														onClick={() => { handleTestLoginClick('test3@nanabianca.it') }}
														size='large' startIcon={<AccountCircle />}>
														T3
													</Button>
												</Grid>

											</Grid>
										)}
									</Grid>

								</Grid>

							</Grid>

						</Grid>
					</Flex>

				</Flex>

				<ErrorSnackbar
					error={error}
					onClose={handleErrorSnackbarClose}
				/>
			</Flex>
		</>
	)
}

export default Login
