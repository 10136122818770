import {ButtonBase, makeStyles, Typography, Grid, createStyles} from '@material-ui/core'
import clsx from 'clsx'
import moment, { Moment } from 'moment'
import PropTypes, { InferProps } from 'prop-types'
import React, {useCallback, useState} from 'react'

import { momentPropType } from '~/utils'
import {NexudusCalendarBooking} from "~/models";

const useStyles = makeStyles((theme) => {
	const buttonOffset = theme.spacing(5)
	const buttonSize = theme.spacing(8)
	const buttonSpacing = theme.spacing(6)

	const buttonSelectedPseudo = {
		backgroundColor: theme.palette.primary.main,
		// boxShadow: theme.shadows[1],
		content: '""',
		height: '100%',
		position: 'absolute',
	}

	const style =  createStyles({
		container: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(16),
		},
		button: {
			backgroundColor: theme.palette.background.paper,
			borderRadius: '50%',
			boxShadow: theme.shadows[2],
			height: buttonSize,
			width: buttonSize,
		},
		disableCont: {
			position: 'relative',
			'& + $disableCont::before': {
				content: '""',
				width: '100%',
				height: '80%',
				backgroundColor: '#C8CBDC',
				position: 'absolute',
				top: '10%',
				left: '-50%',
				zIndex: 0,
			},
			'& + $lastDisableCont::before': {
				content: '""',
				width: '100%',
				height: '80%',
				backgroundColor: '#C8CBDC',
				position: 'absolute',
				top: '10%',
				left: '-50%',
				zIndex: 0,
			},
			'&::after': {
				content: '""',
				width: '100%',
				height: '80%',
				backgroundColor: '#C8CBDC',
				position: 'absolute',
				top: '10%',
				left: '50%',
				zIndex: 0,
			},
		},
		lastDisableCont: {
			position: 'relative',
		},
		buttonDisabled: {
			backgroundColor: "#C8CBDC",
			boxShadow: 'none',
			color: "white",
			zIndex: 2,
		},
		buttonSelected: {
			backgroundColor: theme.palette.primary.main,
		},
		buttonSelectedStart: {
			borderBottomRightRadius: 0,
			borderTopRightRadius: 0,
			'&::after': {
				...buttonSelectedPseudo,
				right: -buttonOffset,
				width: buttonSpacing,
			},
			boxShadow: 'none'
		},
		buttonSelectedEnd: {
			borderBottomLeftRadius: 0,
			borderTopLeftRadius: 0,
			'&::before': {
				...buttonSelectedPseudo,
				left: -buttonOffset,
				width: buttonSpacing,
			},
			boxShadow: 'none'
		},
		buttonSelectedText: {
			color: theme.palette.common.white,
			zIndex: 10
		},
	});
	console.log('style', style);
	return style;
});

const TimeRangePickerProps = {
	onUserSelection: PropTypes.func.isRequired,
	resourceBookings: PropTypes.arrayOf(PropTypes.instanceOf(NexudusCalendarBooking)).isRequired,
	startDate: momentPropType.isRequired,
}

export function round15Min(_moment: Moment): Moment {
	return _moment.minute() < 15 ? _moment.minutes(0) : _moment.minutes(15)
}


const TimeRangePicker: React.FC<
	InferProps<typeof TimeRangePickerProps> & {
		startDate: Moment;
	}
> = (props) => {
	const { onUserSelection, resourceBookings, startDate } = props
	const classes = useStyles()
	const hours = Array.from({ length: 45 }, (_, index) =>
		startDate
			.clone()
			.set('hour', 8)
			.startOf('hour')
			.add(15 * index, 'minutes')
	)
	const [start, setStart] = useState<Moment | null>(startDate.isSameOrAfter(moment())? startDate : null)
	const [end, setEnd] = useState<Moment | null>(null)

	const shouldDisableTimeRangeDate = useCallback(
		(date: Moment, startDate: Moment | null, endDate: Moment | null | undefined): boolean => {
			const roundedNow = round15Min(moment())
			if (date.isBefore(roundedNow, 'minute')){
				return true
			}
			if (resourceBookings != null ){
				for (const resourceBooking of resourceBookings) {
					if(resourceBooking != null) {
						if(date.isBetween(resourceBooking.startMoment, resourceBooking.endMoment, 'minute', '()')){
							return true
						}
						if(endDate === null) {
							if (date.isAfter(startDate) && resourceBooking.startMoment.isAfter(startDate) && resourceBooking.startMoment.isBefore(date)) {
								return true
							}
							if (date.isBefore(startDate) && resourceBooking.startMoment.isSame(date, 'minute')) {
								return true
							}
							if (date.isAfter(startDate) && resourceBooking.endMoment.isSame(date, 'minute')){
								return true
							}
						} else {
							if(resourceBooking.startMoment.isSame(date, 'minute')){
								return true
							}
						}
					}
				}
			}
			return false
		},
		[resourceBookings, start, end]
	)

	function handleHourClick(hour: moment.Moment) {
		let s: Moment | null = start
		let e: Moment | null = end
		if (!s || hour.isSameOrBefore(s) || e) {
			setStart(hour)
			setEnd(null)
			s = hour
			e = null
		} else if (s && !e) {
			setEnd(hour)
			e = hour
		}
		onUserSelection(s, e)
	}

	// const timegrid = () => {
	// 	hours.map((hour) => {})
	// }

	return (
		<Grid
			alignContent='center'
			alignItems='flex-start'
			className={classes.container}
			container
			direction='row'
			justifyContent='center'
			spacing={2}
		>
			{hours.map((hour) => {
				const isDisabled = shouldDisableTimeRangeDate
					? shouldDisableTimeRangeDate(hour, start, end)
					: false
				const isSelectedStart = hour.isSame(start)
				const isSelectedEnd = hour.isSame(end)
				const isSelectedBetween = hour.isBetween(start, end)
				const isSelected =
					isSelectedStart || isSelectedEnd || isSelectedBetween

				const nextmoment = hour.clone().add(30, 'minutes')
				const isLastDisabled = isDisabled ? !shouldDisableTimeRangeDate(nextmoment, start, end) : false

				return (
					<Grid key={`${hour}`} item xs={"auto"} className={isDisabled?isLastDisabled?classes.lastDisableCont:classes.disableCont:''}>
						<ButtonBase
							className={clsx(classes.button, {
								[classes.buttonDisabled]: isDisabled,
								[classes.buttonSelected]: isSelected,
								[classes.buttonSelectedStart]: end && (isSelectedStart || isSelectedBetween),
								[classes.buttonSelectedEnd]:
									isSelectedEnd || isSelectedBetween,
							})}
							disabled={isDisabled}
							onClick={() => handleHourClick(hour)}
						>
							<Typography
								className={clsx({
									[classes.buttonSelectedText]: isSelected,
								})}
								variant='subtitle2'
							>
								{hour.format('LT')}
							</Typography>
						</ButtonBase>
					</Grid>
				)
			})}
		</Grid>
	)
}

TimeRangePicker.propTypes = TimeRangePickerProps

export default TimeRangePicker
