import {Box, LinearProgress, makeStyles} from '@material-ui/core'
import React, { useEffect } from 'react'
import useScript from 'react-script-hook'

import Header from '~/components/header'
import ErrorSnackbar from '~/components/error-snackbar'

const HUBSPOT_FORM_SCRIPT = {
	async: true,
	checkForExisting: true,
	defer: true,
	src: '//js.hsforms.net/forms/shell.js',
}

const useStyles = makeStyles((theme) => ({
	blueEventBooking: {
		background: '#0A24EB!important',
		color: '#ffffff',
		'& h4': {
			color: '#ffffff!important',
		}
	},
	blueEventBookingBg: {
		background: '#0A24EB!important',
		color: '#ffffff',
		minHeight: '100vh',
		fontFamily: 'halyard-display!important',
		'& #hubspot-event-form':{
			'& fieldset': {
				maxWidth : '5000px'
			},
			'& label': {
				display: 'none'
			},
			'& input, textarea': {
				backgroundColor: 'transparent!important',
				color: '#ffffff!important',
				fontFamily: 'halyard-display!important',
				borderColor: '#ffffff',
				fontSize: '1.3rem'
			},
			'& select': {
				backgroundColor: 'transparent!important',
				color: '#ffffff!important',
				fontFamily: 'halyard-display!important',
				borderColor: '#ffffff',
				borderTop: '0',
				borderLeft: '0',
				borderRight: '0',
				borderRadius: '0',
				fontSize: '1.3rem'
			},
			'& option': {
				backgroundColor: '#0A24EB!important',
				color: '#ffffff!important',
				fontFamily: 'halyard-display!important',
				borderColor: '#ffffff',
				fontSize: '1.3rem'
			},
			'& p': {
				color: '#ffffff',
				fontFamily: 'halyard-display!important',
				fontSize: '1.7rem',
				lineHeight: '1.2'
			},
			'& .input-list label': {
				display: 'block',
				backgroundColor: 'transparent',
				color: '#ffffff',
				fontFamily: 'halyard-display!important',
				fontSize: '1.7rem',
				lineHeight: '1.2'
			},
			'& .actions input': {
				width: '100%',
				fontFamily: "Mono45-Headline, sans-serif",
				textTransform: "uppercase",
				backgroundColor: '#FA0056!important',
				borderColor: '#FA0056',
				borderRadius: '0',
				fontSize: '1.4rem'
			}
		},

	}
}))

const EventForm: React.FC = () => {
	const [loading, error] = useScript(HUBSPOT_FORM_SCRIPT)
	const classes = useStyles()
	const targetId = 'hubspot-event-form'

	useEffect(() => {
		if (loading || error) return

		const formId = '0f066f57-20e0-4b64-8ff6-3e45135a1f5e';
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.hbspt.forms.create({
			formId: formId,
			locale: 'it',
			portalId: '3449325',
			pageId: "space-event",
			target: `#${targetId}`,
		});
	}, [error, loading])

	return (
		<div className={classes.blueEventBookingBg}>
			<Header title='Prenota La tua Event Room' className={classes.blueEventBooking} color={'inherit'} />

			{loading && <LinearProgress />}

			<Box id={targetId} padding={2} />

			{error && (
				<ErrorSnackbar error='Impossibile caricare la pagina, riprova più tardi.' />
			)}
		</div>
	)
}

export default EventForm
