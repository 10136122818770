import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as NbWifiPhoneSvg } from '~/assets/images/icons/devices/nb-wifi-phone.svg'

function NbWifiPhone(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbWifiPhoneSvg />
		</SvgIcon>
	)
}

export default NbWifiPhone
