import {NexudusCoworkerProfile, SET_USER, UNSET_USER, UserActionTypes} from './types'

export function setUser(user: NexudusCoworkerProfile): UserActionTypes {
	return {
		type: SET_USER,
		payload: user
	}
}

export function unsetUser(): UserActionTypes {
	return {
		type: UNSET_USER,
		payload: null
	}
}
