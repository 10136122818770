import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as NbWifiLaptopSvg } from '~/assets/images/icons/devices/nb-wifi-laptop.svg'

function NbWifiLaptop(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbWifiLaptopSvg />
		</SvgIcon>
	)
}

export default NbWifiLaptop
