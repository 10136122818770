import { Moment } from 'moment';

export class NexudusCoworker {
	AbsoluteCancellationDate: string
	AccessPincode: number
	Active: boolean
	Address: string
	AddressForInvoice: string
	AgeInDays: number
	AllowNetworkCheckin: boolean
	AvatarUrl: string
	BillingAddress: string
	BillingCityName: string
	BillingCountryId: number
	BillingEmail: string
	BillingName: string
	BillingPostCode: string
	BillingState: string
	Blogger: string
	CompanyName: string
	CreatedOn: string
	Email: string
	EmailForInvoice: string
	FullName: string
	FullNameForInvoice: string
	HomeSpaceGuid: string
	HomeSpaceId: number
	HomeSpaceName: string
	Id: number
	IsMember: boolean
	IsNew: boolean
	IsPayingMember: boolean
	IsTeamAdministrator: boolean
	MobilePhone: string
	NickName: string
	RegistrationDate: string
	Salutation: string
	TeamNames: string
	UniqueId: string
	GuessedLastName: string
	GuessedFirstName: string
	CanMakeBookings: boolean

	constructor(
		AbsoluteCancellationDate: string,
		AccessPincode: number,
		Active: boolean,
		Address: string,
		AddressForInvoice: string,
		AgeInDays: number,
		AllowNetworkCheckin: boolean,
		AvatarUrl: string,
		BillingAddress: string,
		BillingCityName: string,
		BillingCountryId: number,
		BillingEmail: string,
		BillingName: string,
		BillingPostCode: string,
		BillingState: string,
		Blogger: string,
		CompanyName: string,
		CreatedOn: string,
		Email: string,
		EmailForInvoice: string,
		FullName: string,
		FullNameForInvoice: string,
		HomeSpaceGuid: string,
		HomeSpaceId: number,
		HomeSpaceName: string,
		Id: number,
		IsMember: boolean,
		IsNew: boolean,
		IsPayingMember: boolean,
		IsTeamAdministrator: boolean,
		MobilePhone: string,
		NickName: string,
		RegistrationDate: string,
		Salutation: string,
		TeamNames: string,
		UniqueId: string,
		GuessedLastName: string,
		GuessedFirstName: string,
		CanMakeBookings: boolean
	) {
		this.AbsoluteCancellationDate = AbsoluteCancellationDate
		this.AccessPincode = AccessPincode
		this.Active = Active
		this.Address = Address
		this.AddressForInvoice = AddressForInvoice
		this.AgeInDays = AgeInDays
		this.AllowNetworkCheckin = AllowNetworkCheckin
		this.AvatarUrl = AvatarUrl
		this.BillingAddress = BillingAddress
		this.BillingCityName = BillingCityName
		this.BillingCountryId = BillingCountryId
		this.BillingEmail = BillingEmail
		this.BillingName = BillingName
		this.BillingPostCode = BillingPostCode
		this.BillingState = BillingState
		this.Blogger = Blogger
		this.CompanyName = CompanyName
		this.CreatedOn = CreatedOn
		this.Email = Email
		this.EmailForInvoice = EmailForInvoice
		this.FullName = FullName
		this.FullNameForInvoice = FullNameForInvoice
		this.HomeSpaceGuid = HomeSpaceGuid
		this.HomeSpaceId = HomeSpaceId
		this.HomeSpaceName = HomeSpaceName
		this.Id = Id
		this.IsMember = IsMember
		this.IsNew = IsNew
		this.IsPayingMember = IsPayingMember
		this.IsTeamAdministrator = IsTeamAdministrator
		this.MobilePhone = MobilePhone
		this.NickName = NickName
		this.RegistrationDate = RegistrationDate
		this.Salutation = Salutation
		this.TeamNames = TeamNames
		this.UniqueId = UniqueId
		this.GuessedFirstName = GuessedFirstName
		this.GuessedLastName = GuessedLastName
		this.CanMakeBookings = CanMakeBookings
	}
}

export class NexudusCalendarBooking {
	id: string
	resourceId: string
	color: string | undefined
	start: Date
	startMoment: Moment
	end: Date
	endMoment: Moment
	private: boolean
	coworkerFullName: string

	constructor(
		id: string,
		resourceId: string,
		color: string | undefined,
		start: Moment,
		end: Moment,
		isPrivate: boolean,
		coworkerFullName: string
	) {
		this.id = id
		this.resourceId = resourceId
		this.color = color
		this.startMoment = start
		this.start = start.toDate()
		this.endMoment = end
		this.end = end.toDate()
		this.private = isPrivate
		this.coworkerFullName = coworkerFullName
	}
}

export class Device {
	documentId: string
	deviceName: string
	deviceIcon: string
	username: string
	constructor(
		documentId: string,
		deviceName: string,
		deviceIcon: string,
		username: string
	) {
		this.documentId = documentId
		this.deviceName = deviceName
		this.deviceIcon = deviceIcon
		this.username = username
	}
}

export class NexodusRoom {
	Name: string
	Description: string
	ResourceTypeName: string
	HasImage: boolean
	Projector: boolean
	Internet: boolean
	ConferencePhone: boolean
	StandardPhone: boolean
	WhiteBoard: boolean
	LargeDisplay: boolean
	AirConditioning: boolean
	Heating: boolean
	Id: number
	Allocation: number
	SecurityLock: boolean
	Drinks: boolean
	TeaAndCoffee: boolean
	Catering: boolean
	NaturalLight: boolean
	VoiceRecorder: boolean
	CCTV: boolean
	ResourceResourceTypeId: number
	constructor(
		Name: string,
		Description: string,
		ResourceTypeName: string,
		HasImage: boolean,
		Projector: boolean,
		Internet: boolean,
		ConferencePhone: boolean,
		StandardPhone: boolean,
		WhiteBoard: boolean,
		LargeDisplay: boolean,
		AirConditioning: boolean,
		Heating: boolean,
		Id: number,
		Allocation: number,
		SecurityLock: boolean,
		Drinks: boolean,
		TeaAndCoffee: boolean,
		Catering: boolean,
		NaturalLight: boolean,
		VoiceRecorder: boolean,
		CCTV: boolean,
		ResourceResourceTypeId: number
	) {
		this.Name = Name
		this.Description = Description
		this.ResourceTypeName = ResourceTypeName
		this.HasImage = HasImage
		this.Projector = Projector
		this.Internet = Internet
		this.ConferencePhone = ConferencePhone
		this.StandardPhone = StandardPhone
		this.WhiteBoard = WhiteBoard
		this.LargeDisplay = LargeDisplay
		this.AirConditioning = AirConditioning
		this.Heating = Heating
		this.Id = Id
		this.Allocation = Allocation
		this.SecurityLock = SecurityLock
		this.Drinks = Drinks
		this.TeaAndCoffee = TeaAndCoffee
		this.Catering = Catering
		this.NaturalLight = NaturalLight
		this.VoiceRecorder = VoiceRecorder
		this.CCTV = CCTV
		this.ResourceResourceTypeId = ResourceResourceTypeId
	}
}

export class Notification{
	title: string
	pubDate: string
	content: string
	contentSnippet: string
	description: string
	link: string
	guid: string
	createDate: number
	imgUrl: string
	type: string
	constructor(
		title: string,
		pubDate: string,
		content: string,
		contentSnippet: string,
		description: string,
		link: string,
		guid:string,
		createDate:number,
		imgUrl:string,
		type: string
	) {
		this.title=title
		this.pubDate=pubDate
		this.content=content
		this.contentSnippet=contentSnippet
		this.description=description
		this.link=link
		this.guid=guid
		this.createDate=createDate
		this.imgUrl=imgUrl
		this.type=type
	}
}

export class NexudusBookingCredits{
	Count: number
	CreditDescription: string
	CreditDetailList: any[]
	CreditName: string
	DaysLeft: number
	HoursLeft: number
	MinutesLeft: number
	MonthsLeft: number
	PassesLeft: number
	RemainingCredit: number
	TotalCredit: number
	TotalDays: number
	TotalHours: number
	TotalMinutes: number
	TotalMonths: number
	TotalUses: number
	TotalWeeks: number
	UsesLeft: number
	WeeksLeft: number
	constructor(
		Count: number,
		CreditDescription: string,
		CreditDetailList: any[],
		CreditName: string,
		DaysLeft: number,
		HoursLeft: number,
		MinutesLeft: number,
		MonthsLeft: number,
		PassesLeft: number,
		RemainingCredit: number,
		TotalCredit: number,
		TotalDays: number,
		TotalHours: number,
		TotalMinutes: number,
		TotalMonths: number,
		TotalUses: number,
		TotalWeeks: number,
		UsesLeft: number,
		WeeksLeft: number,
	){
		this.Count = Count
		this.CreditDescription = CreditDescription
		this.CreditDetailList = CreditDetailList
		this.CreditName = CreditName
		this.DaysLeft = DaysLeft
		this.HoursLeft = HoursLeft
		this.MinutesLeft = MinutesLeft
		this.MonthsLeft = MonthsLeft
		this.PassesLeft = PassesLeft
		this.RemainingCredit = RemainingCredit
		this.TotalCredit = TotalCredit
		this.TotalDays = TotalDays
		this.TotalHours = TotalHours
		this.TotalMinutes = TotalMinutes
		this.TotalMonths = TotalMonths
		this.TotalUses = TotalUses
		this.TotalWeeks = TotalWeeks
		this.UsesLeft = UsesLeft
		this.WeeksLeft = WeeksLeft
	}
}

export class NexudusAllowances{
	BookingCredits: NexudusBookingCredits[]
	// Add the other params if u need
	constructor(BookingCredits: NexudusBookingCredits[]){
		this.BookingCredits = BookingCredits
	}
}
