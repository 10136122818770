import {BookingsActionTypes, BookingsState, SET_BOOKINGS_EVENTS, SET_BOOKINGS_RESOURCES} from './types'

const initialState: BookingsState = {
	bookingsResources: null,
	bookingsEvents: null
}

export default function bookingStates (
	state = initialState,
	action: BookingsActionTypes
): BookingsState {
	switch (action.type){
		case SET_BOOKINGS_RESOURCES:
			return {
				bookingsResources: action.payload,
				bookingsEvents: state.bookingsEvents
			}
		case SET_BOOKINGS_EVENTS:
			return {
				bookingsResources: state.bookingsResources,
				bookingsEvents: action.payload
			}
		default:
			return state
	}
}
