import React, { useState, useEffect, Suspense } from 'react'
import {
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	Divider,
	LinearProgress,
} from '@material-ui/core'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import DeviceAvatar from '~/components/device-avatar'
import { useNavigate } from 'react-router-dom'

class Device {
	documentId: string
	deviceName: string
	deviceIcon: string
	constructor(documentId: string, deviceName: string, deviceIcon: string) {
		this.documentId = documentId
		this.deviceName = deviceName
		this.deviceIcon = deviceIcon
	}
}

const getDocumentRef = async (userId: string) => {
	const documentRef = firebase
		.firestore()
		.collection('network-credentials')
        .doc(userId)
        .collection('credentials-list')

	return documentRef
}

const getList = async (userId: string) => {
	const documentRef = await getDocumentRef(userId)
	const data: Device[] = []
	const querySnapshot = await documentRef.get()
	querySnapshot.forEach(function (doc) {
		const device = new Device(
			doc.id,
			doc.data().deviceName,
			doc.data().deviceIcon
		)
		data.push(device)
	})
	return data
}

const getUserUid = async () => {
	const user = await firebase.auth().currentUser
	// TODO: Manage the object can possibly be null
	return user!.uid
}
const DevicesList: React.FC = () => {
	const navigate = useNavigate()
	const [devices, setDevices] = useState<Device[]>()
	const handeList = async () => {
		const uid = await getUserUid()
		const data = await getList(uid)
		if (data !== undefined) {
			setDevices(data)
		}
	}

	const updateDeviceClick = (documentId: string) =>{
		navigate(`credentials/update/${documentId}`)
	}

	useEffect(() => {
		handeList()
	}, [])

	if (devices === undefined) {
		return <></>
	} else {
		return (
			<>
				<Suspense fallback={<LinearProgress />}>
					<List>
						{devices.map((device, key) => (
							<ListItem button key={key} onClick={() => updateDeviceClick(device.documentId)}>
									<ListItemAvatar>
										<DeviceAvatar
											resourceId={device.deviceIcon}
										/>
									</ListItemAvatar>
								
								<ListItemText primary={device.deviceName} />
							</ListItem>
						))}
					</List>
				</Suspense>

				<Divider />
			</>
		)
	}
}

export default DevicesList
