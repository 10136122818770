import axios from 'axios'
import {
	cacheAdapterEnhancer,
	retryAdapterEnhancer,
	throttleAdapterEnhancer,
} from 'axios-extensions'
import _ from 'lodash'

const getApiAdapter = _.flowRight([
	retryAdapterEnhancer,
	// https://css-tricks.com/debouncing-throttling-explained-examples/
	throttleAdapterEnhancer,
	cacheAdapterEnhancer,
])

const ApiAdapter = getApiAdapter(axios.defaults.adapter, {
	// * Some caching is already done by SWR
	enabledByDefault: false,
})

export default ApiAdapter
