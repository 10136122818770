import React, {useState} from "react";
import {Backdrop, Box, Button, CircularProgress, FormControl, Grid, Input, InputLabel} from "@material-ui/core";
import ApiInstance from "~/api";
import ErrorSnackbar from "~/components/error-snackbar";
import moment from "moment";
import Header from "~/components/header";

interface AccountHealth {
	user_firebase_exists: boolean;
	contact_exists: boolean;
	user_exists: boolean;
	user_has_credentials: boolean;
	user_password_is_in_sync: boolean;
	coworker_exists: boolean;
	nexudus_user_exists: boolean;
	nexudus_credentials_are_aligned: boolean;
	strooka_user_exists: boolean;
	delivery_addresses_match: boolean;
	strooka_credentials_aligned: boolean;
}

export const StellarAdminPanel: React.FC<unknown> = ({children}) => {
	const today = moment();
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const [month, setMonth] = useState(today.month())
	const [year, setYear] = useState(today.year())


	function handleMonthChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		setMonth(parseInt(event.target.value, 10))
	}

	function handleYearChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		setYear(parseInt(event.target.value, 10))
	}

	async function showError(errorString: string) {
		setError(errorString)
		await new Promise(resolve => setTimeout(resolve, 5 * 1000))
		setError(null)
	}

	async function generateReport() {
		setError(null)
		setLoading(true)
		try {
			const response = await ApiInstance.get(`/stellar/report/${year}/${month}`, {timeout: 540 * 1000})
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute(
				"download",
				`report-${year}-${month}.csv`
			);
			document.body.appendChild(link);
			link.click();

			// Clean up and remove the link
			link.parentNode?.removeChild(link);
		} catch (e) {
			showError(`Error processing ${e}`)
			throw e
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<Header title="Stellar admin" className={"modalHeader"}/>
			<Box padding={1}>
				<ErrorSnackbar error={error}/>
				<Backdrop open={loading}><CircularProgress color='inherit'/></Backdrop>
				<Grid container>
					<Grid item>
						<FormControl>
							<InputLabel htmlFor="month">Month</InputLabel>
							<Input id="month-of-the-year" aria-describedby="Month of the year"
								   value={month}
								   onChange={handleMonthChange} type={"number"} autoComplete={"off"}/>
						</FormControl>
					</Grid>
					<Grid item>
						<FormControl>
							<InputLabel htmlFor="year">Year</InputLabel>
							<Input id="year" aria-describedby="Year"
								   value={year}
								   onChange={handleYearChange} type={"number"} autoComplete={"off"}/>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item>
						<Button variant="contained" color="primary" disabled={!(month && year && !loading)}
								onClick={generateReport}>
							Generate
						</Button>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}
