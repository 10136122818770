import { List, Typography, Box } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import useApi from '~/hooks/use-api'

import BookingsListItem from '../bookings/list-item'
import { readNexudusDateTime } from '~/components/calendar-time-range-picker'
import {useSelector} from "react-redux";
import {RootState} from "~/store";

const TodayBookingsList: React.FC = () => {
	const { data } = useApi<any[]>('/bookings/fullCalendarBookings', {
		params: {
			start: moment().startOf('hour').format('YYYY-MM-DDTHH:mm:ss'),
			end: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
			showAll: false,
		},
	})

	const profileInfo = useSelector( (state: RootState) => { return state?.user?.user;  } );

	const bookings = data.map((value) => ({
			id: value.id,
			resourceId: value.resourceId,
			resourceName: value.resourceName,
			coworkerId: value.coworkerId,
			coworkerFullName: value.coworkerFullName,
			startDate: readNexudusDateTime(value.start),
			endDate: readNexudusDateTime(value.end),
			resourceType: value.resourceTypeId.toString(),
		})).filter((event) => {
			return (
				event.endDate.isAfter(moment()) &&
				event.endDate.isBefore(moment().endOf('day')))
		}).sort((e1, e2) => {
			return e1.startDate > e2.startDate ? 1 : -1
		})

	if (!bookings.length) {
		return (
			<Box paddingX={2}>
				<Typography variant='subtitle1'>
					Non hai nessuna prenotazione attiva per oggi
				</Typography>
			</Box>
		)
	} else {
		return (
			<List disablePadding>
				{bookings.map((item) => (
					<BookingsListItem
						key={`item-${item.id}`}
						{...item}
						button
						disabled={
							item.coworkerId !== profileInfo?.IdString
						}
						component={Link}
						to={`/bookings/${item.id}`}
					/>
				))}
			</List>
		)
	}
}

export default TodayBookingsList
