import {
	makeStyles,
	CircularProgress,
	Typography,
	Grid,
	List,
	ListItemText,
	ListItem,
	ListItemIcon,
	Divider,
	Backdrop,

} from '@material-ui/core'
import {
	AirConditioner,
	Cctv,
	CoffeeMaker,
	Cup,
	Deskphone,
	FoodApple,
	Information,
	KeyVariant,
	MicrophoneVariant,
	PhoneClassic,
	Projector,
	ProjectorScreen,
	Radiator,
	Television,
	WhiteBalanceSunny,
	Wifi,
} from 'mdi-material-ui'
import ApiInstance from '~/api'
import PropTypes, { InferProps } from 'prop-types'
import React, { useCallback, useState, useEffect, Suspense } from 'react'
import { NexodusRoom } from '~/models'
import useApi from '~/hooks/use-api'
import RoomCardImage from '~/components/room-card-image'
import RoomAvatar from '~/components/room-avatar'
import {useSelector} from "react-redux";
import {RootState} from "~/store";

const useStyles = makeStyles((theme) => ({
	list: {
		paddingBottom: theme.spacing(16),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1
	},
	roomAvatar:{
		display: 'grid',
		justifyContent: 'flex-end',
		paddingRight: '4px',
	},
	image:{
		maxWidth: 'none !important',
		marginLeft: '0px !important',
		marginRight: '0px !important'
	}
}))


const RoomDetailProps = {
	resourceId: PropTypes.number.isRequired,
	showRoomHeader: PropTypes.bool,
	nexodusRoom: NexodusRoom,
	customListElement: PropTypes.element
}

const RoomDetail: React.FC<InferProps<typeof RoomDetailProps>> = ({
	resourceId,
	showRoomHeader = true,
	nexodusRoom = null,
	customListElement = null
}) => {
	const classes = useStyles()
	const [detailRoom, setDetailRoom] = useState<NexodusRoom>()
	const [loading, setLoading] = useState(true)

	// const testResource = useSelector((state: RootState) => { return state?.bookings?.bookingsResources; });
	
	const getRoom = () => {
		setLoading(true)
		ApiInstance.get(`/bookings/search`, {
			cache: true,
			params: {
				resourceid: resourceId,
				showall: false
			},
		}).then(response => {
			const resources = response.data['Resources']
			resources.forEach((room: any) => {

				if (room['Id'] === resourceId) {
					setDetailRoom(room as NexodusRoom)
					setLoading(false)
				}
			})
		}).catch(error => {
			console.log(error)
			setLoading(false)
		})
	}


	useEffect(() => {
		if (!nexodusRoom){
			getRoom()
		}else{
			console.log(nexodusRoom)
			setDetailRoom(nexodusRoom)
			setLoading(false)
		}

	}, [])

	const header = () => {
		if (showRoomHeader)
			return(
				<>
						{detailRoom?.Name && (
						<Grid container>
								<Grid item xs={4} className={classes.roomAvatar}>
									<RoomAvatar resourceId={resourceId}/>
								</Grid>
								<Grid item xs={8}>
									<Typography variant="h5">{detailRoom?.Name}</Typography>
								</Grid>
						</Grid>
						)}
				</>
			)
	}

	return (
			<>
			<Backdrop open={loading} className={classes.backdrop}>
				<CircularProgress/>
			</Backdrop>
			<Suspense fallback={<CircularProgress />}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{header()}

				</Grid>

				<>
					{customListElement}
				</>
				{detailRoom?.Description && (
					<Grid item xs={12}>
						<Typography>
							<span
									dangerouslySetInnerHTML={{
										__html: detailRoom?.Description,
									}}
							/>
						</Typography>

					</Grid>
				)}




			<Grid item xs={12}>
				<List>
				{detailRoom?.Allocation && (
					<>
					<ListItem className='room'>
						<Typography className='regular2'>Capienza</Typography>
					</ListItem>
					<ListItem>
							<Typography variant='subtitle2'>{`${detailRoom?.Allocation} ${detailRoom?.Allocation > 1 ? "Players" : "Player"}`}</Typography>

					</ListItem>

					</>
				)}

				<Divider />


					{detailRoom?.SecurityLock && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<KeyVariant />
							</ListItemIcon>
							<ListItemText primary='Apertura con chiave' className='room'/>
						</ListItem>
					)}
					{detailRoom?.AirConditioning && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<AirConditioner />
							</ListItemIcon>
							<ListItemText primary='Aria condizionata' className='room'/>
						</ListItem>
					)}
					{detailRoom?.Drinks && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<Cup />
							</ListItemIcon>
							<ListItemText primary='Bibite' className='room'/>
						</ListItem>
					)}
					{detailRoom?.TeaAndCoffee && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<CoffeeMaker />
							</ListItemIcon>
							<ListItemText primary='Caffè e/o the' className='room'/>
						</ListItem>
					)}
					{detailRoom?.Catering && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<FoodApple />
							</ListItemIcon>
							<ListItemText primary='Frutta e/o snacks' className='room'/>
						</ListItem>
					)}
					{detailRoom?.Internet && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<Wifi />
							</ListItemIcon>
							<ListItemText primary='Internet ad alta velocità' className='room'/>
						</ListItem>
					)}
					{detailRoom?.WhiteBoard && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<ProjectorScreen />
							</ListItemIcon>
							<ListItemText primary='Lavagna' className='room'/>
						</ListItem>
					)}
					{detailRoom?.NaturalLight && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<WhiteBalanceSunny />
							</ListItemIcon>
							<ListItemText primary='Luce naturale' className='room'/>
						</ListItem>
					)}
					{detailRoom?.Projector && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<Projector />
							</ListItemIcon>
							<ListItemText primary='Proiettore' className='room'/>
						</ListItem>
					)}
					{detailRoom?.VoiceRecorder && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<MicrophoneVariant />
							</ListItemIcon>
							<ListItemText primary='Registratore vocale' className='room'/>
						</ListItem>
					)}
					{detailRoom?.Heating && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<Radiator />
							</ListItemIcon>
							<ListItemText primary='Riscaldamento' className='room'/>
						</ListItem>
					)}
					{detailRoom?.ConferencePhone && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<Deskphone />
							</ListItemIcon>
							<ListItemText primary='Telefono per conferenze' className='room'/>
						</ListItem>
					)}
					{detailRoom?.StandardPhone && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<PhoneClassic />
							</ListItemIcon>
							<ListItemText primary='Telefono' className='room'/>
						</ListItem>
					)}
					{detailRoom?.LargeDisplay && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<Television />
							</ListItemIcon>
							<ListItemText primary='Televisione' className='room'/>
						</ListItem>
					)}
					{detailRoom?.CCTV && (
						<ListItem dense>
							<ListItemIcon className='room'>
								<Cctv />
							</ListItemIcon>
							<ListItemText primary='Videosorveglianza' className='room'/>
						</ListItem>
					)}
					</List>
			</Grid>
				<Grid item xs={12} xl={6}>
						{detailRoom?.HasImage && (
							<RoomCardImage className={classes.image} resourceId={detailRoom?.Id} elevation={0}/>
						)}
				</Grid>
			</Grid>
			</Suspense>
			</>
        )

			}



export default RoomDetail
