import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as NbWifiBotSvg } from '~/assets/images/icons/devices/nb-wifi-bot.svg'

function NbWifiBot(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbWifiBotSvg />
		</SvgIcon>
	)
}

export default NbWifiBot
