import React, {useEffect, useState} from 'react'

import DailyPassBooking from './dailypass'
import Header from '~/components/header'
import {makeStyles} from "@material-ui/core";
import {GetBookingCredit, CreditKind} from '~/services/booking-credits-service'

interface BookingCredit {
	CreditName: string;
	TotalCredit: number;
	RemainingCredit: number;
	TotalHours: number;
	HoursLeft: number;
}

const useStyles = makeStyles((theme) => ({
	remainingCredits: {
		background: '#B9BDDB',
		borderRadius: '18px',
		padding: '2px 6px',
		display: 'inline-block',
		minWidth: '50px',
		textAlign: 'center',
		fontSize: '1rem'
	}
}))

const DailyPass: React.FC = () => {

	const [credits, setCredits] = useState('--')

	useEffect(()=>{
		getInfo();
	}, [])

	const getInfo = async() => {
		setCredits(await GetBookingCredit(CreditKind.DAILYDESK))
	}

	const classes = useStyles();
	const remainingCredits = <span className={classes.remainingCredits}>{credits}</span>

	return (
		<>
			<Header title='Prenota desk' className={"modalHeader"} endButton={remainingCredits} />
			<DailyPassBooking credits={credits}/>
		</>
	)
}

export default DailyPass
