import { Fab, makeStyles, FabProps } from '@material-ui/core'
import clsx from 'clsx'
import PropTypes, { InferProps } from 'prop-types'
import React from 'react'

const POSITION_CENTER = 'center'
const POSITION_RIGHT = 'right'

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: theme.shape.borderRadius,
		bottom: 'env(safe-area-inset-bottom, 0)',
		margin: theme.spacing(2),
		position: 'fixed',
	},
	fullWidth: {
		width: '-webkit-fill-available',
		// maxWidth: 500,
	},
	positionCenter: {
		// left: '50%',
		// transform: 'translateX(-50%)',
	},
	positionRight: {
		right: 0,
	},
	icon: {
		marginRight: theme.spacing(1),
	},
}))

const ExtendedFabProps = {
	fullWidth: PropTypes.bool,
	icon: PropTypes.element,
	position: PropTypes.oneOf([POSITION_CENTER, POSITION_RIGHT]),
}

const ExtendedFab: React.FC<FabProps & InferProps<typeof ExtendedFabProps>> = (
	props
) => {
	const {
		children,
		className,
		color = 'secondary',
		fullWidth = true,
		icon = null,
		position = POSITION_CENTER,
		size = 'large',
		...rest
	} = props

	const classes = useStyles()

	return (
		<Fab
			{...rest}
			className={clsx(
				classes.root,
				{
					[classes.fullWidth]: fullWidth,
					[classes.positionCenter]: position === POSITION_CENTER,
					[classes.positionRight]: position === POSITION_RIGHT,
				},
				className
			)}
			color={color}
			size={size}
			variant='extended'
		>
			{!!icon &&
				React.cloneElement(icon, {
					className: classes.icon,
				})}
			{children}
		</Fab>
	)
}

export default ExtendedFab
