import {
    Button,
    LinearProgress,
} from '@material-ui/core'
import React, { useCallback, useState, useEffect, Suspense } from 'react'
import {useNavigate} from 'react-router-dom'

import TopBanner from '~/components/top-banner'

import { Wifi } from 'mdi-material-ui'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'

const WifiBannerButton: React.FC = () => {
	const navigate = useNavigate()

	const handleClick = useCallback(() => {
		navigate("/network")
	}, [navigate])

	return (
		<Button onClick={handleClick}>
			Connettiti
		</Button>
	)
}

const showBanner = async () =>{

    const user = await firebase.auth().currentUser
    if (user){
        const documentRef = firebase
            .firestore()
            .collection('network-credentials')
            .doc(user.uid)
            .collection('credentials-list')

        const doc = await documentRef.get()
        return doc.empty
    }
    return false
}

const NetworkBanner: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        showBanner().then(result =>{
            setIsVisible(result)
        })
    }, [])

    return(
        <>
        <Suspense fallback={<LinearProgress />}>
            <TopBanner visible={isVisible} text="Connettiti alla rete internet di Nana Bianca.
                Potrai gestire tutte le tue connessioni dalla tua pagina profilo" confirmButton={<WifiBannerButton/>}
                icon={<Wifi
                    fontSize='large'
                />}
            />
        </Suspense>


        </>
    )

}

export default NetworkBanner
