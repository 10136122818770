import React, { useCallback, useState, useEffect } from 'react'
import { useSearchParam } from 'react-use'
import ErrorSnackbar from '~/components/error-snackbar'

const ErrorMap: Map<string, string> = new Map<string, string>();
ErrorMap.set('1', 'You are already logged in. No further action is required on your part.');
ErrorMap.set('2', 'You are not configured to authenticate against web portal. No further action is required on your part.');
ErrorMap.set('3', 'This username is already logged into the system');
ErrorMap.set('4', 'You have been excluded.');
ErrorMap.set('5', 'The User Name and Password combination you have entered is invalid. Please try again.');

const CaptiveError: React.FC = () => {
	const statusCode = useSearchParam('statusCode')
	const [error, setError] = useState<string | undefined>(undefined)

	const handleErrorClose = useCallback(() => {
		setError(undefined)
	}, [])


	useEffect(() => {
		console.log(statusCode)
		if (statusCode)
			setError(ErrorMap.get(statusCode))
		console.log(error)
	}, [])

	return (
		<>
			<ErrorSnackbar error={error} onClose={handleErrorClose} />
		</>
	)


}

export default CaptiveError
