import ApiInstance from "~/api";
import {NexudusBookingCredits, NexudusAllowances} from '~/models/index'

export enum CreditKind {
	DAILYDESK = 'daily pass credit',
	BOOKINGROOM = 'meeting'
}


export const GetBookingCredit = async (creditKind: CreditKind): Promise<string> => {
	let remaining_credits = 0
	const response = await (await ApiInstance.get('/allowances', {cache: false})).data as NexudusAllowances
	if (response && response.BookingCredits) {
		response.BookingCredits.filter((bookCredit: NexudusBookingCredits) => {
			if (bookCredit.CreditName.toLocaleLowerCase().includes(creditKind)) {
				remaining_credits += bookCredit.RemainingCredit
			}
		})
	}
	let credit;
	if (remaining_credits === 0) {
		console.error('Not valid credit found!')
		credit = '--'
	} else {
		credit = `${remaining_credits} €`
	}
	return credit
}

