import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as NbQrCodeSvg } from '~/assets/images/icons/nb-qrcode.svg'
import { ReactComponent as NbFbMessengerIconSvg } from '~/assets/images/icons/nb-fbmessenger.svg'

export function NbQrCodeIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbQrCodeSvg />
		</SvgIcon>
	)
}


export function NbFacebookMessenger(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbQrCodeSvg />
		</SvgIcon>
	)
}

