import { Snackbar, IconButton, makeStyles } from '@material-ui/core'
import React from 'react'
import _ from 'lodash'
import { Close } from 'mdi-material-ui'
import PropTypes, { InferProps } from 'prop-types'

const AUTO_HIDE_DURATION = 10 * 1000

const useStyles = makeStyles(() => ({
	snackbar: {
		marginBottom: 'env(safe-area-inset-bottom, 0)',
	},
}))

const ErrorSnackbarProps = {
	error: PropTypes.oneOfType([PropTypes.instanceOf(Error), PropTypes.string]),
	onClose: PropTypes.func,
}

const ErrorSnackbar: React.FC<InferProps<typeof ErrorSnackbarProps>> = ({
	error = null,
	onClose = null,
}) => {
	const classes = useStyles()

	const message = _.isError(error) ? error?.message : error
	const open = !!message

	return (
		<Snackbar
			action={
				!!onClose && (
					<IconButton
						aria-label='close'
						color='inherit'
						onClick={onClose}
						size='small'
					>
						<Close fontSize='small' />
					</IconButton>
				)
			}
			autoHideDuration={AUTO_HIDE_DURATION}
			className={classes.snackbar}
			message={message}
			onClose={onClose ?? undefined}
			open={open}
		/>
	)
}

ErrorSnackbar.propTypes = ErrorSnackbarProps

export default ErrorSnackbar
