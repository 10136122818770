import { useMediaQuery, useTheme } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

type BreakpointOrNull = Breakpoint | null

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */

const useBreakpoint = (): Breakpoint => {
	const theme = useTheme()

	const [minBreakpoint] = theme.breakpoints.keys
	const keys = [...theme.breakpoints.keys].reverse()

	const breakpoint =
		keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const matches = useMediaQuery(theme.breakpoints.up(key))
			return !output && matches ? key : output
		}, null) || minBreakpoint

	return breakpoint
}

export default useBreakpoint
