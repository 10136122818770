import React, { useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import CardSection from '~/routes/dashboard/profile/wallet/CardSection'
import ApiInstance from '~/api'
import { useNavigate } from 'react-router-dom'
import { SnackbarContent } from '@material-ui/core'
import { string } from 'prop-types'

type Nullable<T> = T | undefined | null

export interface StripeError {
	code: string;
	message: string;
	type: string;
}

export default function CardSetupForm() {
	const stripe = useStripe()
	const elements = useElements()
	const navigate = useNavigate()
	const [error, setError] = useState('')
	const [inFlight, setInFlight] = useState(false)

	const handleSubmit = async (event: any) => {
		setError('')
		setInFlight(true)
		event.preventDefault()

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return
		}
		const card = elements.getElement(CardElement)
		if (card === null) return

		const clientSecret = (await ApiInstance.get('/stripe/wallet/setup'))
			.data['client_secret']

		const result = await stripe.confirmCardSetup(clientSecret, {
			payment_method: {
				card: card,
				billing_details: {
					name: 'Jenny Rosen',
				},
			},
		})
		if (result.error && result.error.message != undefined) {
			setError(result.error.message)
			console.log(result.error)
		} else {
			navigate('/profile/wallet')
			// The setup has succeeded. Display a success message and send
			// result.setupIntent.payment_method to your server to save the
			// card to a Customer
		}
		setInFlight(false)
	}

	return (
		<form onSubmit={handleSubmit}>
			{error && <div>{error}</div>}
			<CardSection />
			<button disabled={!stripe || inFlight}>Save Card</button>
		</form>
	)
}
