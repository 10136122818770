import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	makeStyles,
	Radio,
	RadioGroup,
	Typography,
} from '@material-ui/core'
import WifiLockIcon from '@material-ui/icons/WifiLock';
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import {Capacitor} from '@capacitor/core'
import { FirebaseAuthentication } from '@robingenz/capacitor-firebase-authentication';
import { AccountCash, AccountDetails, Brightness4, Wifi, Send } from 'mdi-material-ui'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Flex from '~/components/flex'
import ErrorBoundary from '~/components/error-boundary'
import useColorScheme, { DARK_COLOR_SCHEME, LIGHT_COLOR_SCHEME, SYSTEM_COLOR_SCHEME, } from '~/hooks/use-color-scheme'
import ProfileEdit from './edit/profile-edit'
import UserAvatar from '~/components/user-avatar'
import { ENABLE_DARK_THEME_FEATURE, ENABLE_GUEST_NETWORK_TOKEN, ENABLE_PAYMENT_FEATURE, ENABLE_TEAM_MANAGEMENT, ENABLE_COIN_MANAGEMENT } from '~/settings'
import useApi from "~/hooks/use-api";
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PersonIcon from '@material-ui/icons/Person';
import TeamIcon from '@material-ui/icons/Group'
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import FioriniIcon from '~/components/icons/fiorini';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment, {Moment} from "moment";
import { useSelector } from 'react-redux';
import userStates from "~/store/user/reducers";
import { RootState } from '~/store';
import ApiInstance from "~/api";
import { NexudusCoworker } from "~/models";

const STELLAR_USERS = ['4pT7d49aGuXQYb6vmdt9zUnYH2m2', 'dCEkjcm5PjdNyQzWV0ZmuryzlP52', 'up3OYi6d9VbHuZ8gctQboKH1JTd2','TbPHaHG5GST0F3VntjWiSse6Jcr2'];

const useStyles = makeStyles((theme) => ({
	avatar: {
		marginBottom: theme.spacing(1),
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
}))

interface BookingCredit {
	CreditName: string;
	TotalCredit: number;
	RemainingCredit: number;
	TotalHours: number;
	HoursLeft: number;
}

class ActiveContract {
	RenewalDate: Moment
	Id: number
	constructor(data: any) {
		this.RenewalDate = moment(data.RenewalDate)
		this.Id = data.Id
	}
	getRenewalDelta() {
		return this.RenewalDate.diff(moment(), 'days')
	}
}

class UserPermission {
	sendPush: boolean
	manageTeamCredits: boolean

	constructor() {
		this.sendPush = false
		this.manageTeamCredits = false
	}

	async loadPermissions(uuid: string) {
		const querySnapshot = await firebase.firestore().collection('users')
			.doc(uuid).collection('permissions').get()
		querySnapshot.forEach((snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => {
			if (snapshot.id == 'sendPush') {
				this.sendPush = snapshot.get('allowed')
			} else if (snapshot.id == 'manageTeamCredits') {
				this.manageTeamCredits = snapshot.get('allowed')
			}
		})
	}
}

const ProfileTab: React.FC = () => {
	const [colorScheme, setColorScheme] = useColorScheme()
	const [showThemeDialog, setShowThemeDialog] = useState(false)
	// const [coworker, setCoworker] = useState<NexudusCoworker>()
	const [userProfile, setUserProfile] = useState<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null>(null)
	const [userPermissions, setUserPermissions] = useState<UserPermission>(new UserPermission());
	const navigate = useNavigate()
	const profileInfo = useSelector((state: RootState) => { return state?.user?.user; });
	const currentUserUid = firebase.auth().currentUser?.uid

	useEffect(() => {
		(async () => {
			if (!userProfile) {
				const ref = firebase.firestore().collection('users').doc(currentUserUid)
				ref.get().then((snapshot) => {
					setUserProfile(snapshot)
				})
			}
			if (currentUserUid) {
				const permissions = new UserPermission()
				await permissions.loadPermissions(currentUserUid)
				setUserPermissions(permissions)
			}
		})()
	}, [currentUserUid])

	const handleThemeButtonClick = useCallback(async () => {
		setShowThemeDialog(true)
	}, [])

	const handleThemeDialogClose = useCallback(async () => {
		setShowThemeDialog(false)
	}, [])

	const handleThemeChange = useCallback(
		(event) => {
			setColorScheme(event.target.value)
		},
		[setColorScheme]
	)

	const handleUserEditClick = useCallback(async () => {
		navigate('edit')
	}, [navigate])

	const handleLogoutButtonClick = useCallback(async () => {
		await Promise.all([
			Capacitor.isNativePlatform()
				? await FirebaseAuthentication.signOut()
				: undefined,
			firebase.auth().signOut(),
		])
		navigate('/login')
	}, [navigate])

	const handleCookieConfiguration = () =>{
		(window as any)["CookieScript"]['instance'].show()
	}

	const handleNetworkButtonClick = useCallback(async () => {
		navigate('/network')
	}, [navigate])

	const handleTeamManagement = useCallback(async () => {
		navigate("/profile/team")
	}, [navigate]);

	const handleBenefitsManagement = useCallback(async () => {
		navigate("/profile/benefits")
	}, [navigate]);

	const { data } = useApi<{ 'BookingCredits': BookingCredit[]; 'ActiveContracts': ActiveContract[] }>('/allowances')
	const bookingCredits = data.BookingCredits
	const activeContracts = data.ActiveContracts.map(c => new ActiveContract(c))
	let hasTeams = false
	if ((profileInfo && profileInfo.Teams && profileInfo.Teams.length > 0) || (profileInfo && profileInfo.TeamNames && profileInfo.TeamNames !== '')) {
		hasTeams = true
	} else {
		hasTeams = false
	}
	const benefitsData = useApi<any>('/coins');


	const showStellarAdmin = profileInfo?.Email.endsWith('stellarfood.it') ||
		STELLAR_USERS.includes(currentUserUid || "")


	return (
		<>
			<Dialog onClose={handleThemeDialogClose} open={showThemeDialog}>
				<DialogTitle>Seleziona un tema</DialogTitle>
				<DialogContent>
					<FormControl component='fieldset'>
						<RadioGroup
							onChange={handleThemeChange}
							name='theme'
							value={colorScheme}
						>
							<FormControlLabel
								value={LIGHT_COLOR_SCHEME}
								control={<Radio />}
								label='Chiaro'
							/>
							<FormControlLabel
								value={DARK_COLOR_SCHEME}
								control={<Radio />}
								label='Scuro'
							/>
							<FormControlLabel
								value={SYSTEM_COLOR_SCHEME}
								control={<Radio />}
								label='Sistema'
							/>
						</RadioGroup>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button color='primary' onClick={handleThemeDialogClose}>
						Chiudi
					</Button>
				</DialogActions>
			</Dialog>

			<Flex paddingTop={2} paddingBottom={4}>
				<Box>
					<ErrorBoundary>
						<Suspense fallback={<LinearProgress />}>
							<UserAvatar />
						</Suspense>
					</ErrorBoundary>
					<Divider />
				</Box>

				<List subheader={<ListSubheader>Utente</ListSubheader>}>
					<ListItem button onClick={handleUserEditClick}>
						<ListItemIcon>
							<PersonIcon fontSize={"small"} color={"primary"} />
						</ListItemIcon>
						<ListItemText primary='Dati personali' />
					</ListItem>


					<ListItem button onClick={handleNetworkButtonClick}>
						<ListItemIcon>
							<WifiLockIcon fontSize={"small"} color={"primary"} />
						</ListItemIcon>
						<ListItemText primary='Internet Access' />
					</ListItem>
					{ENABLE_GUEST_NETWORK_TOKEN && (
						<ListItem button onClick={() => { navigate('/network/guest') }}>
							<ListItemIcon>
								<Wifi fontSize={"small"} color={"primary"} />
							</ListItemIcon>
							<ListItemText primary='Guests Access' />
						</ListItem>
					)}
				</List>
				<Divider />

				{(bookingCredits.length > 0 || activeContracts.length > 0) &&
					<>
						<List subheader={<ListSubheader>Il tuo piano</ListSubheader>}>
							{activeContracts.map((c: ActiveContract, index) => (
								<ListItem key={index}>
									<ListItemIcon>
										<DesktopMacIcon fontSize={"small"} color={"primary"} />
									</ListItemIcon>
									<ListItemText>
										<Grid container justifyContent="space-between">
											<Grid item xs={6}>Scrivania</Grid>
											<Grid item xs={6} style={{ textAlign: "right" }}>
												{c.getRenewalDelta()} giorni rimanenti
										</Grid>
										</Grid>
									</ListItemText>
								</ListItem>
							))}

							{bookingCredits.map((bookingCredit: BookingCredit) => (
								<ListItem key={bookingCredit.CreditName}>
									<ListItemIcon>
										<MeetingRoomIcon fontSize={"small"} color={"primary"} />
									</ListItemIcon>
									<ListItemText>
										<Grid container justifyContent="space-between">
											<Grid item xs={6}>
												<Typography noWrap>{bookingCredit.CreditName}</Typography>
											</Grid>
											<Grid item xs={6} style={{ textAlign: "right" }}>
												{bookingCredit.TotalHours && <Typography>{bookingCredit.HoursLeft} ore rimanenti</Typography>}
												{bookingCredit.TotalCredit && <Typography>{bookingCredit.RemainingCredit} &euro; rimanenti</Typography>}
											</Grid>
										</Grid>
									</ListItemText>
								</ListItem>
							))}

							{benefitsData.data && benefitsData.data.coins >= 0 &&
								<ListItem>
									<ListItemIcon>
										<FioriniIcon fontSize={"small"} color={"primary"} />
									</ListItemIcon>
									<ListItemText>
										<Grid container justifyContent="space-between">
											<Grid item xs={6}>
												<Typography noWrap>Fiorini</Typography>
											</Grid>
											<Grid item xs={6} style={{ textAlign: "right" }}>
												{<Typography>{Math.round(benefitsData.data.coins)} rimanenti</Typography>}
											</Grid>
										</Grid>
									</ListItemText>
								</ListItem>
							}
						</List>
						<Divider />
					</>
				}

				{ENABLE_PAYMENT_FEATURE && (
					<List subheader={<ListSubheader>Pagamenti</ListSubheader>}>
						<ListItem
							button
							onClick={() => {
								navigate('/profile/wallet')
							}}
						>
							<ListItemIcon>
								<AccountCash fontSize={"small"} color={"primary"} />
							</ListItemIcon>
							<ListItemText primary='Personal Wallet' />
						</ListItem>

						<Divider />
					</List>
				)}

				{ENABLE_DARK_THEME_FEATURE && (
					<>
						<List subheader={<ListSubheader>App</ListSubheader>}>
							<ListItem button onClick={handleThemeButtonClick}>
								<ListItemIcon>
									<Brightness4 />
								</ListItemIcon>

								<ListItemText primary='Seleziona un tema' />
							</ListItem>
						</List>
						<Divider />
					</>
				)}

				<Divider />
				<List subheader={<ListSubheader>Startup Manager</ListSubheader>}>
					{hasTeams &&
						<ListItem button onClick={handleTeamManagement}>
							<ListItemIcon>
								<TeamIcon fontSize={"small"} color={"primary"} />
							</ListItemIcon>
							<ListItemText primary='Il mio Team' />
						</ListItem>
					}
				</List>

				<Divider />

				{userProfile?.get('isSuperAdmin') &&
					<List subheader={<ListSubheader>Admin</ListSubheader>}>
						<ListItem
							button
							onClick={() => { navigate('/admin/account-health') }}>
							<ListItemIcon>
								<AccountDetails fontSize={"small"} color={"primary"} />
							</ListItemIcon>
							<ListItemText primary='Account Health' />
						</ListItem>
						<ListItem
							button
							onClick={() => { navigate('/admin/notification') }}>
							<ListItemIcon>
								<Send fontSize={"small"} color={"primary"} />
							</ListItemIcon>
							<ListItemText primary='Invia una notifica' />
						</ListItem>
						<Divider />
					</List>
				}
				{(showStellarAdmin || userPermissions.manageTeamCredits) &&
					<List subheader={<ListSubheader>Stellar</ListSubheader>}>
						<ListItem
							button
							onClick={() => { navigate('/profile/stellar') }}>
							<ListItemIcon>
								<AccountDetails fontSize={"small"} color={"primary"} />
							</ListItemIcon>
							<ListItemText primary='Stellar Admin' />
						</ListItem>
						{userPermissions.manageTeamCredits &&
							<ListItem
								button
								onClick={() => {
									navigate('/profile/fiorini-management')
								}}>
								<ListItemIcon>
									<AccountDetails fontSize={"small"} color={"primary"}/>
								</ListItemIcon>
								<ListItemText primary='Fiorini team management'/>
							</ListItem>
						}
						<Divider />
					</List>
				}

				<List>
					<a href="https://sites.google.com/view/nanabianca-legal/privacy-policy" target="_blank"
					   style={{textDecoration:"none", color: "rgba(0, 0, 0, 0.87)"}} rel="noreferrer">
						<ListItem button>
							<ListItemIcon>
								<InfoOutlinedIcon fontSize={"small"}/>
							</ListItemIcon>

							<ListItemText primary='Privacy Policy'/>
						</ListItem>
					</a>
					<a href="https://sites.google.com/view/nanabianca-legal/cookie-policy" target="_blank"
					   style={{textDecoration:"none", color: "rgba(0, 0, 0, 0.87)"}} rel="noreferrer">
					<ListItem button>
						<ListItemIcon>
							<InfoOutlinedIcon fontSize={"small"}/>
						</ListItemIcon>

						<ListItemText primary='Cookie Policy'/>
					</ListItem>
					</a>
					<a style={{textDecoration:"none", color: "rgba(0, 0, 0, 0.87)"}} rel="noreferrer">
						<ListItem button onClick={handleCookieConfiguration}>
							<ListItemIcon>
								<InfoOutlinedIcon fontSize={"small"}/>
							</ListItemIcon>

							<ListItemText primary='Cookie Configuration'/>
						</ListItem>
					</a>
					<ListItem button onClick={handleLogoutButtonClick}>
						<ListItemIcon>
							<PowerSettingsNewIcon fontSize={"small"} color='error' />
						</ListItemIcon>

						<ListItemText primary='Logout' />
					</ListItem>
				</List>
			</Flex>
		</>
	)
}

const ProfileSwitch: React.FC = () => (
	<ErrorBoundary>
		<Suspense fallback={<LinearProgress />}>
			<Routes>
				<Route element={<ProfileTab />} path='/' />
				<Route element={<ProfileEdit />} path='edit' />
			</Routes>
		</Suspense>
	</ErrorBoundary>
)

export default ProfileSwitch
