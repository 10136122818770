import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle, Grid,
	IconButton,
	makeStyles,
	Typography,
} from '@material-ui/core'
import {ArrowLeft, Close} from 'mdi-material-ui'
import moment, {Moment} from 'moment'
import PropTypes from 'prop-types'
import React, {FunctionComponent, useCallback, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import FirebaseAnalytics from '~/components/firebase-analytics'
import ApiInstance from '~/api'
import ErrorSnackbar from '~/components/error-snackbar'
import ExtendedFab from '~/components/extended-fab'
import FullScreenDialog from '~/components/full-screen-dialog'
import Header from '~/components/header'
import TimeRangePicker from '~/components/time-range-picker'

import BookingsListItem from '../routes/dashboard/bookings/list-item'
import SuccessDialog from './success-dialog'
import {NexudusCalendarBooking} from "~/models";
import {start} from "repl";

const useStyles = makeStyles((theme) => ({
	extendedFab: {
		[theme.breakpoints.up('md')]: {
			position: 'sticky',
		},
	},
	moreMeetingDetails: {
		display: 'block',
		width: '100%',
		fontSize: '.7em',
		fontFamily: "halyard-display",
		textTransform: "initial",
		marginTop: '-.7em',
		color: "rgba(0, 0, 0, 0.87)",
	},
	loadingBackdrop: {
		zIndex: theme.zIndex.modal,
		color: 'white',
	},
	resourceNameStyle: {
		color: "white",
		backgroundColor: "#0A24EB",
		padding: "0.15em 1em",
		borderRadius: 25,
		fontFamily: 'Mono45-Headline',
		fontWeight: "bold",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textAlign: "center",
	},
	selectedDate: {
		margin: '1.5em',
		fontWeight: 500,
		fontSize: "1em",
	},
	timeRangePickerContainer: {
		backgroundColor: "#EDEDED",
		overflowX: "hidden",
	}
}))

export function readNexudusDateTime(dt: string): Moment {
	if(dt.endsWith('Z')) {
		dt = dt.replace('Z', '')
	}
	return moment(dt)
}

const CalendarTimeRangePickerPropsOld = {
	onClose: PropTypes.func.isRequired,
	value: PropTypes.shape({
		allowMultipleBookings: PropTypes.bool,
		date: PropTypes.instanceOf(Date),
		resourceId: PropTypes.number.isRequired,
		resourceName: PropTypes.string.isRequired,
	}),
}

type CalendarTimeRangePickerProps = {
	onClose: any;
	allowMultipleBookings: boolean;
	date: Moment;
	resourceId: number;
	resourceName: string;
	resourceBookings: NexudusCalendarBooking[];
}

// No need to define the defaultProps property
const CalendarTimeRangePicker: FunctionComponent<CalendarTimeRangePickerProps> = ({onClose, allowMultipleBookings, date, resourceId, resourceName, resourceBookings}) => {
	const classes = useStyles()
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState<any>(null)

	const [startDate, setStartDate] = useState<Moment | null>(date)
	const [endDate, setEndDate] = useState<Moment | null>(null)

	const [dialogOpen, setDialogOpen] = useState(false)
	const [dialogLoading, setDialogLoading] = useState(false)

	const [moreMeetingDetails, setMoreMeetingDetails] = useState('');

	const [resourceBookingPrice, setResourceBookingPrice] = useState<
		string | null
	>(null)

	/* Filter only events for this specific resource */
	resourceBookings = resourceBookings.filter((b) => {
		return b.resourceId.toString() === resourceId.toString()
	})

	const handleTimeRangeChange = (startDate: Moment | null, endDate: Moment | null) => {
		console.log(startDate?.toString(), endDate?.toString())
		setStartDate(startDate)
		setEndDate(endDate)
		if (startDate && endDate){
			const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre']
			const timedifference = ((endDate.toDate().getTime() - startDate.toDate().getTime()) / 3600000);
			const datePart1 = startDate.toDate().getDate() + " " + months[startDate.toDate().getMonth()] + " ";
			const datePart2 = startDate.toDate().getHours() + ":" + startDate.toDate().getMinutes() + " - ";
			const datePart3 = endDate.toDate().getHours() + ":" + endDate.toDate().getMinutes() + " ";
			const datePart4 = "(" + timedifference + " or" + ( timedifference > 1 ? 'e': 'a') +")";
			setMoreMeetingDetails(datePart1 + datePart2 + datePart3 + datePart4)
		} else {
			setMoreMeetingDetails('')
		}

	}

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
	}, [])

	const handleDialogButtonClick = useCallback(async () => {
		setDialogLoading(true)
		FirebaseAnalytics({name:'confirm_booking'})

		// https://learn.nexudus.com/api/public-api/bookings#create
		const path = '/bookings/newBookingJson'
		const data = {
			Booking: {
				Resource: {
					Id: resourceId,
				},
				FromTime: (startDate as Moment).format('YYYY-MM-DDTHH:mm:ss'),
				ToTime: (endDate as Moment).format('YYYY-MM-DDTHH:mm:ss'),
			},
		}

		try {
			await ApiInstance.post(path, data)
			setDialogOpen(false)
			setSuccess(true)
			FirebaseAnalytics({name:'confirm_booking_success'})
		} catch (error) {
			setError(error)
			FirebaseAnalytics({name:'confirm_booking_error'})
		} finally {
			setDialogLoading(false)
			setLoading(false)
		}
	}, [endDate, resourceId, startDate])

	const handleButtonClick = useCallback(async () => {
		setLoading(true)
		FirebaseAnalytics({name:'check_booking_price'})

		// https://learn.nexudus.com/api/public-api/bookings#booking-cost
		const path = '/bookings/getBookingPrice'
		const data = {
			Booking: {
				Resource: {
					Id: resourceId,
				},
				FromTime: (startDate as Moment).format('YYYY-MM-DDTHH:mm:ss'),
				ToTime: (endDate as Moment).format('YYYY-MM-DDTHH:mm:ss'),
			},
		}

		try {
			const response = await ApiInstance.post(path, data)

			if (!response.data.IsAvailable) {
				setLoading(false)
				setError(response.data.Message || 'La stanza non è disponibile per la prenotazione.')
				FirebaseAnalytics({name:'check_booking_price_unavailable'})
				return
			}

			if (response.data.PriceDecimal > 0) {
				setResourceBookingPrice(response.data.Price)
				setDialogOpen(true)
				setLoading(false)
				FirebaseAnalytics({name:'check_booking_price_payable'})
				return
			}
			handleDialogButtonClick()
		} catch (error) {
			setLoading(false)
			setError(error)
			FirebaseAnalytics({name:'check_booking_price_error'})

		}
	}, [handleDialogButtonClick, endDate, resourceId, startDate])

	const handleClose = useCallback(() => {
		setEndDate(null)
		onClose()
	}, [onClose])

	const handleSuccessClose = useCallback(() => {
		navigate(-1)
	}, [navigate])

	const handleErrorClose = useCallback(() => {
		setError(null)
	}, [])

	if (success)
		return (
			<SuccessDialog open onClose={handleSuccessClose}>
				Super! La tua
				<Box color='primary.text'>meeting room</Box>
				aspetta solo te
			</SuccessDialog>
		)

	return (
		<FullScreenDialog onClose={handleClose} open={!!resourceId}>
			<Backdrop className={classes.loadingBackdrop} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>

			<Grid container direction="row" alignItems="center">
				<Grid item xs={2}>
					<IconButton
						aria-label='back'
						color='inherit'
						onClick={handleClose}>
						<ArrowLeft />
					</IconButton>
				</Grid>
				<Grid item xs={8}>
					<Typography className={classes.resourceNameStyle}>{resourceName}</Typography>
				</Grid>
				{/*<Grid item xs={2}>12 ore</Grid>*/}
			</Grid>

			<Grid container>
				<Grid item xs={12} className={classes.selectedDate}>
				{date.format("DD MMMM YYYY")}
				</Grid>
			</Grid>

			<Grid container className={classes.timeRangePickerContainer}>
			{startDate!=null &&
				<TimeRangePicker
					onUserSelection={handleTimeRangeChange}
					resourceBookings={resourceBookings}
					startDate={startDate}
				/>
			}
			</Grid>

			<ExtendedFab
				className={classes.extendedFab}
				disabled={!(startDate && endDate)}
				onClick={handleButtonClick}
			>
				<span>
					Conferma
					{moreMeetingDetails !== ''? <span className={classes.moreMeetingDetails}>{moreMeetingDetails}</span> : null}
				</span>
			</ExtendedFab>

			<Dialog
				disableBackdropClick={dialogLoading}
				disableEscapeKeyDown={dialogLoading}
				onClose={handleDialogClose}
				open={dialogOpen}
			>
				<DialogTitle>Hai finito il tuo credito ore</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Le ore non comprese nel tuo piano ti verrano fatturate a
						fine mese. Il prezzo per questa prenotazione è di{' '}
						<Typography color='primary' variant='inherit'>
							{resourceBookingPrice}
						</Typography>
					</DialogContentText>

					<BookingsListItem
						disableGutters
						endDate={endDate as Moment}
						resourceId={resourceId as number}
						resourceName={resourceName as string}
						startDate={startDate as Moment}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={dialogLoading}
						onClick={handleDialogClose}
					>
						Annulla
					</Button>
					<Button
						color='secondary'
						disabled={dialogLoading}
						onClick={handleDialogButtonClick}
					>
						Sì, conferma
						{dialogLoading && (
							<CircularProgress
							// TODO
							// className={
							// 	classes.buttonCircularProgress
							// }
							// size={24}
							/>
						)}
					</Button>
				</DialogActions>
			</Dialog>
			<ErrorSnackbar error={error} onClose={handleErrorClose} />
		</FullScreenDialog>
	)
}


// CalendarTimeRangePicker.propTypes = CalendarTimeRangePickerProps

export default CalendarTimeRangePicker
