import React, { ErrorInfo } from 'react'

import ErrorSnackbar from './error-snackbar'

// * Error boundaries currently have to be classes.
class ErrorBoundary extends React.PureComponent {
	state = {
		error: null,
		showError: false,
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return {
			error,
			showError: true,
		}
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		// TODO log to an error reporting service like Sentry.
		console.warn(error, errorInfo.componentStack)
	}

	handleClose = (): void => {
		this.setState({
			showError: false,
		})
	}

	render() {
		if (this.state.error)
			return (
				<ErrorSnackbar
					error={this.state.showError ? this.state.error : null}
					onClose={this.handleClose}
				/>
			)

		/* eslint-disable-next-line react/prop-types */
		return this.props.children
	}
}

export default ErrorBoundary
