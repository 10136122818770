import { Slide, useScrollTrigger, SlideProps } from '@material-ui/core'
import React from 'react'

const SlideOnScroll: React.FC<SlideProps> = (props) => {
	const slideTrigger = useScrollTrigger({
		threshold: 250,
	})

	return (
		<Slide {...props} appear={false} direction='down' in={!slideTrigger} />
	)
}

export default SlideOnScroll
