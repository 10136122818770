import {
	Box,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	makeStyles,
	useTheme,
	ButtonBase,
	ButtonBaseProps,
	Grid,
	Typography,
} from '@material-ui/core'
import { Height } from '@material-ui/icons'
import moment from 'moment'
import React from 'react'

import GridListTileImage from '~/components/grid-list-tile-image'
import useBreakpoint from '~/hooks/use-breakpoint'
import useFeedApi from '~/hooks/use-feed-api'

const BREAKPOINT_MIN_COLS = 1.25

const useStyles = makeStyles((theme) => ({
	eventsGridList: {
		alignItems: 'center',
		flexWrap: 'nowrap',
		marginLeft: theme.spacing(-2),
		marginRight: theme.spacing(-2),
		overflowX: 'scroll',
		scrollSnapType: 'x mandatory',
		paddingBottom:"1em",
	},
	eventsGridListTile: {
		scrollSnapAlign: 'center',
		scrollSnapStop: 'always',
		marginRight: theme.spacing(2),
	},
	eventsGridListTileBar: {
		backgroundColor: theme.palette.background.paper,
	},
	eventsGridListTileBarTitle: {
		color: theme.palette.text.primary,
		overflow: 'hidden',
		minHeight: '21px',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		marginBottom: '4px',
		fontWeigth: '600',
	},
	eventsGridListTileBarSubtitle: {
		color: '#0A24EB',
		fontSize: '12px',
	},
	buttonGrid:{
		display: 'block',
	},
}))

const GridListTileButton: React.FC<ButtonBaseProps<'a'>> = (props) => (
	<Box clone height={1} width={1}>
		<ButtonBase {...props} component='a' />
	</Box>
)

const EventsGridList: React.FC = () => {
	const data = useFeedApi()
	const classes = useStyles()
	const theme = useTheme()
	const breakpoint = useBreakpoint()

	const cols =  theme.breakpoints.keys.indexOf(breakpoint) + BREAKPOINT_MIN_COLS
	let events = data
	events = data.filter((value) => moment(value.isoDate).isSameOrAfter(moment(), 'day'))
	return (
		<Box padding={2}>
			<ImageList
				className={classes.eventsGridList}
				cols={cols}>
				{events.slice(0, 5)
					.map((value, i) => (
						<ImageListItem
							key={i}
							className={classes.eventsGridListTile}
							style={{width: '95%',
							height: '250px', maxWidth: '357px'}}

						>
							<GridListTileButton
								className={classes.buttonGrid}
								href={value.link}
								rel='noopener noreferrer'
								target='_blank'
							>

								<div>
									<Typography
										className={classes.eventsGridListTileBarSubtitle}
										variant='subtitle2'
									>
										{moment(value.isoDate).format(
											'LL'
										)}
									</Typography>
									<Typography
										className={classes.eventsGridListTileBarTitle}
										variant='body1'
									>
										{value.title}
									</Typography>
								</div>

								<div>
									<img src={
											value['media:group']?.[
												'media:content'
											][0]['$'].url
										}
										alt='Event'
										loading="lazy"
										style={{objectFit:'contain', width:'100%'}}
									/>
								</div>

							</GridListTileButton>
						</ImageListItem>
					))}
			</ImageList>
		</Box>
	)
}

export default EventsGridList
