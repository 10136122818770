// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import {getMessaging, getToken, onMessage,} from "firebase/messaging";
import React, {Suspense, useCallback, useEffect, useState} from 'react'
import {useLocalStorage} from 'react-use'
import {useAsync} from 'react-async'
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {useSearchParam} from 'react-use'

import DashboardBottomNavigation from '~/components/dashboard-bottom-navigation'
import DashboardDrawer from '~/components/dashboard-drawer'
import DashboardHeader from '~/components/dashboard-header'
import Flex from '~/components/flex'
import SuccessDialog from '~/components/success-dialog'

import BookingsTab from './bookings'
import EventsTab from './events'
import NotificationsTab from './notifications'
import FoodTab, {FoodLogin} from './food'
import HomeTab from './home'
import ProfileTab from './profile'
import NetworkTab from './network'
import NetworkGuestTab from './profile/network-guest'
import WalletRoot from '~/routes/dashboard/profile/wallet'
import WalletAddPaymentMethod from '~/routes/dashboard/profile/wallet/WalletAddPaymentMethod'
import {AdminAccountHealth} from "~/routes/dashboard/profile/admin/account_health";
import {SendNotification} from "~/routes/dashboard/profile/admin/send_notification";
import TeamTab from './profile/team'
// import BenefitsTab from'./profile/benefits'
import JoinTeam from "~/routes/dashboard/profile/team/joinTeam";
import {useDispatch} from "react-redux"
import {setUser, unsetUser} from "~/store/user/actions"
import ApiInstance from "~/api";
import QRCodeTab from "~/routes/dashboard/qrcode";
import {Grid, Box, Container, LinearProgress} from "@material-ui/core";
import {StellarAdminPanel} from './profile/stellar-admin/StellarAdminPanel'
import {isWebView, isIos} from '~/services/utils-service'
import {FioriniAdminPanel} from './profile/admin/FioriniAdminPanel'
import {FioriniAdminEdit} from './profile/admin/FioriniAdminEdit'
import ErrorBoundary from "~/components/error-boundary";

const DESKTOP_MENU_URLS = [
	'/network/guest',
	'/admin/account-health',
	'/profile/team'
]

const EXCLUDED_DASHBOARD_HEADER_PATH = [
	'notifications',
]

export const getNotificationTokenAndSubscribeToTopic = async () => {

	const messaging = getMessaging()
	const registered = sessionStorage.getItem("registered");

	if (!registered) {
		getToken(messaging, {vapidKey: 'BIvErCfU2fRRzWC3m5fM8n9qBJhlyEJY5IeQQBA6UWPt0hMy2z4pL1Uyq88bXUD10SbeHkjsouLUhrdAmIIBeog'})
			.then((currentToken) => {
				if (currentToken) {
					console.log('Notification token retrived')

					ApiInstance.post('/notifications/subscribe', {
						token: currentToken,
					}).then((response) => {
						console.log('Succesfully register')
						sessionStorage.setItem("registered", 'true');
					}).catch((err) => {
						console.log(err)
					})
				} else {
					console.log('No push notification token avaiable')
				}
			}).catch((err) => {
			console.log(err)
		})
	}
}

const showPaddingNotch = () => {
	return isWebView() && isIos()
}
export const getPushMessage = async () => {
	const messaging = getMessaging()

	onMessage(messaging, (payload) => {
		console.log(payload)
	})
}
const Dashboard: React.FC = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const [currentUserSnapshot, setCurrentUserSnapshot] = useState<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null>(null)

	const submissionGuid = useSearchParam('submissionGuid')
	// * submissionGuid comes from Hubspot form
	const showSuccessDialog = !!submissionGuid

	const handleDialogClose = useCallback(async () => {
		await navigate(location.pathname, {replace: true})
	}, [location, navigate])

	const {currentUser} = firebase.auth()
	const dispatch = useDispatch();


	useEffect(() => {
		if (!currentUser) {
			dispatch(unsetUser());
			if (location.pathname === "/joinTeam") {
				const redirect = "?inviteToTeam=" + location.search;
				navigate('/login' + redirect, {replace: true})
			}
			if (location.pathname === "/food/login") {
				const urlParams = new URLSearchParams(window.location.search);
				urlParams.set('redirect', '/food/login?' + urlParams.toString())
				navigate('/login?' + urlParams.toString(), {replace: true})
			} else {
				navigate('/login', {replace: true})
			}
		} else {
			ApiInstance.get('/user/profile').then((response) => {
				dispatch(setUser(response.data))
			}).catch((error) => {
				console.log('userProfileError', error);
				dispatch(unsetUser());
				navigate('/login', {replace: true})
			});

		}

		if (currentUser) {
			(async function (currentUser) {
				const snapshot = await firebase.firestore().collection('users').doc(currentUser.uid).get()
				setCurrentUserSnapshot(snapshot)
				await getNotificationTokenAndSubscribeToTopic()
			})(currentUser)

		}

	}, [currentUser, navigate])

	if (!currentUser) return null
	const hideOnDesktop = location.pathname.match(/\/food\/login/)
	const currentPath = location.pathname.split('/')

	const showDashboardTab = () => {
		const isExcludedPath = EXCLUDED_DASHBOARD_HEADER_PATH.find(path => {
			return currentPath.includes(path)
		})
		if (isExcludedPath) {
			return false
		} else {
			return currentPath.filter(Boolean).length <= 1
		}

	}

	return (
		<>
			<Container style={{
				minHeight: '100vh',
				paddingLeft: '0px',
				paddingRight: '0px',
				paddingTop: showPaddingNotch() ? '44px' : '0px'
			}}>
				<Grid container alignItems="center" justifyContent="center">

					{!hideOnDesktop &&
						<Grid item xl={1} md={1}>
							<DashboardDrawer user={currentUserSnapshot}/>
						</Grid>
					}
					<Grid item xs={12} xl={11} md={11}>


						<Grid container>
							{showDashboardTab() &&
								<Grid item xs={12} xl={12}>
									<DashboardHeader/>
								</Grid>
							}
							<Grid item xs={12} xl={12}>

								<Routes>
									<Route element={<HomeTab/>} path='/'/>
									<Route element={<BookingsTab/>} path='bookings/*'/>
									<Route element={<EventsTab/>} path='events'/>
									<Route element={<NotificationsTab/>} path='notifications'/>
									<Route element={<FoodLogin/>} path='food/login'/>
									<Route element={<FoodTab/>} path='food'/>
									<Route element={<NetworkTab/>} path='network/*'/>
									<Route element={<NetworkGuestTab/>} path='network/guest'/>
									<Route element={<Suspense fallback={<LinearProgress/>}>
											<QRCodeTab/>
										</Suspense>} path='profile/qrcode' />
									<Route element={<StellarAdminPanel/>} path='profile/stellar/'/>
									<Route element={<FioriniAdminPanel/>} path='profile/fiorini-management/'/>
									<Route element={<FioriniAdminEdit/>} path='profile/fiorini-management/:teamId'/>
									<Route element={<ProfileTab/>} path='profile/*'/>
									<Route element={<TeamTab/>} path='profile/team/*'/>
										{/*<Route element={<BenefitsTab />} path='profile/benefits/*' />*/}
									<Route element={<WalletRoot/>} path='profile/wallet'/>
									<Route element={<JoinTeam/>} path='joinTeam'/>
									<Route element={<AdminAccountHealth/>} path='admin/account-health'/>
									<Route element={<SendNotification/>} path='admin/notification'/>
									<Route
										element={<WalletAddPaymentMethod/>}
										path='profile/wallet/add'
									/>
								</Routes>


							</Grid>
						</Grid>


						{showDashboardTab() && <DashboardBottomNavigation user={currentUserSnapshot}/>}
					</Grid>
				</Grid>
			</Container>

			<SuccessDialog open={showSuccessDialog} onClose={handleDialogClose}>
				Super! Abbiamo preso in carico la tua richiesta e ti
				ricontatteremo appena possibile
			</SuccessDialog>

		</>
	)
}


export default Dashboard
