import { Avatar, Box, SvgIconProps } from '@material-ui/core'
import PropTypes, { InferProps } from 'prop-types'
import React from 'react'


const CircleBadgeProps = {
	text: PropTypes.string.isRequired,
    fillColor: PropTypes.string,
    textColor: PropTypes.string,
}

const CircleBadge: React.FC<InferProps<SvgIconProps & typeof CircleBadgeProps>> = ({
	text,
    fillColor,
    textColor,
	...rest
}) => {

	return (
		<svg height="30" width="30">
        <g id="circle">
            <circle  fill={fillColor ? fillColor: '#0A24EB'} cx="15" cy="15" r="10">
            </circle>
            <text x="15" y="15" textAnchor="middle" stroke="none" strokeWidth="0.1em" dy=".3em" style={{fill:textColor? textColor: 'white', fontSize:'0.538em', fontFamily: "Halyard Display", fontWeight: 600,}}>{text}</text>
        </g>
        </svg>
	)
}

CircleBadge.propTypes = CircleBadgeProps

export default CircleBadge
