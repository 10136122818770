const DEFAULT_HEADER_STYLE = {
	fontFamily: 'mono45-headline',
}

const DEFAULT_EMPHASIZED_HEADER_STYLE = {
	...DEFAULT_HEADER_STYLE,
	fontWeight: 700,
	// textTransform: 'uppercase',
}

const theme = {
	overrides: {
		MuiBottomNavigationAction: {
			root: {
				minWidth: 64,
			},
		},
		MuiCssBaseline: {
			'@global': {
				html: {
					// * Using the 10px simplification
					// https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
					// fontSize: '62.5%' /* 62.5% of 16px = 10px */,
					fontSize: '80%'
				},
			},
		},
	},
	palette: {
		primary: {
			main: 'rgb(10, 36, 235)',
			text: 'rgb(12, 8, 50)',
		},
		secondary: {
			main: 'rgb(250, 0, 86)',
		},
	},
	props: {
		MuiListSubheader: {
			disableSticky: true,
		},
		MuiTooltip: {
			arrow: true,
		},
	},
	shape: {
		borderRadius: 2,
	},
	typography: {
		button: {
			letterSpacing: '0.1rem',
			fontFamily: 'mono45-headline',
		},
		fontFamily: 'halyard-display',
		h1: DEFAULT_EMPHASIZED_HEADER_STYLE,
		h2: DEFAULT_EMPHASIZED_HEADER_STYLE,
		h3: DEFAULT_EMPHASIZED_HEADER_STYLE,
		h4: DEFAULT_EMPHASIZED_HEADER_STYLE,
		h5: DEFAULT_HEADER_STYLE,
		h6: DEFAULT_HEADER_STYLE,
		// Tell Material-UI what's the font-size on the html element
		htmlFontSize: 10,
	},
}

export default theme
