import { useLocalStorage } from 'react-use'

const LIGHT_COLOR_SCHEME = 'light'
const DARK_COLOR_SCHEME = 'dark'
const SYSTEM_COLOR_SCHEME = 'system'

const useColorScheme = () => useLocalStorage('color-scheme', LIGHT_COLOR_SCHEME)

export {
	DARK_COLOR_SCHEME,
	LIGHT_COLOR_SCHEME,
	SYSTEM_COLOR_SCHEME,
	useColorScheme as default,
}
