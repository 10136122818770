import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone';
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
import FastfoodTwoToneIcon from '@material-ui/icons/FastfoodTwoTone';
import {OverridableComponent} from "@material-ui/core/OverridableComponent";
import {SvgIconTypeMap} from "@material-ui/core";
import moment from "moment/moment";

interface NavigationLink {
	icon: OverridableComponent<SvgIconTypeMap>;
	label: string;
	to: string;
	visibilityRole: string|null;
	hide: boolean|null;
}

const DASHBOARD_NAVIGATION_LINKS = [
	<NavigationLink>{ icon: DashboardTwoToneIcon, label: 'Dashboard', to: '/' },
	<NavigationLink>{ icon: AssessmentTwoToneIcon, label: 'Prenota', to: '/bookings' },
	<NavigationLink>{ icon: EventTwoToneIcon, label: 'Eventi', to: '/events' },
	<NavigationLink>{ icon: FastfoodTwoToneIcon, label: 'Food', to: '/food', hide: moment().isBefore(moment("2021-03-03 19:00"))},
	<NavigationLink>{ icon: AccountBoxTwoToneIcon, label: 'Profilo', to: '/profile' },
]

export { DASHBOARD_NAVIGATION_LINKS }
