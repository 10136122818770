import {
	Backdrop,
	Button,
	CircularProgress,
	makeStyles,
	Grid,
	Box,
	Typography,
	TextField,

} from '@material-ui/core'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import React, { useCallback, useState, useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import FirebaseAnalytics from '~/components/firebase-analytics'
import backgroundImage from '~/assets/images/piazza-cestello.png'
import  phoneImage from '~/assets/images/mobile_light.png'
import  playstoreBanner from '~/assets/images/playstore_banner.png'
import  appstoreBanner from '~/assets/images/appstore_banner.png'
import { ReactComponent as NbLogo } from '~/assets/images/nb-logo.svg'
import ErrorSnackbar from '~/components/error-snackbar'
import Flex from '~/components/flex'
import CaptiveError from '~/components/captive-error'


const useStyles = makeStyles((theme) => ({
	background: {
		backgroundColor: '#0A24EB',
		backgroundPosition: 'center',
		backgroundImage: `url(${backgroundImage})`,
		backgroundSize: 'cover',
		minWidth: '100%',
		minHeight: '100%',
		position: 'absolute',
	},
	button: {
		marginBottom: '50px',
		width: '100%',
	},
	buttonGrid: {
		display: 'grid',
		justifyContent: 'center',
	},
	buttonTest: {
		marginTop: theme.spacing(3),
	},
	loadingBackdrop: {
		color: theme.palette.common.white,
		zIndex: theme.zIndex.modal,
	},
	logo: {
		fill: theme.palette.common.white,
		maxWidth: '175px',
		marginTop: '76px',
	},
	hidden: {
		display: 'none',
	},
	title: {
		display: "grid",
		lineHeight: 0.90,
		justifyContent: 'center',
	},
	nanaPhone: {
		display: "grid",
		justifyContent: 'center',
	},
	coming:{
		marginTop: '40px',
	},
	resendCode:{
		display: 'grid',
		paddingTop: '0px',
		paddingBottom: '0px',
		marginTop: '0px',
		marginBottom: '0px',

	},
	banner:{
		filter: 'grayscale(100%)',
		padding: '5px',
		paddingRight: '10px',
	},
	txtConnect:{
		color: 'white',
		fontFamily: "Halyard Display",
		fontSize: '16px',
		fontWeight: 600,
		textTransform: 'none',
		lineHeight: '22px',
		textDecoration: 'underline'

	},
	btnResendCode:{
		color: 'white',
		fontFamily: "Halyard Display",
		fontSize: '11px',
		textTransform: 'none',
		lineHeight: '22px',
		textDecoration: 'underline',
		width: '100%',

	},
	waitCodeText:{
		fontSize:'1.9rem'
	}
}))

const getDataFromUserId = async (userId: string) => {
	const documentRef = firebase.firestore().collection('users').doc(userId)

	const document = await documentRef.get()
	if (document.exists) return document.data()

	// Wait for it to be created
	const documentSnapshot = await new Promise<firebase.firestore.DocumentSnapshot>((resolve, reject) => {
		const observer = documentRef.onSnapshot((documentSnapshot) => {
			if (documentSnapshot.exists) {
				// Unsubscribe to listener once created
				observer()
				resolve(documentSnapshot)
			}
		}, reject)
	})

	return documentSnapshot.data()
}

const PhoneLanding: React.FC = () =>{
	const classes = useStyles()
	const navigate = useNavigate()
	const [smsForm, setSmsForm] = useState(false)
	const showForm = () => {
		setSmsForm(true)
	}
	const goTokenLogin = () => {
		const queryString = window.location.search
		navigate(`/token${queryString}`)
	}

	return (
		<>
			<Flex className={classes.background} margin={0} display="block">
				<Flex
					alignItems='center'
					justifyContent='space-around'
					margin={2}
					>
					<Flex color='white' flex={0}>
					{!smsForm &&(

							<Grid container justifyContent="center" alignItems="center" spacing={3}>
								<Grid item xs={12} xl={6} md={6} className={classes.nanaPhone}>
									<img src={phoneImage} alt="Nana"/>
								</Grid>
								<Grid item xs={12} xl={6} md={6}>
									<Grid container justifyContent="center" alignItems="center" direction="column">
										<Grid item xs={12} className={classes.coming}>
											<Typography variant="h2">COMING SOON</Typography>
										</Grid>
										<Grid item xs={12}>
											<Grid container direction="row" spacing={1}>
												<Grid item xs={6} className={classes.banner}><img src={playstoreBanner} alt="Avaiable on Play store"/></Grid>
												<Grid item xs={6} className={classes.banner}><img src={appstoreBanner} alt="Avaiable on App store"/></Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Button onClick={()=> showForm()} className={classes.txtConnect}>Connettiti con un sms</Button>
										</Grid>
										<Grid item xs={12}>
											<Button onClick={goTokenLogin} className={classes.txtConnect}>Hai un token? Connettiti qui</Button>
										</Grid>
									</Grid>
							
								</Grid>
							</Grid>
							)}
							{smsForm &&(
								<PhoneLogin/>
							)}
					</Flex>
				</Flex>
				<CaptiveError/>
		</Flex>
		</>
	)
}

const PhoneLogin: React.FC = () => {
	const navigate = useNavigate()
	const classes = useStyles()
	const redirectURL = useSearchParam('redirect')
	const switchURL = useSearchParam('switch_url')

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const [userId, setUserId] = useState('')
	const [password, setPassword] = useState('')
	const [url, setUrl] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('+39')
	const [showCodeForm, setShowCodeForm] = useState(false)
	const [authCode, setAuthCode] = useState('')
	const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult>()
	const authForm = useRef<HTMLFormElement>(null)
	const bntSubmitCaptive = useRef<HTMLButtonElement>(null)
	const [recaptchaVerifier, setRecaptchaVerifier] = useState<firebase.auth.RecaptchaVerifier | undefined>()
	const phoneRegex = new RegExp("^[\+][0-9]{3}?[0-9]{3}[0-9]{4,6}$")
	const [showResend, setShowResend] = useState(false)

	const checkPhoneValidation = () =>{
		if(phoneRegex.test(phoneNumber)){
			return true
		}
		if (phoneNumber.length === 10){
			const country = new RegExp('^[\+]')
			if (!country.test(phoneNumber)){
				setError("Il numero di telefono non é valido, aggiungi il country code")
				return false
			}
		}
		setError("Il numero di telefono non é valido")
		return false
	}

	const firebaseLogin = (phone: string, captchaContainer:firebase.auth.RecaptchaVerifier) =>{
		setLoading(true)
		setTimeout(setResend, 10000)
		setShowCodeForm(true)
		
		firebase.auth().signInWithPhoneNumber(phone, captchaContainer)
				.then(confirmationResult => {
					setShowCodeForm(true)
					setConfirmationResult(confirmationResult)
					setLoading(false)
					FirebaseAnalytics({name:'phone_login_send_code'})
				})
				.catch(error => {
					setLoading(false)
					setError((error as Error).message)
					console.log(`PHONE LOGIN ERROR: ${error}`)
					FirebaseAnalytics({name:'phone_login_send_code_error'})
				})

				
	}

	const setResend = useCallback(() => {
		setShowResend(true)
		setLoading(false)
	}, [])

	const handlePhoneLogin = () => {
		const isValid = checkPhoneValidation()

		if (isValid){
			let captchaV = recaptchaVerifier
			if(!recaptchaVerifier){
				const captcha = new firebase.auth.RecaptchaVerifier('captchaContainer', {
					'size': 'invisible',
				})
				setRecaptchaVerifier(captcha)
				captchaV = captcha
			}
			if(captchaV){
				firebaseLogin(phoneNumber, captchaV)
			}
		}
	}

	

	const handleResendCode = () => {
		setShowResend(false)
		recaptchaVerifier?.clear()
		const captcha = new firebase.auth.RecaptchaVerifier('captchaContainerResend', {
			'size': 'invisible',
		})
		FirebaseAnalytics({name:'phone_login_resend_code'})
		firebaseLogin(phoneNumber, captcha)
	}

	const handleConfirmCode = useCallback(
		async (confirmationResult, authCode) => {
			setLoading(true)
			if (confirmationResult && authCode) {
				const result = await confirmationResult.confirm(authCode)
				try {
					if (switchURL) {
						const userId = result.user?.uid
						if (userId) {
							const data = await getDataFromUserId(userId)
							setUserId(userId)
							setPassword(data?.password)
							setUrl(switchURL)
							const aForm = authForm.current
							if (aForm && aForm !== null) {
								if (bntSubmitCaptive.current){
									try{
										bntSubmitCaptive.current?.click()
										FirebaseAnalytics({name:'captive_login_post'})
										console.log("CAPTIVE LOGIN SUCCESS: Post success")
									}catch(error){
										console.log(`CAPTIVE LOGIN FAILED: ${error}`)
									}
								}
							}else{
								console.log("CAPTIVE LOGIN FAILED: Missing form")
							}
						}
					}else{
						console.log("CAPTIVE LOGIN FAILED: SwitchURL params not found")
					}
				} catch (error) {
					setError((error as Error).message)
					console.log(`CAPTIVE LOGIN FAILED: ${error}`)
					FirebaseAnalytics({name:'captive_login_post_error'})
				}
				finally {
					setLoading(false)
				}

			}
			else {
				FirebaseAnalytics({name:'captive_login_error'})
				console.log("CAPTIVE LOGIN FAILED: Auth error")
				setError("Errore nell'autenticazione")
			}
		}, [navigate, switchURL, redirectURL])

	const handleConfirmClick = useCallback(
		() => handleConfirmCode(confirmationResult, authCode),
		[handleConfirmCode, confirmationResult, authCode]
	)



	const handleErrorSnackbarClose = useCallback(() => {
		setError(null)
	}, [])

	const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPhoneNumber(event.target.value)		
	}

	const handleAuthCode = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAuthCode(event.target.value)
	}
	const phoneNumberForm = (
		<>
			<Grid item xs={12} xl={12}>
				<Typography variant="h4">Accedi alla rete wifi</Typography>
					<Typography variant="body2">Inserisci il tuo numero di telefono e riceverai un codice via SMS con cui potrai accedere gratuitamente alla rete WIFI di Nana Bianca.
				</Typography>
			</Grid>
			<Grid item xs={12} xl={12}>
				<TextField
					required
					className="phoneForm"
					id='phone-number'
					onChange={handleInput}
					label='Il tuo numero di telefono'
					variant="filled"
					value={phoneNumber}
					type={"tel"}

				/>
			</Grid>

			<Grid item xs={12} xl={12} >
				<Button
					className={classes.button}
					disabled={loading}
					onClick={handlePhoneLogin}
					size='large'
					variant='contained'
					color='secondary'
				>
					Get code
				</Button>
			</Grid>
		</>
	)

	const verificationCodeForm = (
		<>
			<Grid item xs={12} xl={12}>
				<Typography variant="h4" className={classes.waitCodeText}>INSERISCI IL CODICE di verifica ricevuto tramite sms al numero <span style={{color:'#FA0056'}}>{phoneNumber}</span></Typography>
				<Typography variant="body2">Ci siamo quasi, inserisci il tuo codice personale inviato sul tuo telefono tramite SMS e sarai pronto a navigare
				</Typography>

			</Grid>
			<Grid item xs={12} xl={12}>
				<TextField
					required
					className="phoneForm"
					id='auth-code'
					onChange={handleAuthCode}
					disabled={!showCodeForm}
					label='Codice SMS'
					value={authCode}
					variant='filled'
					type={"number"}
				/>
			</Grid>
			<Grid item xs={12} xl={12} className={classes.resendCode}>
				{showResend && (
					<Button  className={classes.btnResendCode} onClick={handleResendCode}>
					Clicca qui se non hai ricevuto il codice
			   </Button>
				
				)}
			</Grid>
			
			<Grid item xs={12} xl={12}>
				<Button
					id="login"
					className={classes.button}
					disabled={loading}
					onClick={handleConfirmClick}
					size='large'
					variant='contained'
					color='secondary'
				>
					Inizia a navigare
            	</Button>
			</Grid>
		</>
	)

	let gridItem
	if (!showCodeForm) {
		gridItem = phoneNumberForm
	}
	else {
		gridItem = verificationCodeForm
	}

	return (
		<>
			<Backdrop className={classes.loadingBackdrop} open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<form action={url} method='POST' ref={authForm}
				  className={classes.hidden}>
				<input type='text' name='username' value={userId} readOnly/>
				<input type='text' name='password' value={password} readOnly/>
				<input type='text' name='buttonClicked' value='4' readOnly/>
				<button type='submit' id='auth-submit' ref={bntSubmitCaptive}/>
			</form>

						 <Grid container color='white' spacing={3}>
							<Grid item xs={12} xl={12}>
								<Box paddingBottom={10}  >
									<NbLogo className={classes.logo} title='logo' />
								</Box>
							</Grid>

							<Grid item xs={12} xl={12} className={classes.buttonGrid}>
								<Grid container alignItems="center" spacing={5}>
									{gridItem}
								</Grid>
							</Grid>

							<Grid item id='captchaContainer'></Grid>
							<Grid item id='captchaContainerResend'></Grid>
						</Grid>

			<ErrorSnackbar
				error={error}
				onClose={handleErrorSnackbarClose}
			/>

		</>
	)
}

export default PhoneLanding
