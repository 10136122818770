import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core'
import moment from 'moment'
import React, { useCallback, useState } from 'react'

import useFeedApi, { FeedItem } from '~/hooks/use-feed-api'

const sortByDateDesc = (valueA: FeedItem, valueB: FeedItem): number => {
	if (moment(valueA.isoDate).isAfter(valueB.isoDate)) return -1

	if (moment(valueA.isoDate).isBefore(valueB.isoDate)) return 1

	return 0
}

const useStyles = makeStyles((theme) => ({
	cardMedia: {
		height: 200,
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	eventDateDay: {
		color: "#0A24EB",
		fontSize: "2em",
		fontFamily: "Mono45-Headline",
		fontWeight: "bold",
		lineHeight: '0.5em'
	},
	eventDateMonth: {
		fontSize: "1.3em",
		fontFamily: "Mono45-Headline",
		fontWeight: "bold",
	},
	eventTitle: {
		color: '#0C0832',
		fontWeight: 200
	},
	cardContentCompact: {
		paddingBottom: "0px !important"
	},
	cardCompact: {
		boxShadow: "none",
	}
}))


const EventsGridItem: React.FC<FeedItem> = (props) => {
	const classes = useStyles()

	const [expanded, setExpanded] = useState(false)

	const handleExpandClick = useCallback(() => {
		setExpanded((expanded) => !expanded)
	}, [])

	const limit = 80
	const eventDate = moment(props.isoDate)
	const title = props.title.length < limit && props.title || props.title.substr(0, limit) + '...'

	return (
		<Grid item xs={12} sm={6} md={3} zeroMinWidth>
			<Card className={classes.cardCompact}>
				<CardMedia
					className={classes.cardMedia}
					image={props['media:group']['media:content'][0]['$'].url}
					title='image'
				/>
				<CardContent className={classes.cardContentCompact}>
					<Grid container>
						<Grid item xs={2}>
							<Typography className={classes.eventDateDay}>
								{eventDate.format('DD')}
							</Typography>
							<Typography className={classes.eventDateMonth}>
								{eventDate.format('MMMM').slice(0, 3)}
							</Typography>
						</Grid>
						<Grid item xs={10}>
							<Typography className={classes.eventTitle}>
								{title}
							</Typography>
						</Grid>
					</Grid>
					<Grid container direction="row"
						  justifyContent="flex-end" alignItems="center">
						<Grid item>
							<Button href={props.link}  rel='noopener noreferrer'
									size='small' target='_blank' style={{color: "#0A24EB"}}>
								Iscriviti
							</Button>
						</Grid>
					</Grid>
				</CardContent>

			</Card>
		</Grid>
	)
}

const EventsGrid: React.FC = () => {
	let data = useFeedApi()
	data = data.slice().slice().sort(sortByDateDesc)
	return (
		<Box padding={2} paddingBottom={16}>
			<Grid container spacing={2}>
				{data
					.map((value: FeedItem) => (
						<EventsGridItem key={value.link} {...value} />
					))}
			</Grid>
		</Box>
	)
}

export default EventsGrid
