
import React, {useCallback, useState, useEffect, useRef, useMemo} from 'react'

import FullCalendar from '@fullcalendar/react'
import {CalendarOptions} from '@fullcalendar/common'
import scrollGridPlugin from '@fullcalendar/scrollgrid'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import allLocales from '@fullcalendar/core/locales-all'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import {
	Backdrop,
	Box,
	CircularProgress,
	makeStyles,
	useTheme,
	Button,
	Grid,
	Dialog,
	DialogContent,
	DialogActions,
	useMediaQuery,
	Typography,
} from '@material-ui/core'
import _ from 'lodash'
import moment, {Moment} from 'moment'
import PropTypes, { InferProps } from 'prop-types'

import ApiInstance from '~/api'
import AppProvider from '~/app/provider'

import CalendarDatePicker from '../calendar-date-picker'
import ErrorSnackbar from '~/components/error-snackbar'
import CalendarTimeRangePicker, {readNexudusDateTime} from '../calendar-time-range-picker'
import Flex from '../flex'
import RoomAvatar from '../room-avatar'
import './styles.css'
import {NexudusCalendarBooking} from '~/models'
import FirebaseAnalytics from '~/components/firebase-analytics'
import {round15Min} from "~/components/time-range-picker";
import RoomDetail from '../room-detail'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "~/store";
import {setBookingEvents, setBookingResources} from "~/store/bookings/actions";
import { SMALL_MR_RESOURCE_TYPE_ID, LARGE_MR_RESOURCE_TYPE_ID, CB_RESOURCE_TYPE_ID } from '~/constants'

const PLUGINS = [
	interactionPlugin,
	momentPlugin,
	resourceTimeGridPlugin,
	scrollGridPlugin,
]

const BOOKING_EXCLUDED_CONTRACTS = ['42Firenze']
const SLOT_MIN_TIME = moment({ hour: 8 }).format(moment.HTML5_FMT.TIME_SECONDS)
const SLOT_MAX_TIME = moment({ hour: 19, minute:30 }).format(moment.HTML5_FMT.TIME_SECONDS)
const isFirefox = 'MozAppearance' in document.documentElement.style;

const useStyles = makeStyles((theme) => ({
	eventContentText: {
		textAlign: 'center',
	},
	loadingBackdrop: {
		zIndex: theme.zIndex.modal,
		color: theme.palette.common.white,
	},
	notMemberFallback: {
		textAlign: 'center'
	},
	meetingRoomAllocation:{
		position: "absolute",
		bottom: (isFirefox ? '-20px': 0),
		minWidth: "6em",
	},
	meetingRoomName:{
		fontSize: "0.88rem",
	},
	calendarContainer: {
		'& .fc-timegrid-now-indicator-arrow': {
			borderLeftColor: theme.palette.primary.main,
		},
		'& .fc-timegrid-now-indicator-line': {
			borderColor: theme.palette.primary.main,
		}
	}
}))

const ResourceLabelContent = (props: any) => {

	const { resource } = props
	const resourceId = _.toNumber(resource.id)
	const classes = useStyles()
	const [open, setOpen] =useState(false)
	const handleOpenDialog = () =>{
		setOpen(true)
	}
	const handleCloseDialog = () =>{
		setOpen(false)
	}

	const roomDialog = () => {

		if (resourceId)
			return (<>
					<Dialog open={open} onClose={() => handleCloseDialog()}>
						<DialogContent>
							<RoomDetail resourceId={resourceId}/>
						</DialogContent>
						<DialogActions>
						<Button onClick={() => handleCloseDialog()} color="secondary">
							Indietro
						</Button>
						</DialogActions>
					</Dialog>
					</>
			)
	}
 	return (
		<>
		{roomDialog()}

		<Flex alignItems='center' justifyContent='center' padding={0.5}>

			<Box paddingBottom={0.5} onClick={()=> handleOpenDialog()}>
			<RoomAvatar resourceId={resourceId}/>
			</Box>
			<Typography variant="subtitle2" className={classes.meetingRoomName}>{resource.extendedProps.name}</Typography>
			<Box className={classes.meetingRoomAllocation}>
				{resource.extendedProps.resource.Allocation}
				{resource.extendedProps.resource.Allocation > 1 ? " players" : " player"}
			</Box>
		</Flex>
		</>
	)
}

const getInitialDate = (): Date => moment().startOf('day').toDate()

const CalendarProps = {
	resourceId: PropTypes.number,
}

const Calendar: React.FC<CalendarOptions & InferProps<typeof CalendarProps>> = ({
	resourceId,
	...props
}) => {
	const calendarRef = useRef<FullCalendar>(null)
	const classes = useStyles()
	const theme = useTheme()

	const [loading, setLoading] = useState(true)
	const [loadingResources, setLoadingResources] = useState(true)
	const [selectedDate, setSelectedDate] = useState(getInitialDate)
	const [bookings, setBookings] = useState<NexudusCalendarBooking[]>([])
	const [CanMakeBookings, setCanMakeBookings] = useState<boolean | null>(null)
	const [selectedResource, setSelectedResource] = useState<{
		allowMultipleBookings: boolean;
		date: Moment;
		resourceId: number;
		resourceName: string;
	} | null>(null)
	const [error, setError] = useState<any>(null)
/*
	let coworkerInstance: NexudusCoworker | undefined
	const coworkerReady = new Promise((resolve, reject) => {
		ApiInstance.get('/profile', {
			cache: true,
			params: { _resource: 'Coworker' },
		}).then((response) => {
			coworkerInstance = response.data as NexudusCoworker
			setCanMakeBookings(coworkerInstance.IsMember)
			resolve(coworkerInstance)
		})
	})
*/
	const profileInfo = useSelector( (state: RootState) => { return state?.user?.user;  } );
	const canMakeBooking = profileInfo && (profileInfo.IsMember && BOOKING_EXCLUDED_CONTRACTS.indexOf(profileInfo.TariffNames) === -1)
	if (canMakeBooking !== CanMakeBookings){
		setCanMakeBookings(canMakeBooking)
	}
	const dispatch = useDispatch()

	useEffect(() => {
		const calendarApi = calendarRef.current?.getApi()
		calendarApi?.gotoDate(selectedDate)
	}, [calendarRef, selectedDate])

	const allEvents = useSelector((state: RootState) => { return state?.bookings?.bookingsEvents; });


	const getEvents = useCallback(
		async ({ startStr, endStr }) => {

			let responseValue = null;

			if (allEvents && allEvents.requestDate && allEvents.requestDate > new Date().getTime() - 900000 ){
				responseValue = allEvents.events;
			} else {
				const [response] = await Promise.all([

					// https://learn.nexudus.com/api/public-api/bookings#bookings-data
					ApiInstance.post('/bookings/fullCalendarBookings', null, {
						params: {
							start: startStr,
							end: endStr,
						},
					}).catch((err) => {
						setError(
							'Connessione con Nexudus non riuscita: Utente non trovato, probabilmente è avvenuto un errore durante la creazione'
						)
					}),
				])
				if (response === undefined) {
					return undefined
				}

				dispatch(setBookingEvents({
					requestDate: new Date().getTime(),
					requestStart:startStr,
					requestEnd:endStr,
					events:response.data
				}));

				responseValue = response.data;
			}



			const events = responseValue.map((value: any) => {

				let color = theme.palette.grey[300]
				if (profileInfo?.Id.toString() === value.coworkerId){
					color = theme.palette.primary.main
				}

				return new NexudusCalendarBooking(value.id, value.resourceId.toString(), color,
					readNexudusDateTime(value.start),  readNexudusDateTime(value.end),
					value.private, value.coworkerFullName)
			})

			setBookings(events)
			return events
		},
		[theme.palette.primary.main]
	)

	const getResources = useCallback(
		async ({ startStr, endStr }) => {
			setLoadingResources(true)
			// https://learn.nexudus.com/api/public-api/bookings#search
			const response = await ApiInstance.get('/bookings/search', {
				cache: false,
				params: {
					start: startStr,
					end: endStr,
					type: JSON.stringify([SMALL_MR_RESOURCE_TYPE_ID,LARGE_MR_RESOURCE_TYPE_ID,CB_RESOURCE_TYPE_ID])
				},
			})

			dispatch(setBookingResources(response.data))

			const resources = response.data.Resources.map((value: any) => ({
				id: value.Id,
				allowMultipleBookings: value.AllowMultipleBookings,
				name: value.Name,
				resource: value,
			}))

			if (resourceId)
				return resources.filter((value: any) => value.id === resourceId )

			setLoadingResources(false)
			return resources
		},
		[resourceId]
	)

	const handleDateChange = useCallback((date) => {
		if (date?.isValid()) setSelectedDate(date.toDate())
	}, [])

	const shouldDisableDate = useCallback((date) => date.weekday() >= 5, [])

	const handleDateClick = useCallback((info) => {
		FirebaseAnalytics({name:'start_booking_flow'})

		console.log(info.date)
		const selectedDateTime = moment(info.date)
		setSelectedResource({
			allowMultipleBookings: info.resource.extendedProps.allowMultipleBookings,
			date: selectedDateTime,
			resourceId: _.toNumber(info.resource.id),
			resourceName: info.resource.extendedProps.name,
		})
	}, [])

	const handleSelect = useCallback(({ resource, start: startDate }) => {
		const selectedDateTime = moment(startDate);
		setSelectedResource({
			allowMultipleBookings: resource.extendedProps.allowMultipleBookings,
			date: selectedDateTime,
			resourceId: _.toNumber(resource.id),
			resourceName: resource.extendedProps.name,
		})
	}, [])

	const handleTimeRangePickerClose = useCallback(() => {
		setSelectedResource(null)
	}, [])

	const eventContent = useCallback(({ event }) => {
		// I'm using the classic href because in this place we do not have the router context of the application
		if (event.extendedProps.private) {
			// return `${moment(event.start).format("HH:mm")} - ${moment(event.end).format("HH:mm")}`
		} else {
			return (
				<a href={`/bookings/${event.id}`}>
					{event.extendedProps.coworkerFullName}
				</a>
			)
		}
	}, [])

	const resourceLabelContent = useCallback(
		({ resource }) => (
			<AppProvider>
				<ResourceLabelContent resource={resource} />
			</AppProvider>
		),
		[]
	)

	const slotLabelFormat =  useMemo(() => {
		return {hour: '2-digit', minute: '2-digit',}
	}, [])

	const slotDuration =  useMemo(() => {
		return "00:15:00"
	}, [])

	const getElementOffset = (el: any) => {
		if (el != null) {
			const rect = el.getBoundingClientRect(),
				  scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
				  scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
		}
	};
	const offset = getElementOffset(window.document.getElementById("calendarContainer"))
	const contentHeight = window.innerHeight - (offset?.top || 0) - 1

	return (
		<>
			<Backdrop className={classes.loadingBackdrop} open={((loading || loadingResources) && CanMakeBookings == true) || CanMakeBookings == null}>
				<CircularProgress color='inherit' />
			</Backdrop>
			{ CanMakeBookings === false &&
				<Box padding={8}>
					<Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
						<Grid item xs={12}>
						<h5 className={classes.notMemberFallback}>Hey! Entra nella galassia di Nana Bianca e prenota le nostre incredibili meeting room.</h5>
						</Grid>
						<Grid item xs={12}>
							<a href="https://m.me/nanabiancaitaly?ref=Early%20Bird" target="BLANK" style={{textDecoration: 'none'}}>
								<Button variant={"contained"} color={"secondary"}>Contattaci ora!</Button>
							</a>
						</Grid>
					</Grid>
				</Box>
			}

			{ CanMakeBookings &&
				<Box padding={2} paddingBottom={0} className={classes.calendarContainer}>
					<CalendarDatePicker
						onChange={handleDateChange}
						shouldDisableDate={shouldDisableDate}
						value={selectedDate}
					/>

					{/* This is just a placeholder to find the position of the calendar */}
					<Box id={"calendarContainer"}/>
					<FullCalendar
						{...props}
						schedulerLicenseKey="0581392871-fcs-1602513978"
						allDaySlot={false}
						aspectRatio={0.5}
						dateClick={handleDateClick}
						dayCount={resourceId ? 5 : 1}
						dayMinWidth={72}
						displayEventTime={false}
						eventBackgroundColor={theme.palette.grey[300]}
						eventBorderColor='transparent'
						eventClassNames={classes.eventContentText}
						eventContent={eventContent}
						events={getEvents}
						expandRows
						headerToolbar={false}
						// nowIndicator={true}
						contentHeight={contentHeight}
						initialView={
							resourceId ? 'resourceTimeGrid' : 'resourceTimeGridDay'
						}
						loading={setLoading}
						locale={moment.locale()}
						locales={allLocales}
						plugins={PLUGINS}
						ref={calendarRef}
						nowIndicator={true}
						resourceLabelContent={resourceLabelContent}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						slotLabelFormat={slotLabelFormat}
						slotDuration={slotDuration}
						resourceOrder='name'
						resources={getResources}
						select={handleSelect}
						selectMirror
						selectOverlap={false}
						selectable
						showNonCurrentDates={false}
						slotMaxTime={SLOT_MAX_TIME}
						slotMinTime={SLOT_MIN_TIME}
						weekends={false}
					/>
					<ErrorSnackbar error={error} />
				</Box>
			}

			{selectedResource &&
				<CalendarTimeRangePicker
					onClose={handleTimeRangePickerClose}
					allowMultipleBookings={selectedResource.allowMultipleBookings}
					date={selectedResource.date}
					resourceId={selectedResource.resourceId}
					resourceName={selectedResource.resourceName}
					resourceBookings={bookings}
				/>
			}


		</>
	)
}

Calendar.propTypes = CalendarProps

export default Calendar
