import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as NbWifiGameSvg } from '~/assets/images/icons/devices/nb-wifi-game.svg'

function NbWifiGame(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbWifiGameSvg />
		</SvgIcon>
	)
}

export default NbWifiGame
