import { Style } from '@capacitor/status-bar'
import MomentUtils from '@date-io/moment'
import {
	CssBaseline,
	MuiThemeProvider,
	createTheme,
	responsiveFontSizes,
	useMediaQuery,
	PaletteType,
} from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import _ from 'lodash'
import React, { useMemo, useEffect } from 'react'

import useColorScheme, {
	LIGHT_COLOR_SCHEME,
	SYSTEM_COLOR_SCHEME,
	DARK_COLOR_SCHEME,
} from '~/hooks/use-color-scheme'
import theme from '~/theme'

const AppProvider: React.FC = (props) => {
	const [colorScheme] = useColorScheme()
	const prefersDarkColorScheme = useMediaQuery('(prefers-color-scheme: dark)')

	const systemColorScheme = prefersDarkColorScheme
		? DARK_COLOR_SCHEME
		: LIGHT_COLOR_SCHEME

	const themeColorScheme =
		colorScheme === SYSTEM_COLOR_SCHEME ? systemColorScheme : colorScheme

	useEffect(() => {
		
		/*let color = 'white'
		let style = Style.Light

		if (themeColorScheme === DARK_COLOR_SCHEME) {
			color = 'black'
			style = Style.Dark
		}

		if (Capacitor.isNative)
			Promise.all([
				Plugins.StatusBar.setBackgroundColor({ color }),
				Plugins.StatusBar.setStyle({ style }),
			])
		*/
	}, [themeColorScheme])

	const memoizedTheme = useMemo(
		() =>
			responsiveFontSizes(
				createTheme(
					_.merge({}, theme, {
						palette: {
							type: themeColorScheme as PaletteType,
						},
					})
				)
			),
		[themeColorScheme]
	)

	const { children } = props

	return (
		<MuiThemeProvider theme={memoizedTheme}>
			{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
			<CssBaseline />

			<MuiPickersUtilsProvider utils={MomentUtils}>
				{children}
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	)
}

export default AppProvider
