import {
	Avatar, Backdrop,
	Box,
	Button,
	Card,
	CardActions,
	CardHeader, CircularProgress,
	FormControl,
	Grid,
	Input,
	InputLabel
} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {AdminApiInstance} from "~/api";
import {useNavigate} from "react-router-dom";
import _debounce from 'lodash/debounce';
import Header from "~/components/header";


export interface TeamRecord {
	AllTeamMembers?: any;
	BusinessId: number;
	BusinessName: string;
	Name: string;
	Description: string;
	TunnelPrivateGroupId: string;
	TeamMembers?: any;
	AddedTeamMembers?: any;
	RemovedTeamMembers?: any;
	TeamAdministrators?: any;
	AddedTeamAdministrators?: any;
	RemovedTeamAdministrators?: any;
	CreateSingleInvoiceForTeam: boolean;
	UseSpecialPrices: boolean;
	PayingMemberId?: number;
	PayingMemberFullName: string;
	PayingMemberCoworkerType?: number;
	PayingMemberBillingName: string;
	PayingMemberCompanyName: string;
	DefaultMemberTariffId?: number;
	DefaultMemberTariffName: string;
	MaxTeamMemberCount?: any;
	TransferCreditsToPayingMember: boolean;
	ShareTimePasses: boolean;
	ShareExtraServices: boolean;
	ShareBookingCredit: boolean;
	DiscountExtraServices?: number;
	DiscountTimePasses?: any;
	DiscountCharges?: any;
	DiscountTariffs?: number;
	ProfileSummary?: any;
	ProfileTags?: any;
	ProfileWebsite: string;
	GoogleMapsLink?: any;
	ProfileIsPublic: boolean;
	HasCommunityGroup: boolean;
	Twitter?: any;
	Facebook?: any;
	Linkedin?: any;
	Skype?: any;
	CoworkerIds: string;
	CoworkerFullNames: string;
	CoworkerBillingNames: string;
	ActiveContracts: number;
	NewTeamLogoUrl?: any;
	TeamLogoFileName: string;
	ClearTeamLogoFile: boolean;
	NewTeamImage1Url?: any;
	TeamImage1FileName?: any;
	ClearTeamImage1File: boolean;
	NewTeamImage2Url?: any;
	TeamImage2FileName?: any;
	ClearTeamImage2File: boolean;
	NewTeamImage3Url?: any;
	TeamImage3FileName?: any;
	ClearTeamImage3File: boolean;
	Id: number;
	UpdatedOn: Date;
	CreatedOn: Date;
	UniqueId: string;
	UpdatedBy: string;
	IsNew: boolean;
	SystemId?: any;
	ToStringText: string;
	LocalizationDetails?: any;
	CustomFields?: any;
}


export const FioriniAdminPanel: React.FC<unknown> = ({children}) => {
	const navigate = useNavigate()
	const [loading, setLoading] = useState<boolean>(true)
	const [search, setSearch] = useState<string>('')
	const [teams, setTeams] = useState<TeamRecord[]>([])
	useEffect(() => {
		(async () => {
			setLoading(true)
			setTeams([])
			const params = {
				page: 1,
				size: 10,
				orderBy: 'Id',
				dir: 'Descending',
				Team_Name: search,
			}
			const response = await AdminApiInstance.get('/api/spaces/teams', {params: params})
			setTeams(response.data.Records)
			setLoading(false)
		})()
	}, [search])

	function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
		setSearch(event.target.value)
	}
	const _handleSearchChange = useCallback(_debounce(handleSearchChange, 500), []);

	return (
		<>
		<Header title="Coin configuration" className={"modalHeader"}/>
		<Box padding={2}>
			<Backdrop open={loading} style={{zIndex: 10}}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormControl>
						<InputLabel htmlFor="search-team-name">Team Name</InputLabel>
						<Input id="search-team-name" aria-describedby="Team Name"
							   onChange={_handleSearchChange} autoComplete={"off"}/>
					</FormControl>
				</Grid>
				{teams.map((team: TeamRecord) => {
					return <Grid item key={team.Id} md={4} xs={12}>
						<Card style={{ minHeight: 150 }}>
							<CardHeader avatar={<Avatar src={`https://nexudus.spaces.nexudus.com//en/team/getAvatar/${team.Id}?w=110&h=110&mode=Pad`}></Avatar>}
							 title={team.Name}
							 subheader={team.PayingMemberFullName}></CardHeader>
							<CardActions>
								<Button size="small" onClick={()=>{ navigate(`/profile/fiorini-management/${team.Id}`)} }>Edit</Button>
							</CardActions>
						</Card>
					</Grid>
				})}
			</Grid>

		</Box>
			</>
	)
}
