import {
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	FormGroup,
	Grid,
	TextField
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import firebase from "firebase/compat/app";
import Header from "~/components/header";


class TeamSettings {
	id: string;
	coinsDailyRecharge: number;
	coinsEnabled: boolean;

	constructor(id: string, coinsDailyRecharge?: number, coinsEnabled?: boolean) {
		this.id = id
		this.coinsDailyRecharge = coinsDailyRecharge || 0
		this.coinsEnabled = coinsEnabled || false
	}


	async save() {
		const record = await firebase.firestore().doc(`/teams-settings/${this.id}`).get()
		const data = {coinsDailyRecharge: this.coinsDailyRecharge, coinsEnabled: this.coinsEnabled}
		if (record.exists) {
			await record.ref.update(data)
		} else {
			await record.ref.set(data)
		}

	}
}

export const FioriniAdminEdit: React.FC<unknown> = ({children}) => {
	const navigate = useNavigate()
	const [loading, setLoading] = useState<boolean>(true);
	const {teamId} = useParams()
	const [formData, setFormData] = useState({coinsDailyRecharge: 0, coinsEnabled: false})
	const [team, setTeam] = useState<TeamSettings>(new TeamSettings(teamId || ''))

	useEffect(() => {
		(async () => {

			setLoading(true)
			const record = await firebase.firestore().doc(`/teams-settings/${teamId}`).get()
			const initialTeam = new TeamSettings(teamId || '', record.get('coinsDailyRecharge'), record.get('coinsEnabled'))
			setTeam(initialTeam)
			setFormData({coinsDailyRecharge: initialTeam.coinsDailyRecharge, coinsEnabled: initialTeam.coinsEnabled})
			setLoading(false)
		})()
	}, [teamId])

	async function handleSave() {
		setLoading(true)
		team.coinsDailyRecharge = formData.coinsDailyRecharge
		team.coinsEnabled = formData.coinsEnabled
		await team.save()
		setLoading(false)
	}

	return (
		<>
		<Header title={`Change team ${team.id}`} className={"modalHeader"}/>
		<Box padding={2}>
			<Backdrop open={loading} style={{zIndex: 10}}>
				<CircularProgress color='inherit'/>
			</Backdrop>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<FormGroup>
						<TextField id="coinsDailyRecharge" label="Coins" type="number"
								   value={formData.coinsDailyRecharge}
								   helperText={`equivalent to ${formData.coinsDailyRecharge / 100} eur`}
								   onChange={(e) => setFormData({
									   ...formData,
									   coinsDailyRecharge: parseInt(e.target.value, 10)
								   })}/>
					</FormGroup>
				</Grid>
				<Grid item xs={12}>
					<FormGroup>
						<FormControlLabel
							control={<Checkbox checked={formData.coinsEnabled}
											   onChange={(e, checked) => setFormData({
												   ...formData,
												   coinsEnabled: checked
											   })}/>} label="Enabled"/>
					</FormGroup>
				</Grid>
				<Grid item xs={12}>
					<Button variant="contained" onClick={handleSave}>Save</Button>
				</Grid>
			</Grid>

		</Box>
		</>
	)
}
