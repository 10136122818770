
export const isWebView = (): boolean => {
	// Verbose way instead of window.hasOwnProperty for fixing Eslint
	if (Object.prototype.hasOwnProperty.call(window, 'cordova')) {
		// TODO add another check like cordova.GetFirebaseAuth()
		return true
	}
	return false
}

export const isIos = (): boolean => {
	const userAgent = window.navigator.userAgent.toLowerCase();
	if (userAgent && /iphone|ipad|ipod|ios/.test(userAgent)){
		return true
	}
	return false
}