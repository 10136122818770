import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { MeetingIcon } from './meeting'
import { UserManualIcon } from './user-manual-icon'
import { ConventionsIcon } from './conventions-icon'
import { CowoIcon } from './cowo-icon'
import { EventIcon } from './event'
import { DailyPassIcon } from './dailypass'
import { PlusCircleOutline, CallMade } from 'mdi-material-ui'


export function BookMeetingRoomIcon(props: SvgIconProps) {
	return (
		<>
			<MeetingIcon {...props}/>
			{/* <SvgIcon className={"addBooking"}>
			</SvgIcon> */}
		</>
	)
}

export function BookMeetingEventIcon(props: SvgIconProps) {
	return (
		<>
			<EventIcon {...props}/>

			<SvgIcon className={"addBooking"}>
				<PlusCircleOutline></PlusCircleOutline>
			</SvgIcon>

		</>
	)
}

export function BookDailyPassIcon(props: SvgIconProps) {
	return (
		<>
			<DailyPassIcon {...props}/>

			{/* <SvgIcon className={"addBooking"}>
			</SvgIcon> */}

		</>
	)
}

export function UserManualShortcutIcon(props: SvgIconProps) {
	return (
		<>
			<UserManualIcon {...props}/>

			{/* <SvgIcon className={"addBooking"}>
			</SvgIcon> */}

		</>
	)
}
export function ConventionsShortcutIcon(props: SvgIconProps) {
	return (
		<>
			<ConventionsIcon {...props}/>


		</>
	)
}
export function CowoShortcutIcon(props: SvgIconProps) {
	return (
		<>
			<CowoIcon {...props}/>


		</>
	)
}