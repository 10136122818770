import { current } from '@reduxjs/toolkit'
import axios from 'axios'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import { initializeApp } from 'firebase/app';



import React, { useState, useCallback } from 'react'
import { useAsync } from 'react-async'
import { Route, Routes } from 'react-router-dom'

import { DEBUG } from '~/constants'

import Dashboard from './dashboard'
import Login from './login'
import PhoneLogin from './phone-login'
import TokenLogin from './token-login'

const getInitialLoading = (): boolean => {
	try {
		firebase.app()
	} catch {
		return true
	}
	return false
}

const Root: React.FC = () => {
	const [loading, setLoading] = useState(getInitialLoading)
	
	const initializeSpaceApp = useCallback(async (): Promise<void> => {
		if (!loading) return

		let config = {
			apiKey: 'AIzaSyCbX_V0yNjvBDWCoFF4ljb67V_dFJz0I9E',
			appId: '1:554114900152:web:7fff11832a2ed51c8b944d',
			authDomain: 'space-app-8cf36.firebaseapp.com',
			databaseURL: 'https://space-app-8cf36.firebaseio.com',
			measurementId: 'G-9V0LB8K130',
			messagingSenderId: '554114900152',
			projectId: 'space-app-8cf36',
			storageBucket: 'space-app-8cf36.appspot.com',
		}

		if (!DEBUG) {
			try {
				const response = await
				axios.get('/__/firebase/init.json')
				config = { ...config, ...response.data }
			} catch {
				// Using fallback config
			}
		}


		
		initializeApp(config) // v9 configuration
		firebase.initializeApp(config)
		firebase.auth().useDeviceLanguage()
		firebase.firestore().settings({
			cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
		})
		
		await new Promise<void>((resolve, reject) => {
			const unsubscribe = firebase.auth().onAuthStateChanged(() => {
				unsubscribe()
				resolve()
			}, reject)
		})
		
		setLoading(false)
	}, [loading])

	useAsync(initializeSpaceApp)
	
	

	if (loading) return null

	return (
		<Routes>
			<Route element={<Dashboard />} path='/*' />
			<Route element={<Login />} path='/login' />
			<Route element={<PhoneLogin />} path='/phone' />
			<Route element={<TokenLogin />} path='/token' />
		</Routes>
	)
}

export default Root
