import {Moment} from "moment"

export const SET_BOOKINGS_RESOURCES = 'SET_BOOKINGS_RESOURCES'
export const SET_BOOKINGS_EVENTS = 'SET_BOOKINGS_EVENTS'


export interface BookingsState {
	bookingsResources: BookingsResources | null;
	bookingsEvents: BookingsEvents | null;
}

export interface BookingsResources {
	AllShifts: [];
	ChargeBooingImmediately: boolean;
	EndDate: Moment;
	Group: any;
	ResourceTypes: [];
	Resources: [];
	SearchFields: [];
	SelectedTypes: any;
	StartDate: Moment;
}

export interface BookingsEvents {
	requestDate: number;
	requestStart: string;
	requestEnd: string;
	events: [];
}

interface SetBookingsResourcesAction {
	type: typeof SET_BOOKINGS_RESOURCES;
	payload: BookingsResources;
}

interface SetBookingsEvents {
	type: typeof SET_BOOKINGS_EVENTS;
	payload: BookingsEvents;
}

export type BookingsActionTypes = SetBookingsResourcesAction | SetBookingsEvents;
