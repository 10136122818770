import React, { useState } from 'react'
import {
	Box,
	Button,
	Grid,
	makeStyles,
	Snackbar,
	Backdrop,
	CircularProgress,
	IconButton,
} from '@material-ui/core'
import { Close, Wifi } from 'mdi-material-ui'
import ApiInstance from '~/api'
import SuccessDialog from '~/components/success-dialog'
import Header from '~/components/header'

const useStyles = makeStyles((theme) => ({
	password: {
		width: '100%',
		justifyContent: 'center',
	},
	divider: {
		marginBottom: '26px',

		backgroundColor: '#BCBBC1'
	},
	box: {
		borderStyle: 'solid',
		borderColor: '#FFFFFF',
		borderRadius: '10px',
		fontFamily: 'Monospace',
		fontWeight: 700,
		fontSize: '28px',
		letterSpacing: '0',
		lineHeight: '0px',
	},
	btnEnd: {
		position: 'fixed',
		bottom: '10px',
		width: '95%',
	},
	fullwidth: {
		width: '100%',
	},
	logo: {
		display: 'grid',
		marginBottom: '10px',
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1
	},
}))

const NetworkGuestTab: React.FC = () => {
	const [token, setToken] = useState('')
	const [showSuccess, setShowSuccess] = useState(false)
	const [copySnackBar, setCopySnackBar] = React.useState(false)
	const [loading, setLoading] = React.useState(false)
	const [snackMessage, setSnackMessage] = React.useState('')

	const classes = useStyles()

	const getToken = () => {
		setLoading(true)
		ApiInstance.post('/network/guest').then((response) => {
			if (response.data !== undefined) {
				setToken(response.data.token)
				setLoading(false)
				setShowSuccess(true)
			}
		}).catch(error =>{
			console.log('Error: ', error )
		})
	}

	const copyToClipboard = () => {
		if (token !== '') {
			if (navigator !== undefined && navigator.clipboard !== undefined) {
				navigator.clipboard.writeText(token)
				setSnackMessage('Copiato!')
				setCopySnackBar(true)
			} else {
				// navigator.clipboard is undefined on mobile without https
				setSnackMessage(
					'Error: Unable to save to clipboard on insecure protocol'
				)
				setCopySnackBar(true)
			}
		}
	}
	const handleCloseSnack = () => {
		setCopySnackBar(false)
	}
	const closeSuccess = () => {
		setShowSuccess(false)
	}
	return (
		<Grid container justifyContent={"center"}>
			<Grid item xs={12} md={6}>
				<Header title='Guest Access' className={"modalHeader"}/>
				<Backdrop open={loading} className={classes.backdrop}>
					<CircularProgress color='inherit' />
				</Backdrop>

				<Box padding={1}>
					<Grid container spacing={3}>
						<Grid item xs={12} className={classes.logo} justifyContent='center'>
							<Wifi color='primary' style={{ fontSize: 100 }} />
						</Grid>
						<Grid item xs={12}>
							Fai connettere i tuoi ospiti a NanaBianca-Guest e offri loro un
							token per poter usufruire della potente connessione di Nana Bianca
						</Grid>
						<Grid item xs={12}>
							<Button onClick={() => getToken()} color='secondary' className={classes.fullwidth} variant="contained">Genera un token</Button>
						</Grid>
					</Grid>

					<SuccessDialog
						onClose={closeSuccess}
						open={showSuccess}
						className='passwordDialog'
					>
						<Box className="passwordTitle">

							Ecco il tuo
						<Box color='primary.text' className='passwordTitleBlack' >token</Box>
						</Box>

						<Grid
							container
							justifyContent='center'
							className={classes.password}
						>
							<Grid item xs={12} justifyContent='center'>

								<Box className={classes.box}>
									<h1>{token}</h1>
								</Box>
							</Grid>
							<Grid item xs={12} className={classes.btnEnd} >
								<Button
									onClick={copyToClipboard}
									className={classes.fullwidth}
									variant='contained'
									color='secondary'
								>COPIA</Button>
							</Grid>


						</Grid>

					</SuccessDialog>
				</Box>
				<Snackbar
					open={copySnackBar}
					autoHideDuration={3000}
					message={snackMessage}
					onClose={handleCloseSnack ?? undefined}
					action={
						!!handleCloseSnack && (
							<IconButton
								aria-label='close'
								color='inherit'
								onClick={handleCloseSnack}
								size='small'
							>
								<Close fontSize='small' />
							</IconButton>
						)
					}
				/>
			</Grid>
		</Grid>
	)
}



export default NetworkGuestTab
