import {
	useMediaQuery,
	useTheme,
	InputAdornment,
	IconButton,
	makeStyles,
} from '@material-ui/core'
import { DatePicker, DatePickerProps } from '@material-ui/pickers'
import { CalendarMonth, ChevronDown } from 'mdi-material-ui'
import React, { useMemo } from 'react'

const DATE_INPUT_PROPS = {
	disableUnderline: true,
}

const DATE_DIALOG_VARIANT_PROPS = {
	cancelLabel: 'Annulla',
	clearable: false,
	showTodayButton: true,
	todayLabel: 'Oggi',
}

const useStyles = makeStyles(() => ({
	input: {
		fontSize: '1rem',
		fontWeight: 400,
	},
}))

const CalendarDatePicker: React.FC<DatePickerProps> = (props) => {
	const classes = useStyles()
	const theme = useTheme()
	const shouldUseDialog = useMediaQuery(theme.breakpoints.down('sm'))

	const getDatePickerVariant = () =>{
		if (shouldUseDialog && props.variant !== 'static'){
			return 'dialog'
		}else if(props.variant === 'static'){
			return 'static'
		}else if(props.variant === 'inline'){
			return 'inline'
		}else{
			return 'inline'
		}
	}

	const getAutoOk = () => {
		if (props.autoOk){
			return props.autoOk
		}else{
			return !shouldUseDialog
		}
	}

	const InputProps = useMemo(
		() => (
			{
			...DATE_INPUT_PROPS,

			className: classes.input,
			endAdornment: (
				<InputAdornment position='start'>
					<IconButton
					// disabled={disabled}
					// {...KeyboardButtonProps}
					// onClick={onOpen}
					>
						{shouldUseDialog ? <CalendarMonth /> : <ChevronDown />}
					</IconButton>
				</InputAdornment>
			),
		}),
		[classes.input, shouldUseDialog]
	)

	const getDialogProps = () => {
		if (!props.autoOk){
			return DATE_DIALOG_VARIANT_PROPS
		}else{
			return {}
		}
	}

	return (
		<DatePicker
			{...props}
			{...(shouldUseDialog && getDialogProps())}
			InputProps={InputProps}
			autoOk={getAutoOk()}
			disablePast
			format='LL'
			fullWidth={shouldUseDialog}
			// helperText='Seleziona una data'
			inputVariant='standard'
			invalidDateMessage='Data non valida'
			// label='Data'
			margin='normal'
			openTo='date'
			variant={getDatePickerVariant()}
		/>
	)
}

export default CalendarDatePicker
