export const SET_USER = 'SET_USER';
export const UNSET_USER = 'UNSET_USER';

export interface NexudusCoworkerProfile {
	FullName: string;
	CoworkerType: string;
	GuessedFirstNameForInvoice: string;
	GuessedLastNameForInvoice: string;
	GuessedFirstName: string;
	GuessedLastName: string;
	Salutation: string;
	Address: null|string;
	PostCode: null|string;
	CityName: null|string;
	State: null|string;
	Email: string;
	Active: boolean;
	DiscountCode: null|string;
	RefererGuid: string;
	ReferenceNumber: null|string;
	HasZoom: boolean;
	HasOffice365: boolean;
	HasGoogle: boolean;
	IsNew: boolean;
	CheckedIn: boolean;
	SignUpToNewsletter: boolean;
	DeleteAvatar: boolean;
	DeleteBanner: boolean;
	AllowNetworkCheckin: boolean;
	CanMakeBookings: boolean;
	CanPurchaseProducts: boolean;
	CanPurchaseEvents: boolean;
	CanAccessCommunity: boolean;
	AccessCardId: null|string;
	AvatarUrl: string;
	ProfileUrl: string;
	CountryId: number;
	BillingCountryId: number;
	InvoicingSpaceId: number;
	InvoicingSpaceGuid: string;
	InvoicingSpaceName: string;
	HomeSpaceId: number;
	InvoicingBusinessId: number;
	HomeSpaceGuid: string;
	HomeSpaceName: string;
	SimpleTimeZoneId: number;
	IsMember: boolean;
	CancellationDate: null|string;
	UtcCancellationDate: null|string;
	AbsoluteCancellationDate: null|string;
	TariffNames: string;
	TariffIds: string;
	TeamNames: string;
	PaysForTeamUniqueId: string;
	IsPayingMember: boolean;
	IsPaidForCustomer: boolean;
	IsTeamAdministrator: boolean;
	DoNotProcessInvoicesAutomatically: boolean;
	MobilePhone: null|string;
	LandLine: null|string;
	NickName: null|string;
	BusinessArea: null|string;
	Position: null|string;
	CompanyName: string;
	ProfileTags: string;
	ProfileTagsSpaces: string;
	ProfileTagsList: string[];
	ProfileSummary: null|string;
	ProfileSummaryHtml: string;
	ProfileWebsite: null|string;
	Teams: NexudusTeam[];
	TeamMembers: any[];
	Url: string;
	Gender: string;
	ProfileIsPublic: boolean;
	RegistrationDate: string;
	RegistrationDateUtc: string;
	UtcRegistrationDate: string;
	DateOfBirth: null|string;
	DateOfBirthUtc: null|string;
	UtcDateOfBirth: null|string;
	Twitter: null|string;
	Skype: null|string;
	Facebook: null|string;
	Linkedin: null|string;
	Google: null|string;
	Telegram: null|string;
	Github: null|string;
	Pinterest: null|string;
	Flickr: null|string;
	Instagram: null|string;
	Vimeo: null|string;
	Tumblr: null|string;
	Blogger: null|string;
	HasContactDetails: boolean;
	BillingName: string;
	BillingEmail: string;
	BillingAddress: null|string;
	BillingPostCode: null|string;
	BillingCityName: string;
	BillingState: null|string;
	TaxIDNumber: null|string;
	CardNumber: null|string;
	AccessPincode: string;
	Custom1: string;
	Custom2: null|string;
	Custom3: null|string;
	Custom4: null|string;
	Custom5: null|string;
	Custom6: null|string;
	Custom7: null|string;
	Custom8: null|string;
	Custom9: null|string;
	Custom10: null|string;
	Custom11: null|string;
	Custom12: null|string;
	Custom13: null|string;
	Custom14: null|string;
	Custom15: null|string;
	Custom16: null|string;
	Custom17: null|string;
	Custom18: null|string;
	Custom19: null|string;
	Custom20: null|string;
	Custom21: null|string;
	Custom22: null|string;
	Custom23: null|string;
	Custom24: null|string;
	Custom25: null|string;
	Custom26: null|string;
	Custom27: null|string;
	Custom28: null|string;
	Custom29: null|string;
	Custom30: null|string;
	EnableGoCardlessPayments: boolean;
	StripeDirectDebitPaymentsEnabled: boolean;
	GoCardlessContractNumber: null|string;
	HasGoCardlessContractNumber: boolean;
	HasStripeDirectDebitMandate: boolean;
	HasStripeACHCustomerToken: boolean;
	HasStripeACHBankToken: boolean;
	HasSetupRegularPayments: boolean;
	RegularPaymentProvider: string;
	RegularPaymentContractNumber: null|string;
	HasRegularPaymentContractNumber: boolean;
	HasAcceptedStripeACHAgreement: boolean;
	HasVerifiedStripeACHDeposits: boolean;
	StripePublicKeKey: string;
	EmailForInvoice: string;
	AddressForInvoice: string;
	PostCodeForInvoice: string;
	CityForInvoice: string;
	StateForInvoice: string;
	FullNameForInvoice: string;
	GeneralTermsAccepted: boolean;
	GeneralTermsAcceptedOnline: boolean;
	AgeInDays: number;
	HasBanner: boolean;
	Id: number;
	IdString: string;
	UpdatedOn: string;
	CreatedOn: string;
	UpdatedOnUtc: string;
	CreatedOnUtc: string;
	UniqueId: string;
	IsNull: boolean;
}

export interface NexudusTeam {
	AllTeamMembers: null|string;
	BusinessId: number;
	BusinessName: string;
	Name: string;
	Description: null|string;
	TunnelPrivateGroupId: null|string;
	TeamMembers: null|string;
	AddedTeamMembers: null|string;
	RemovedTeamMembers: null|string;
	TeamAdministrators: null|string;
	AddedTeamAdministrators: null|string;
	RemovedTeamAdministrators: null|string;
	CreateSingleInvoiceForTeam: false;
	UseSpecialPrices: false;
	PayingMemberId: number;
	PayingMemberFullName: string;
	PayingMemberCoworkerType: 1;
	PayingMemberBillingName: string;
	PayingMemberCompanyName: null|string;
	DefaultMemberTariffId: number;
	DefaultMemberTariffName: string;
	MaxTeamMemberCount: null|string;
	TransferCreditsToPayingMember: boolean;
	ShareTimePasses: boolean;
	ShareExtraServices: boolean;
	ShareBookingCredit: boolean;
	DiscountExtraServices: null|string;
	DiscountTimePasses: null|string;
	DiscountCharges: null|string;
	DiscountTariffs: null|string;
	ProfileSummary: null|string;
	ProfileTags: null|string;
	ProfileWebsite: null|string;
	GoogleMapsLink: null|string;
	ProfileIsPublic: boolean;
	HasCommunityGroup: boolean;
	Twitter: null|string;
	Facebook: null|string;
	Linkedin: null|string;
	Skype: null|string;
	CoworkerIds: string;
	CoworkerFullNames: string;
	CoworkerBillingNames: string;
	ActiveContracts: null|number;
	NewTeamLogoUrl: null|string;
	TeamLogoFileName: null|string;
	ClearTeamLogoFile: boolean;
	NewTeamImage1Url: null|string;
	TeamImage1FileName: null|string;
	ClearTeamImage1File: boolean;
	NewTeamImage2Url: null|string;
	TeamImage2FileName: null|string;
	ClearTeamImage2File: boolean;
	NewTeamImage3Url: null|string;
	TeamImage3FileName: null|string;
	ClearTeamImage3File: boolean;
	Id: number;
	UpdatedOn: string;
	CreatedOn: string;
	UniqueId: string;
	UpdatedBy: string;
	IsNew: boolean;
	SystemId: null|string;
	ToStringText: string;
	LocalizationDetails: null|string;
	CustomFields: null|string;
}

export interface UserState {
	user: NexudusCoworkerProfile | null;
	loading: boolean;
}

interface SetUserAction {
	type: typeof SET_USER;
	payload: NexudusCoworkerProfile;
}

interface UnsetUserAction {
	type: typeof UNSET_USER;
	payload: null;
}

export type UserActionTypes = SetUserAction | UnsetUserAction;
