import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	LinearProgress,
	makeStyles,
	Box,
} from '@material-ui/core'
import {
	Information,
} from 'mdi-material-ui'
import PropTypes, { InferProps } from 'prop-types'
import React, { Suspense, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ApiInstance from '~/api'
import ErrorBoundary from '~/components/error-boundary'
import ErrorSnackbar from '~/components/error-snackbar'
import ExtendedFab from '~/components/extended-fab'
import Header from '~/components/header'
import useApi from '~/hooks/use-api'

import BookingsListItem from '../list-item'
import { readNexudusDateTime } from "~/components/calendar-time-range-picker";
import RoomDetail from '~/components/room-detail'
import { NexodusRoom } from '~/models'

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1
	},
	buttonCircularProgress: {
		position: 'absolute',
	},
	list: {
		paddingBottom: theme.spacing(16),
	},
	btnEnd: {
		position: 'absolute',
		bottom: '50px',
		width: '92%',
		marginBottom: '0px',
	},
	box: {
		maxWidth: '700px'
	},
}))

const BookingsDetailListProps = {
	bookingId: PropTypes.string.isRequired,
}

const BookingsDetailList: React.FC<InferProps<
	typeof BookingsDetailListProps
>> = ({ bookingId }) => {
	const classes = useStyles()
	const { data } = useApi<{ Resource: NexodusRoom; Value: any }>(
		`/bookings/bookingjson/${bookingId}`
	)


	const boockingListItem = () => {
		const type = String(data.Value.ResourceResourceTypeId)
		return (
			<>

				<BookingsListItem
					dateFormat='long'
					endDate={readNexudusDateTime(data.Value.ToTime)}
					resourceId={data.Value.ResourceId}
					resourceName={data.Value.ResourceName}
					resourceType={type}
					startDate={readNexudusDateTime(data.Value.FromTime)}
				/>
			</>
		)
	}

	return (
		<>
			<RoomDetail resourceId={data.Resource.Id} nexodusRoom={data.Resource} customListElement={boockingListItem()} showRoomHeader={false} />
		</>
	)
}

BookingsDetailList.propTypes = BookingsDetailListProps

const BookingsDetail: React.FC = () => {
	const navigate = useNavigate()
	const { bookingId } = useParams()
	const classes = useStyles()

	const [dialogLoading, setDialogLoading] = useState(false)
	const [dialogOpen, setDialogOpen] = useState(false)

	const [error, setError] = useState<any>(null)

	const handleButtonClick = useCallback(() => {
		setDialogOpen(true)
	}, [])

	const handleDialogClose = useCallback(() => {
		setDialogOpen(false)
	}, [])

	const handleDialogButtonClick = useCallback(async () => {
		setDialogLoading(true)

		try {
			// https://learn.nexudus.com/api/public-api/bookings#delete-one
			await ApiInstance.post('/bookings/deleteJson', {
				id: bookingId,
			})
		} catch (error) {
			setError((error as Error).message)
			return
		} finally {
			setDialogOpen(false)
			setDialogLoading(false)
		}

		navigate(-1)
	}, [bookingId, navigate])

	const handleErrorSnackbarClose = useCallback(() => {
		setError(null)
	}, [])

	return (
		<>
			<Header
				title='Riepilogo Prenotazione'
				className={"modalHeader"}
			/>

			<ErrorBoundary>
				<Suspense fallback={<LinearProgress />}>
					<Box padding={2} style={{position: 'absolute', height:'100%'}}>
						<Box className={classes.box}>
							{bookingId && (
								<BookingsDetailList bookingId={bookingId} />
							)}
						</Box>
						<Button
							onClick={handleButtonClick}
							className={classes.btnEnd}
							variant='contained'
							color='secondary'
						>Cancella</Button>
					</Box>
				</Suspense>
			</ErrorBoundary>


			<Dialog
				disableEscapeKeyDown={dialogLoading}
				onClose={handleDialogClose}
				open={dialogOpen}
			>
				<DialogTitle>
					{'Sei sicuro di voler cancellare la prenotazione?'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{"L'operazione non può essere annullata"}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={dialogLoading}
						onClick={handleDialogClose}
					>
						Annulla
					</Button>
					<Button
						color='primary'
						disabled={dialogLoading}
						onClick={handleDialogButtonClick}
					>
						Sì, cancella
						{dialogLoading && (
							<CircularProgress
								className={classes.buttonCircularProgress}
								size={24}
							/>
						)}
					</Button>
				</DialogActions>
			</Dialog>

			<ErrorSnackbar error={error} onClose={handleErrorSnackbarClose} />
		</>
	)
}

export default BookingsDetail
