import React, {useEffect, useState} from 'react'

import Calendar from '~/components/calendar'
import Header from '~/components/header'
import {makeStyles} from "@material-ui/core";
import {GetBookingCredit, CreditKind} from '~/services/booking-credits-service'
// import useQueryParams from '~/hooks/use-query-params'

// enum NameResourceTypeEnum {
// 	large = '243598355',
// 	small = '243598356',
// }


interface BookingCredit {
	CreditName: string;
	TotalCredit: number;
	RemainingCredit: number;
	TotalHours: number;
	HoursLeft: number;
}

const useStyles = makeStyles((theme) => ({
	remainingCredits: {
		background: '#B9BDDB',
		borderRadius: '18px',
		padding: '2px 6px',
		display: 'inline-block',
		minWidth: '50px',
		textAlign: 'center',
		fontSize: '1rem'
	}
}))

const MeetingCalendar: React.FC = () => {

	const [credits, setCredits] = useState('--')

	useEffect(()=>{
		getInfo();


	}, [])

	const getInfo = async() => {
		setCredits(await GetBookingCredit(CreditKind.BOOKINGROOM))
	}

	const classes = useStyles();
	const remainingCredits = <span className={classes.remainingCredits}>{credits}</span>

	// const queryParams = useQueryParams()

	// const initialSelectedResourceTypes = queryParams
	// 	.getAll('types')
	// 	.map(
	// 		(value) =>
	// 			NameResourceTypeEnum[value as keyof typeof NameResourceTypeEnum]
	// 	)
	// 	.filter(Boolean)
	// const [selectedResourceTypes, setSelectedResourceTypes] = useState<
	// 	string[]
	// >(initialSelectedResourceTypes)

	// useEffect(() => {
	// 	if (!calendarRef.current) return

	// 	const calendarApi = calendarRef.current!.getApi()

	// 	initialResources.forEach((initialResource: ResourceType) => {
	// 		if (selectedResourceTypes.length) {
	// 			const resource = calendarApi.getResourceById(
	// 				initialResource.id.toString()
	// 			)

	// 			if (
	// 				selectedResourceTypes.includes(
	// 					initialResource.typeId.toString()
	// 				)
	// 			) {
	// 				if (!resource)
	// 					calendarApi.addResource(initialResource as any)
	// 			} else {
	// 				if (resource) resource.remove()
	// 			}
	// 		} else {
	// 			calendarApi.addResource(initialResource as any)
	// 		}
	// 	})
	// }, [calendarRef, initialResources, selectedResourceTypes])

	return (
		<>
			<Header title='Prenota stanza' className={"modalHeader"} endButton={remainingCredits} />
			<Calendar />
		</>
	)
}

export default MeetingCalendar
