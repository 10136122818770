import { Avatar, Box, SvgIconProps } from '@material-ui/core'
import PropTypes, { InferProps } from 'prop-types'
import React from 'react'

import DeviceIcon from '~/components/device-icon'

const DeviceAvatarProps = {
	resourceId: PropTypes.string.isRequired,
}

const DeviceAvatar: React.FC<InferProps<SvgIconProps & typeof DeviceAvatarProps>> = ({
	resourceId,
	...rest
}) => {
	return (
		<DeviceIcon {...rest} resourceId={resourceId} />
	)
}

DeviceAvatar.propTypes = DeviceAvatarProps

export default DeviceAvatar
