import { LinearProgress } from '@material-ui/core'
import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import ErrorBoundary from '~/components/error-boundary'
import BookingsFab from '~/components/bookings-fab'

import BookingsDetail from './detail'
import BookingsEvent from './event'
import BookingsGym from './gym'
import BookingsList from './list'
import BookingsMeeting from './meeting'
import DailyPassBooking from  './daily-pass'

const BookingsTab: React.FC = () => {
	return(
		<>
			<ErrorBoundary>
				<Suspense fallback={<LinearProgress />}>
					<BookingsList />
				</Suspense>
			</ErrorBoundary>

			<BookingsFab />
		</>
	)
	
	}

const BookingsSwitch: React.FC = () => (
	<Routes>
		<Route element={<BookingsTab />} path='/' />
		<Route element={<BookingsMeeting />} path='meeting' />
		<Route element={<BookingsGym />} path='gym' />
		<Route element={<DailyPassBooking />} path='dailypass' />
		<Route element={<BookingsEvent />} path='event' />
		<Route element={<BookingsDetail />} path=':bookingId' />
	</Routes>
)

export default BookingsSwitch
