import { Fab, makeStyles, Box,  } from '@material-ui/core'
import React, { useEffect, useCallback } from 'react'
import { Link, Navigate } from 'react-router-dom'

import {useSelector} from "react-redux";
import {RootState} from "~/store";
import SpeedDial, { SpeedDialProps } from '@material-ui/lab/SpeedDial'
import {useNavigate} from 'react-router-dom'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import { EventIcon } from "~/components/icons/event";
import { MeetingIcon } from "~/components/icons/meeting";
import { DailyPassIcon } from "~/components/icons/dailypass";
import { stubFalse } from 'lodash'

const useStyles = makeStyles((theme) => ({
	icon: {
        marginBottom: '0.5rem',
        '.MuiSpeedDialIcon-root':{
            marginBottom: '0.5rem',
        }
    },
    speedDial: {
        position: 'fixed',
        '&.MuiSpeedDial-directionUp': {
          bottom: theme.spacing(12),
          right: theme.spacing(1),
        },
    },
	speedDialIcons: {
		//width: '56px',
		//height: '56px',
		'& .MuiSvgIcon-root': {
			width: '56px',
			height: '56px',
		}
	}
}))


const BookingsFab: React.FC = () =>  {
  const baseActions = [
    { icon: <MeetingIcon />, name: 'Meeting room', url: '/bookings/meeting' },
    /* { icon: <EventIcon />, name: 'Event room', url: '/bookings/event'}, */
  ]
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const [actions , setActions] = React.useState(baseActions)
  const navigate = useNavigate()
  
  
  const profileInfo = useSelector( (state: RootState) => { return state?.user?.user;  } );
    const canBookFlexi = useCallback (() => { 
      return (profileInfo && profileInfo.TariffNames !== null &&profileInfo.TariffNames.search("Flexi") > -1)
    },[profileInfo])

    const handleClose = () => {
        setOpen(false);
      }

      const handleOpen = () => {
        setOpen(true);
      }

      const navigateTo = (url: string) => {
        navigate(url)
      }

      useEffect(() => {
        if (canBookFlexi()){
          baseActions.push({ icon: <DailyPassIcon />, name: 'Flexi desk', url: '/bookings/dailypass'},)
          setActions(baseActions)
        }      
      }, [profileInfo])
    return (
        <>
        <SpeedDial
                    ariaLabel="Bookings"
                    icon={< SpeedDialIcon className={classes.icon}/>}
                    className={classes.speedDial}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                    direction='up'
                    >
                    {actions.map((action) => (
                        <SpeedDialAction
						className={classes.speedDialIcons}
                        onClick={() => navigateTo(action.url)}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        />
                    ))}
                    </SpeedDial>
        </>
    )
}


export default BookingsFab
