import Parser from 'rss-parser'
import useSWR from 'swr'

const FEED_BASE_URL = 'https://nanabianca.it/feed'
const FEED_ITEM_MEDIA_KEY = 'media:group'

export interface FeedItem extends Parser.Item {
	content: string;
	isoDate: string;
	link: string;
	title: string;
	[FEED_ITEM_MEDIA_KEY]: {
		'media:content': { $: { url: string } }[];
	};
}

const parser = new Parser({
	customFields: {
		item: [FEED_ITEM_MEDIA_KEY],
	},
})

const feedApiFetcher = async (): Promise<FeedItem[] | undefined> => {
	const response = await parser.parseURL(FEED_BASE_URL)

	return response.items as FeedItem[]
}

const useFeedApi = (): FeedItem[] => {
	const { data } = useSWR(FEED_BASE_URL, feedApiFetcher, {
		revalidateOnFocus: false,
		suspense: true,
	})

	return data ?? []
}

export default useFeedApi
