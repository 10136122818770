import React from 'react'
import useApi from '~/hooks/use-api'
import Flex from '~/components/flex'
import { Avatar, Typography, makeStyles } from '@material-ui/core'
import {NexudusCoworker} from "~/models";
import {useSelector} from "react-redux";
import {RootState} from "~/store";
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {NexudusCoworkerProfile} from "~/store/user/types";

const useStyles = makeStyles((theme) => ({
	avatar: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
		width: '2em',
		height: '2em',
	},
}))

class ProfileAvatar extends React.Component<{ classes: ClassNameMap<"avatar">; profileInfo: NexudusCoworkerProfile | null | undefined }> {
	render() {
		if(this.props.profileInfo !== null && this.props.profileInfo !== undefined ) {
			return <Avatar
				className={this.props.classes.avatar}
				src={`//nanabianca.spaces.nexudus.com${this.props.profileInfo.AvatarUrl}`}
			/>;
		} else {
			return <></>
		}

	}
}

const UserAvatar: React.FC = () => {
	const classes = useStyles()
	const profileInfo = useSelector( (state: RootState) => { return state?.user?.user;  } );
	return (
		<>
			<Flex alignItems='center' alignSelf='center' flex={0}>
				<ProfileAvatar classes={classes} profileInfo={profileInfo}/>
				<Typography paragraph variant='h6'>
					{profileInfo?.FullName}
				</Typography>
				{(profileInfo?.CompanyName || profileInfo?.TeamNames) &&
					<Typography paragraph
								variant={"subtitle1"}>{profileInfo?.CompanyName || profileInfo?.TeamNames}</Typography>
				}

			</Flex>
		</>
	)
}

export default UserAvatar
