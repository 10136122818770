import {
    Backdrop,
    Button,
    CircularProgress,
    makeStyles,
    Grid,
    Box,
    Typography,
    TextField,

} from '@material-ui/core'
import FirebaseAnalytics from '~/components/firebase-analytics'
import React, { useCallback, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import backgroundImage from '~/assets/images/piazza-cestello.png'

import { ReactComponent as NbLogo } from '~/assets/images/nb-logo.svg'
import ErrorSnackbar from '~/components/error-snackbar'
import Flex from '~/components/flex'

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: '#0A24EB',
        backgroundPosition: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        minWidth: '100%',
        minHeight: '100%',
        position: 'absolute',
    },
    button: {
        marginBottom: '50px',
        width: '100%',
    },
    buttonGrid: {
        display: 'grid',
        justifyContent: 'center',
    },
    buttonTest: {
        marginTop: theme.spacing(3),
    },
    loadingBackdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.modal,
    },
    logo: {
        fill: theme.palette.common.white,
        maxWidth: '175px',
        marginTop: '76px',
    },
    hidden: {
        display: 'none',
    },
    title: {
        display: "grid",
        lineHeight: 0.90,
        justifyContent: 'center',
    },
    nanaPhone: {
        display: "grid",
        justifyContent: 'center',
    },
    coming: {
        marginTop: '40px',
    },
    resendCode: {
        display: 'grid',
        paddingTop: '0px',
        paddingBottom: '0px',
        marginTop: '0px',
        marginBottom: '0px',

    },
    banner: {
        filter: 'grayscale(100%)',
        padding: '5px',
        paddingRight: '10px',
    },
    txtConnect: {
        color: 'white',
        fontFamily: "Halyard Display",
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'none',
        lineHeight: '22px',
        textDecoration: 'underline'

    },
    btnResendCode: {
        color: 'white',
        fontFamily: "Halyard Display",
        fontSize: '11px',
        textTransform: 'none',
        lineHeight: '22px',
        textDecoration: 'underline',
        width: '100%',

    }
}))



const TokenLogin: React.FC = () => {
    const navigate = useNavigate()
    const classes = useStyles()
    const redirectURL = useSearchParam('redirect')
    const switchURL = useSearchParam('switch_url')

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [url, setUrl] = useState('')
    const [redirectUrl, setRedirectUrl] = useState('')
    const [token, setToken] = useState('')

    const authForm = useRef<HTMLFormElement>(null)
    const bntSubmitCaptive = useRef<HTMLButtonElement>(null)

    const handleCaptiveLogin = useCallback(
        async () => {
            setLoading(true)
            try {
                if (switchURL) {
                    setUrl(switchURL)
                    if (redirectURL){
                        setRedirectUrl(redirectURL)}
                    // const queryString = window.location.search
                    const aForm = authForm.current
                    if (aForm && aForm !== null) {
                        aForm.action = switchURL
                        if (bntSubmitCaptive.current) {
                            try {
                                bntSubmitCaptive.current?.click()
                                FirebaseAnalytics({name:'captive_login_post'})
                                console.log("CAPTIVE LOGIN SUCCESS: Post success")
                            } catch (error) {
                                console.log(`CAPTIVE LOGIN FAILED: ${error}`)
                                setError('Errore nel login')
                            }
                        }
                    } else {
                        console.log("CAPTIVE LOGIN FAILED: Missing form")
                        setError('Errore nel login')
                    }
                } else {
                    console.log('CAPTIVE LOGIN FAILED: Missing switchURL')
                    setError('Errore nel login')
                }
            } catch (error) {
                setError((error as Error).message)
                console.log(`CAPTIVE LOGIN FAILED: ${error}`)
                FirebaseAnalytics({name:'captive_login_post_error'})
            }
            finally {
                setLoading(false)
            }

        }, [navigate, switchURL, redirectURL])


    const handleErrorSnackbarClose = useCallback(() => {
        setError(null)
    }, [])

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => { 
        setToken(event.target.value)
        // Token login use the token as username and password
        setUser(event.target.value)
        setPassword(event.target.value)
    }

    const TokenForm = (
        <>
            <Grid item xs={12} xl={12}>
                <Typography variant="h4">Accedi alla rete wifi</Typography>
                <Typography variant="body2">Inserisci il token con cui potrai accedere gratuitamente alla rete WIFI di Nana Bianca.
				</Typography>
            </Grid>
            <Grid item xs={12} xl={12}>
                <TextField
                    required
                    className="phoneForm"
                    id='phone-number'
                    onChange={handleInput}
                    label='Inserisci il token'
                    variant="filled"
                    value={token}

                />
            </Grid>

            <Grid item xs={12} xl={12} >
                <Button
                    className={classes.button}
                    disabled={loading}
                    onClick={handleCaptiveLogin}
                    size='large'
                    variant='contained'
                    color='secondary'
                >
                    Inizia a navigare
				</Button>
            </Grid>
        </>
    )


    return (
        <>
            <Backdrop className={classes.loadingBackdrop} open={loading}>
                <CircularProgress color='inherit' />
            </Backdrop>
            <form action={url} method='POST' ref={authForm}
                className={classes.hidden}>
                <input type='text' name='redirect_url' value={redirectUrl} readOnly />
                <input type='text' name='switch_url' value={url} readOnly />

                <input type='text' name='username' value={user} readOnly />
                <input type='text' name='password' value={password} readOnly />
                <input type='text' name='buttonClicked' value='4' readOnly />
                <button type='submit' id='auth-submit' ref={bntSubmitCaptive} />
            </form>

            <Flex className={classes.background} margin={0} display="block">
                <Flex
                    alignItems='center'
                    justifyContent='space-around'
                    margin={2}
                >
                    <Flex color='white' flex={0}>

                        <Grid container color='white' spacing={3}>
                            <Grid item xs={12} xl={12}>
                                <Box paddingBottom={10}  >
                                    <NbLogo className={classes.logo} title='logo' />
                                </Box>
                            </Grid>

                            <Grid item xs={12} xl={12} className={classes.buttonGrid}>
                                <Grid container alignItems="center" spacing={5}>
                                    {TokenForm}
                                </Grid>
                            </Grid>


                        </Grid>

                        <ErrorSnackbar
                            error={error}
                            onClose={handleErrorSnackbarClose}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export default TokenLogin
