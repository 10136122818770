import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'


import { ReactComponent as FioriniLogo } from '~/assets/images/nb-star.svg'

export default function FioriniIcon(props: SvgIconProps) {
	return (
		<>
			<SvgIcon {...props}>
				<FioriniLogo />
			</SvgIcon>
		</>
	)
}
