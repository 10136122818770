import {
    makeStyles,
    AppBar,
    Toolbar,
    Typography,
    AppBarProps,
    Button,
    CardContent,
    CardActions,
    Card,
    Box,
    Grid,

} from '@material-ui/core'
import clsx from 'clsx'
import PropTypes, { InferProps } from 'prop-types'
import React, { useCallback, useState, useEffect } from 'react'


import SlideOnScroll from './slide-on-scroll'

const useStyles = makeStyles(() => ({
    appBar: {
        paddingTop: 'env(safe-area-inset-top, 0)',
    },
    title: {
        flexGrow: 1,
    },
    card: {
        width: '100%',
        color: 'white',
        backgroundColor: '#0A24EB',
    },
}))


const BannerProps = {
    confirmButton: PropTypes.element,
    closeButtonText: PropTypes.string,
    text: PropTypes.string,
    visible: PropTypes.bool,
    icon: PropTypes.element
}

const TopBanner: React.FC<AppBarProps & InferProps<typeof BannerProps>> = ({
    children = null,
    className,
    color = 'inherit',

    position = 'sticky',
    closeButtonText = "No, grazie",
    confirmButton,
    text,
    visible,
    icon,
    ...rest
}) => {
    const [isVisible, setIsVisible] = useState(visible)
    const classes = useStyles()

    const onClose = () => {
        setIsVisible(false)
    }

    useEffect(() => {
        setIsVisible(visible)
	}, [visible])

    if (isVisible) {
        return (
            <>
                <SlideOnScroll>
                    <AppBar
                        {...rest}
                        className={clsx(classes.appBar, className)}
                        color={color}
                        position={position}
                    >
                        <Toolbar disableGutters>

                            <Card className={classes.card}>
                                <CardContent>
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={2}>
                                            {icon}
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography
                                                variant='subtitle2'
                                            >
                                                {text}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions className='bannerCardAction'>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                        <Button onClick={onClose}>{closeButtonText}</Button>
                                        </Grid>
                                        <Grid item>

                                        {confirmButton}
                                        </Grid>
                                    </Grid>
                                    
                                </CardActions>
                            </Card>

                        </Toolbar>

                        {children}
                    </AppBar>
                </SlideOnScroll>
            </>
        )
    } else {
        return (<></>)
    }

}

TopBanner.propTypes = BannerProps

export default TopBanner
