import { makeStyles, Card, CardMedia, CardProps } from '@material-ui/core'
import PropTypes, { InferProps } from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	
	cardMedia: {
		height: 320,
	},
}))

const RoomCardImageProps = {
	resourceId: PropTypes.number.isRequired,
}

const RoomCardImage: React.FC<CardProps & InferProps<typeof RoomCardImageProps>> = ({
	resourceId,
	...rest
}) => {
	const classes = useStyles()

	const imageUrl = `//nanabianca.spaces.nexudus.com/en/publicresources/getimage/${resourceId}?h=400`

	return (
		<Card {...rest}  raised>
			<CardMedia
				className={classes.cardMedia}
				image={imageUrl}
				title='Immagine stanza'
			/>
		</Card>
	)
}

RoomCardImage.propTypes = RoomCardImageProps

export default RoomCardImage
