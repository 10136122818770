import React, {
	useState,
	Suspense,
	useCallback,
} from 'react'
import Header from '~/components/header'
import {
	Divider,
	Grid,
	TextField,
	Box,
	Backdrop,
	CircularProgress,
	Button,
	makeStyles,
	LinearProgress, DialogTitle, DialogContent, Dialog, DialogActions,
} from '@material-ui/core'
import ErrorBoundary from '~/components/error-boundary'
import ApiInstance from '~/api'
import Flex from '~/components/flex'
import {useParams} from "react-router";


const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%',
	},
	grid: {
		padding: theme.spacing(3),
	},
	button: {
		width: '100%',
		marginTop: '50px',
	},
}))


const InviteToTeam: React.FC = () => {
	const { id } = useParams();
	const classes = useStyles()
	const [loading, setLoading] = useState(false)
	const [emailInvite, setEmailInvite] = useState('')
	const [showResultDialog, setShowResultDialog] = useState(false)
	const [dialogText, setDialogText] = useState("");
	const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailInvite(event.target.value)
	}
	const handleEmailBlur = (event:React.FocusEvent<HTMLInputElement>) => {
		setEmailInvite(event.target.value)
	}


	const handleSubmit = async () => {
		const emailValidation = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		if (emailInvite && emailValidation.test(String(emailInvite).toLowerCase())) {
			setLoading(true);
			const args = {
				email: String(emailInvite).toLowerCase(),
				teamId: id
			}

			try {
				const response = await ApiInstance.post(
					'/team-manager/coworker/invitation/generate',
					args
				)
				if (response !== undefined) {
					setLoading(false);
					setDialogText("invito inviato con successo");
					setShowResultDialog(true);
				}
			} catch (error) {
				setLoading(false)
				setDialogText("si è verificato un errore");
				setShowResultDialog(true);
			}
		} else {
			setDialogText("si prega di compilare il campo email con una email valida");
			setShowResultDialog(true)
		}
		return false
	};


	const handleResultDialogClose = useCallback(async () => {
		setShowResultDialog(false)
	}, [])

	return (
	<>
		<Dialog onClose={handleResultDialogClose} open={showResultDialog}>
			<DialogTitle>Invito utente</DialogTitle>
			<DialogContent>
				{dialogText}
			</DialogContent>
			<DialogActions>
				<Button color='primary' onClick={handleResultDialogClose}>
					Chiudi
				</Button>
			</DialogActions>
		</Dialog>

		<Flex paddingTop={2} paddingBottom={4}>
			<Box>
				<ErrorBoundary>
					<Suspense fallback={<LinearProgress />}>
						<Header title='Il Team' className={"modalHeader"}/>
					</Suspense>
				</ErrorBoundary>
				<Divider />
				<Backdrop open={loading}>
					<CircularProgress color='inherit' />
				</Backdrop>
			</Box>
			<div className={classes.form}>
				<Grid
					container
					justifyContent='center'
					className={classes.grid}
					spacing={4}
				>
					<Grid item xs={12}>
						<TextField
							className={classes.form}
							id='Email'
							label='Email'
							value={emailInvite}
							onChange={handleEmail}
							required={true}
							helperText="Inserire la mail dell'utente da invitare"
							type={"email"}
							onBlur={handleEmailBlur}
						/>
					</Grid>

					<Grid item xs={12}>
						<Button
							className={classes.button}
							onClick={handleSubmit}
							variant='contained'
							color='primary'
						>
							Aggiungi al Team
						</Button>
					</Grid>
				</Grid>
			</div>
		</Flex>
	</>
	);
}
export default InviteToTeam;
