import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import useSWR, { SWRResponse } from 'swr'

import ApiInstance from '~/api'

interface ApiInterface<Data, Error>
	extends Omit<
		SWRResponse<AxiosResponse<Data>, AxiosError<Error>>,
		'data'
	> {
	// * `data` is always defined when using suspense
	data: Data;
}

const apiFetcher = async (
	path: string,
	jsonConfig: string
): Promise<AxiosResponse> => {
	const config = jsonConfig ? JSON.parse(jsonConfig) : {}
	const response = await ApiInstance(path, config)

	return response
}

const useApi = <Data = unknown, Error = unknown>(
	path: string,
	config?: AxiosRequestConfig
): ApiInterface<Data, Error> => {
	const key = [path, JSON.stringify(config)]

	const { data: response, ...rest } = useSWR(key, apiFetcher, {
		suspense: true,
	})

	return {
		...rest,
		data: response?.data,
	}
}

export default useApi
