import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'


const apiRequestInterceptor = async (
	config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
	const { currentUser } = await firebase.auth()

	if (currentUser) {
		const token = await currentUser.getIdToken()
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		config.headers.common.Authorization = `Bearer ${token}`
	}

	if (config.data instanceof FormData)
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		config.headers.common['Content-Type'] = 'multipart/form-data'

	return config
}

const apiResponseInterceptor = (response: AxiosResponse): AxiosResponse => {
	const { data } = response

	if (data?.WasSuccessful === false) throw new Error(data.Message)

	const status = data?.Status || 200
	if (!axios.defaults.validateStatus?.(status)){
		throw new Error(data.Message)
	}

	return response
}

export { apiRequestInterceptor, apiResponseInterceptor }
