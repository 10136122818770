import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as CowoIcoSvg } from '~/assets/images/icons/cowo.svg'

export function CowoIcon(props: SvgIconProps) {
	return (
		<>
			<SvgIcon {...props}>
				<CowoIcoSvg />
			</SvgIcon>
		</>
	)
}
