const DEBUG = process.env.NODE_ENV === 'development'

export const ENABLE_PAYMENT_FEATURE = DEBUG
export const ENABLE_DARK_THEME_FEATURE = DEBUG
export const ENABLE_FOOD_FEATURE = DEBUG
export const ENABLE_TEAM_MANAGEMENT = DEBUG
export const ENABLE_COIN_MANAGEMENT = DEBUG
export const ENABLE_GUEST_NETWORK_TOKEN = true

export const API_BASE_URL = DEBUG ? '//space-proxy.127.0.0.1.nip.io:5000/space-app-8cf36/us-central1/api/' : '//europe-west1-space-app-8cf36.cloudfunctions.net/api/'
export const API_ADMIN_URL = DEBUG ? '//space-proxy.127.0.0.1.nip.io:5000/space-app-8cf36/us-central1/api/nexudus/admin/' : '//europe-west1-space-app-8cf36.cloudfunctions.net/api/nexudus/admin/'
// export const API_BASE_URL = '//us-central1-space-app-8cf36.cloudfunctions.net/api/'
export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51HpF6zH1m7vLt3A6lMQpNE6K5s2l2DYJM6gdHwrByNiVlgzB5bp2idD4RRAP6qi9APpnNEiZ6v982fcBYKd61tOy0053Eip2pW'
// if(DEBUG) {
// 	API_BASE_URL = '//127.0.0.1:5000/space-app-8cf36/us-central1/api/'
// }
