import React from 'react'

import Header from '~/components/header'

const GymCalendar: React.FC = () => (
	<>
		<Header title='Prenota palestra' />
		
	</>
)

export default GymCalendar
