import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as NbWifiTabletSvg } from '~/assets/images/icons/devices/nb-wifi-tablet.svg'

function NbWifiTablet(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbWifiTabletSvg />
		</SvgIcon>
	)
}

export default NbWifiTablet
