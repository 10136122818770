import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as NbWifiDesktopSvg } from '~/assets/images/icons/devices/nb-wifi-desktop.svg'

function NbWifiDesktop(props: SvgIconProps) {
	return (
		<SvgIcon {...props}>
			<NbWifiDesktopSvg />
		</SvgIcon>
	)
}

export default NbWifiDesktop
