import {Drawer, makeStyles, Hidden, Grid, IconButton, Snackbar} from '@material-ui/core'
import {Close} from 'mdi-material-ui'
import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as NbStar } from '~/assets/images/nb-star.svg'
import { DASHBOARD_NAVIGATION_LINKS } from '~/constants/dashboard'

import Flex from './flex'

import FirebaseAnalytics from '~/components/firebase-analytics'

const useStyles = makeStyles((theme) => {
	const drawerOpenWidth = theme.spacing(12)

	return {
		drawer: {
			flexShrink: 0,
			whiteSpace: 'nowrap',
			width: drawerOpenWidth,
		},
		drawerLogo: {
			fill: theme.palette.primary.main,
			padding: theme.spacing(1),
			width: drawerOpenWidth,
			maxHeight: '100%',
		},
		toolbar: {
			...theme.mixins.toolbar,
		},
		drawerText: {
			fontSize: "0.8em",
			fontWeight: "bold",
			color: "#B9BDDB",
			marginTop: "-1em",
			marginBottom: "2em",
		},
		drawerTextSelected: {
			fontSize: "0.8em",
			fontWeight: "bold",
			color: "#0B2262",
			marginTop: "-1em",
			marginBottom: "2em",
		},
		linkContainerClass: {
			textDecoration: "none",
		},
		drawerIconSelected: {color: "#0B2262",},
		drawerIcon: {color: "#B9BDDB",},
	}
})


interface Props {
	user?: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>|null;
}


const DashboardDrawer: React.FC<Props> = (props) => {
	const location = useLocation()
	const classes = useStyles()
	const [showEasterEgg, setShowEasterEgg] = useState(false)
	const [easterEggMessage, setEasterEggMessage] = useState('')
	const [easterEggClick, setEasterEggClick] = useState(0)
	const easterEggMessages = ['WE BUILD THE FUTURE 👷‍♂️ FIXING THE PRESENT!', 'SI MA ORA SMETTILA DI CLICCARE', "DAVVERO BASTA NON C'É ALTRO"]
	
	const user = props.user || null

	const handleEasterEgg = () =>{
		if (easterEggClick === 6){
			setEasterEggMessage(easterEggMessages[0])
			setShowEasterEgg(true)
			FirebaseAnalytics({name:'EASTER EGG #1'})
		}else if(easterEggClick === 20){
			setEasterEggMessage(easterEggMessages[1])
			setShowEasterEgg(true)
			FirebaseAnalytics({name:'EASTER EGG #2'})
		}else if(easterEggClick===35){
			setEasterEggMessage(easterEggMessages[2])
			setShowEasterEgg(true)
			setEasterEggClick(0)
			FirebaseAnalytics({name:'EASTER EGG #3'})
			return
		}
		setEasterEggClick(easterEggClick+1)
	}

	const closeEasterEgg = () => {
		setShowEasterEgg(false)
	}

	return (
		<Hidden smDown>
			  <Snackbar
				open={showEasterEgg}
				autoHideDuration={6000}
				onClose={closeEasterEgg}
				message={easterEggMessage}
				action={
					<IconButton
					aria-label='close'
					color='inherit'
					onClick={closeEasterEgg}
					size='small'
					>
						<Close fontSize='small' />
					</IconButton>
				}
      		/>
			<Drawer className={classes.drawer} variant='permanent'>
				<Flex className={classes.toolbar} alignItems='center' flex={0}>
					<NbStar className={classes.drawerLogo} title='logo' onClick={handleEasterEgg}/>
				</Flex>

				<Flex justifyContent='center'>
					{DASHBOARD_NAVIGATION_LINKS.map((value) => {
						if(value.hide) {
							return
						}
						if(value.visibilityRole && !user) {
							return
						}
						if(value.visibilityRole && user && !user.get(value.visibilityRole)) {
							return
						}

						const isSelected = location.pathname === value.to

						return (
							<Link key={value.to} to={value.to} className={classes.linkContainerClass}>
								<Grid container direction="column" justifyContent="center" alignItems="center">
									<Grid item>
										<IconButton aria-label={value.label}
													className={isSelected ? classes.drawerIconSelected : classes.drawerIcon}>
											<value.icon fontSize="large" />
										</IconButton>
									</Grid>
									<Grid item className={isSelected ? classes.drawerTextSelected : classes.drawerText}>
										{value.label}
									</Grid>
								</Grid>
							</Link>
							// <LabelButton
							// 	color={isSelected ? 'primary' : 'default'}
							// 	component={Link}
							// 	key={value.to}
							// 	label={value.label}
							// 	to={value.to}
							// >
							// 	<value.icon />
							// </LabelButton>
						)
					})}
				</Flex>
			</Drawer>
		</Hidden>
	)
}

export default DashboardDrawer
