import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'


import { ReactComponent as BookDailyPassIconSVG } from '~/assets/images/icons/booking-dailypass.svg'

export function DailyPassIcon(props: SvgIconProps) {
	return (
		<>
			<SvgIcon {...props}>
				<BookDailyPassIconSVG />
			</SvgIcon>
		</>
	)
}