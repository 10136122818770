import React, {Suspense, useCallback, useEffect, useState} from "react";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles
} from "@material-ui/core";
import ApiInstance from "~/api";
import {useNavigate} from "react-router";
import ErrorBoundary from "~/components/error-boundary";
import {PlusCircle} from "mdi-material-ui";
import RemoveUserIcon from "@material-ui/icons/HighlightOff";
import ReplayIcon from "@material-ui/icons/Replay";
import useApi from "~/hooks/use-api";
import {useSelector} from "react-redux";
import {RootState} from "~/store";

const useStyles = makeStyles(() => ({
	h2: {
		opacity: 0.32,
		color: "#0C0832",
		fontFamily: "Mono45-Headline, sans-serif",
		fontSize: "20px",
		fontWeight: "bold",
		letterSpacing: "0.5px",
		lineHeight: "24px",
		textTransform: "uppercase",
		margin:0,
		padding: "10px",
		border: "1px solid #C2C2C2",
	},
	titleInvited: {
		opacity: 0.32,
		color: "#0C0832",
		fontFamily: "Mono45-Headline, sans-serif",
		margin:0,
		padding: "10px",
	},
	addMember: {
		width: "100%",
		marginBottom: "40px",
	},
	noMembersTeam: {
		display: "block",
		textAlign: "center"
	},
	reInvite: {

	}
}))

interface Props {
	teamData: any;
	idUser: unknown;
}

interface Invitation {
	email: string;
	teamId: string;
	teamLeader: number;
	token: string;
}

const TeamList: React.FC<Props> = (props) => {
	const classes = useStyles();
	const navigate = useNavigate()

	const [loading, setLoading] = useState(false);
	const [showResultDialog, setShowResultDialog] = useState(false)
	const [dialogText, setDialogText] = useState("");
	const [dialogTitle, setDialogTitle] = useState("");
	const [ungroupUser, setUngroupUser] = useState({teamId:"", coworkerId:""})

	const [userList, setUserList] = useState([<></>]);
	const [invitedList, setInvitedList] = useState(null);
	const profileInfo = useSelector( (state: RootState) => { return state?.user?.user;  } );
	const {data, mutate} = useApi<any>(`/team-manager/team?teamId=${props.teamData.Id}`, {cache:false});
	const alreadyInvited = Array(0);

	useEffect(()=>{
		if (profileInfo?.TeamMembers && profileInfo?.TeamMembers.length > 0) {
			const sortedTeamMembers = profileInfo.TeamMembers.slice().sort((a:any, b:any) => {
				return a.FullName > b.FullName ? 1 : -1
			})

			const TeamMemberList = sortedTeamMembers.map( (user: any) =>{
				alreadyInvited.push(user.Email);
				return (
					<ListItem key={`list-item-${user.UniqueId}`}>
						<ListItemText>{user.FullName}</ListItemText>
						{props.teamData.PayingMemberId === props.idUser && user.Id !== props.idUser ?
							<ListItemIcon>
								<RemoveUserIcon fontSize={"small"} color={"secondary"} onClick={() => {
									handleRemoveUser(user.FullName, props.teamData.Id, user.Id);
								}}/>
							</ListItemIcon>
							: null}
					</ListItem>
				)
			});

			if (TeamMemberList.length>0){
				setUserList( TeamMemberList );
			} else {
				setUserList(
				[<ListItem key={"list-item-default"}>
					<ListItemText>Non sono presenti iscritti a questo team</ListItemText>
				</ListItem>]
				);
			}

		}

		if ( data.Invitations && data.Invitations.length > 0 ) {
			const InvitedMemberList = data.Invitations.sort().map( (invitation: Invitation) => {
				if (alreadyInvited.find((value) => { return value === invitation.email}) === undefined) {
					alreadyInvited.push(invitation.email);
					return (
						<ListItem key={`invitation-${invitation.token}`}>
							<ListItemText>
								{invitation.email}
							</ListItemText>
							{
								props.teamData.PayingMemberId === props.idUser &&
								<ListItemIcon>
									<RemoveUserIcon fontSize={"small"} color={"secondary"} onClick={() => {
										handleRemoveInvite(invitation)
									}}/>
									<ReplayIcon fontSize={"small"} onClick={() => {
										handleReinvite(props.teamData.Id, invitation.email)
									}}/>
								</ListItemIcon>
							}
						</ListItem>
					)
				}

			});
			setInvitedList( InvitedMemberList );
		}


		return () => {
			setUserList([<></>])
			setInvitedList(null)
		}

	}, [profileInfo, data]);


	const handleRemoveUser = (fullName: string, teamId: any, coworkerId: string) => {
		setDialogTitle("Rimozione utente");
		setDialogText(`Sei sicuro di voler eliminare ${fullName} questo utente dal team?`);
		setShowResultDialog(true);
		setUngroupUser({teamId: teamId, coworkerId: coworkerId});
	}

	async function handleRemoveInvite(invitation: Invitation) {
		setLoading(true)
		await ApiInstance.post('/team-manager/coworker/invitation/remove', {token: invitation.token})
		await mutate()
		setLoading(false)

	}

	const handleResultDialogClose = () => {
		setShowResultDialog(false);
		if (ungroupUser !== {teamId:"", coworkerId:""}){
			setUngroupUser({teamId:"", coworkerId:""});
		}
	}

	const handleResultDialogContinue = async () => {
		setShowResultDialog(false);

		if(ungroupUser.teamId!=="" && ungroupUser.coworkerId!==""){
			setLoading(true);
			const args = {
				coworkerId: ungroupUser.coworkerId,
				teamId: ungroupUser.teamId
			}
			try {
				const response = await ApiInstance.post('/team-manager/coworker/remove', args)
				if (response !== undefined) {
					setUserList(userList.filter((row:any)=>{ return row.key !== ungroupUser.coworkerId} ));
					setDialogTitle("Rimozione utente");
					setDialogText("Utente Eliminato dal team");
					setShowResultDialog(true);
					setUngroupUser({teamId:"", coworkerId:""});
					setLoading(false);
				}
			} catch (error) {
				setDialogTitle("Rimozione utente");
				setDialogText("Si è verificato un errore nell'invio della richiesta si prega di riprovare");
				setShowResultDialog(true);
				setLoading(false)
			}
		} else {
			setDialogTitle("Rimozione utente");
			setDialogText("Si è verificato un errore: mancano dati per l'eliminazione");
			setShowResultDialog(true);
		}
	}

	const handleAddUser = useCallback(async (teamId: string | number ) => {
		navigate(`invite/${teamId}`);
	}, [navigate]);

	const handleReinvite = async (groupId: any, userMail: string) => {

		if(groupId!=="" && userMail!=="") {
			const args = {
				email: String(userMail).toLowerCase(),
				teamId: groupId
			}
			setLoading(true);
			try {
				const response = await ApiInstance.post(
					'/team-manager/coworker/invitation/generate',
					args
				)
				if (response !== undefined) {
					setLoading(false);
					setDialogTitle("Reinvita utente");
					setDialogText("invito reinviato con successo");
					setShowResultDialog(true);
				}
			} catch (error) {
				setLoading(false)
				setDialogTitle("Reinvita utente");
				setDialogText("si è verificato un errore nell'invio dell'invito");
				setShowResultDialog(true);
			}
		}
	};

	return (
		<>
			<Dialog onClose={handleResultDialogClose} open={showResultDialog}>
				<DialogTitle>{dialogTitle}</DialogTitle>
				<DialogContent>
					{dialogText}
				</DialogContent>
				<DialogActions>
					<Button color='primary' onClick={handleResultDialogClose}>
						{ungroupUser.teamId !== "" && ungroupUser.coworkerId !== "" ? "Annulla" : "Chiudi"}
					</Button>
					{ungroupUser.teamId !== "" && ungroupUser.coworkerId !== "" &&
						<Button color='primary' onClick={handleResultDialogContinue}>
							Continua
						</Button>}
				</DialogActions>
			</Dialog>
			<ErrorBoundary>
				<Suspense fallback={<LinearProgress/>}>
					<Box>
						<h2 className={classes.h2}>{props.teamData.Name}</h2>
						<Divider/>
					</Box>
					<List>
						{userList}
					</List>
					{props.teamData.PayingMemberId === props.idUser && invitedList !== null &&
						<div>
							<p className={classes.titleInvited}>Inviti: </p>
							<List>{invitedList}</List>
						</div>
					}
					{props.teamData.PayingMemberId === props.idUser &&
						<Button className={classes.addMember} onClick={() => {
							handleAddUser(props.teamData.Id);
						}}>
							<PlusCircle color="primary"/>
							Aggiungi un membro al team
						</Button>
					}
					<Backdrop open={loading}>
						<LinearProgress/>
						<CircularProgress color='inherit'/>
					</Backdrop>
				</Suspense>
			</ErrorBoundary>
		</>
	)
}
export default TeamList

