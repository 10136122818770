import {
	BottomNavigation,
	BottomNavigationAction,
	Box,
	Hidden,
} from '@material-ui/core'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {DASHBOARD_NAVIGATION_LINKS} from "~/constants/dashboard";
import {isIos, isWebView} from "~/services/utils-service"

interface Props {
	user?: firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>|null;
}

const UsesafeArea = () => {
	return isWebView() && isIos()
}

const DashboardBottomNavigation: React.FC<Props> = (props) => {
	const location = useLocation()
	const user = props.user || null

	return (
		<>
		<Hidden mdUp>
			<Box height='85px'/>
		</Hidden>
		<Box position='fixed' bottom={UsesafeArea() ? 1:0} left={0} right={0}>
			{/* <Flex alignItems='flex-end' m={1}>
				<ScrollToTop />
			</Flex> */}

			<Hidden mdUp>
				<Box paddingBottom='0px'>
					<BottomNavigation showLabels value={location.pathname}>
						{DASHBOARD_NAVIGATION_LINKS.map((value) => {
							if(value.hide) {
								return
							}
							if(value.visibilityRole && !user) {
								return
							}
							if(value.visibilityRole && user && !user.get(value.visibilityRole)) {
								return
							}
							return <BottomNavigationAction
								key={value.to}
								component={Link}
								icon={<value.icon/>}
								label={value.label}
								to={value.to}
								value={value.to}
							/>
						})}
					</BottomNavigation>
				</Box>

			</Hidden>
		</Box>
		</>
	)
}

export default DashboardBottomNavigation
