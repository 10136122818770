import { createGlobalState } from 'react-use'

const ACTIVE_BOOKINGS_TAB = 'active'
const INACTIVE_BOOKINGS_TAB = 'inactive'

const useDashboardBookingsTab = createGlobalState(ACTIVE_BOOKINGS_TAB)

export {
	ACTIVE_BOOKINGS_TAB,
	INACTIVE_BOOKINGS_TAB,
	useDashboardBookingsTab as default,
}
