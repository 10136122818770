import { UserState, SET_USER, UNSET_USER, UserActionTypes} from './types'

const initialState: UserState = {
	user: null,
	loading: true
}

export default function userStates (
	state = initialState,
	action: UserActionTypes
): UserState {
	switch (action.type){
		case SET_USER:
			return {
				user: action.payload,
				loading: false
			}
		case UNSET_USER:
			return {
				user: null,
				loading: true
			}
		default:
			return state
	}
}
