import React, { useState } from 'react'
import Header from '~/components/header'
import {
	TextField,
	Button,
	List,
	ListItem,
	ListItemIcon,
	Typography,
	makeStyles,
	Box,
	Grid,
} from '@material-ui/core'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'

import { DEVICES } from '~/constants'
import DeviceAvatar from '~/components/device-avatar'
import PasswordManager from '~/components/password-manager'
import FirebaseAnalytics from '~/components/firebase-analytics'
import { Wifi } from 'mdi-material-ui'
import { v4 as uuid } from 'uuid'

const useStyles = makeStyles((theme) => {
	return {
		hlist: {
			display: 'flex',
			flexDirection: 'row',
			padding: 0,
			flexWrap: 'wrap',
		},
		fullwidth: {
			width: '100%',
		},
		buttonWhite: {
			color: '#FFFFFF',
		},
		grid: {
			paddingBottom: theme.spacing(5),
		},
		newButton:{
			paddingBottom: theme.spacing(5),
			marginTop: '50px',
			width: '100%'
		},
		selected: {
			'&.Mui-selected': {
				color: theme.palette.text.primary,
			},
		},

	}
})


const getUserInfo = async () => {
	const user = await firebase.auth().currentUser
	// TODO: Manage the object can possibly be null
	let email = user!.email
	if (email === null) {
		email = ''
	}
	return { uid: user!.uid, email: email }
}

const GenerateToken: React.FC = () => {
	const classes = useStyles()

	const [deviceName, setDeviceName] = useState('')
	const [username, setUsername] = useState('')
	const [deviceIcon, setDeviceIcon] = useState('')

	const [showPasswordDialog, setShowPasswordDialog] = useState(false)
	const [selectedIcon, setSelectedIcon] = useState(DEVICES.PHONE)

	const [disabled, setDisabled] = useState(false)

	const [icons] = useState([
		DEVICES.PHONE,
		DEVICES.DESKTOP,
		DEVICES.LAPTOP,
		DEVICES.TABLET,
		DEVICES.GAME,
		DEVICES.CAST,
		DEVICES.PRINTER,
		DEVICES.BOT,
	])

	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: string
	) => {
		setSelectedIcon(index)
		setDisabled(false)
	}

	const handleInput = (event: {
		target: { value: React.SetStateAction<string> };
	}) => {
		setDeviceName(event.target.value)
	}

	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
	}

	async function handleClick() {

		if (!deviceName || deviceName === '') {
			const [partialUuid, ...allUuid] = uuid().split('-')
			setDeviceName(`${selectedIcon}-${partialUuid}`)
		}
		FirebaseAnalytics({name:'create_new_authentication'})
		setToken()
	}

	async function setToken() {
		const userInfo = await getUserInfo()
		let icon = selectedIcon
		if (selectedIcon === undefined) {
			icon = DEVICES.PHONE
		}

		setDeviceIcon(icon)
		setUsername(userInfo.email)
		setShowPasswordDialog(true)
	}

	return (
		<>
			<Header title='Crea Password' className={"modalHeader"}/>
			<PasswordManager open={showPasswordDialog}
				deviceName={deviceName}
				username={username}
				deviceIcon={deviceIcon}/>

			<Box padding={3} className={"deviceClassSelector"} >
				<Grid container justifyContent={"center"} spacing={5}>
					<Grid item xl={6} md={6} >
						<form onSubmit={handleFormSubmit}>
							<Grid
								container
								item
								justifyContent='center'
								xs={12}
								className={classes.grid}
							>
								<Wifi
									color='primary'
									fontSize='large'
									className={classes.fullwidth}
									style={{fontSize: "10rem"}}
								/>
							</Grid>
							<Grid

								item
								xs={12}
								justifyContent='center'
								className={classes.grid}

							>
								<TextField
									className={classes.fullwidth}
									id='device-name'
									onChange={handleInput}
									label='Personalizza il nome del dispositivo'
									placeholder=''
								/>
							</Grid>
							<Grid item xs={12} className={classes.grid}>
								<Typography>
									Scegli il tipo di dispositivo
								</Typography>
							</Grid>
							<Grid item className={classes.grid} justifyContent={"center"}>
									<List className={classes.hlist}>
										{icons.map((icon, key) => (
											<Grid  item xs={3}  md={1}  xl={1} key={key}>
												<ListItem
													className={classes.selected}
													key={key}
													button
													selected={selectedIcon === icon}
													onClick={(event) =>
														handleListItemClick(
															event,
															icon
														)
													}
												>
													<ListItemIcon>
														<DeviceAvatar
															resourceId={icon}
														/>
													</ListItemIcon>
												</ListItem>
											</Grid>
										))}
									</List>
							</Grid>
							<Grid

								item
								xs={12}
								className={classes.newButton}
								alignItems='center'
								justifyContent='center'
							>
								<Button
									className={classes.fullwidth}
									onClick={handleClick}
									variant='contained'
									color='secondary'
									disabled={disabled}
								>
									{' '}
									CREA
								</Button>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}

export default GenerateToken
