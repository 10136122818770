import {
	Dumbbell,
	SpaceInvaders,
	SpaceStation,
	School,
	PacMan,
} from 'mdi-material-ui'

// import ASTEROID from '~/assets/images/icons/rooms/png/no_floor/asteroid.png'
import COBOL from '~/assets/images/icons/rooms/png/no_floor/cobol.png'
import DEFENDER from '~/assets/images/icons/rooms/png/no_floor/defender.png'
import DONKEYKONG from '~/assets/images/icons/rooms/png/no_floor/donkey.png'
import ARKANOID from '~/assets/images/icons/rooms/png/no_floor/arkanoid.png'
import FROGGER from '~/assets/images/icons/rooms/png/no_floor/frogger.png'
import GALAGA from '~/assets/images/icons/rooms/png/no_floor/galaga.png'
import JAVA from '~/assets/images/icons/rooms/png/no_floor/java.png'
import LEMMINGS from '~/assets/images/icons/rooms/png/no_floor/lemmings.png'
import PACMAN from '~/assets/images/icons/rooms/png/no_floor/pac man.png'
import PERL from '~/assets/images/icons/rooms/png/no_floor/perl.png'
import PHP from '~/assets/images/icons/rooms/png/no_floor/php.png'
import PYTHON from '~/assets/images/icons/rooms/png/no_floor/Python.png'
import QBERT from '~/assets/images/icons/rooms/png/no_floor/qbert.png'
import RUST from '~/assets/images/icons/rooms/png/no_floor/rust.png'
import SPACEINVADERS from '~/assets/images/icons/rooms/png/no_floor/invaders.png'
import SUPERMARIO from '~/assets/images/icons/rooms/png/no_floor/super mario.png'
import TETRIS from '~/assets/images/icons/rooms/png/no_floor/tetris.png'
import CISCO from '~/assets/images/icons/rooms/png/no_floor/cisco.png'
import SNAKE from '~/assets/images/icons/rooms/png/no_floor/snake.png'
import STARTUPITALIA from '~/assets/images/icons/rooms/png/no_floor/startupitalia.png'
import CALLINGBOOTH from '~/assets/images/icons/rooms/png/no_floor/callingbooth.png'

// 2x
// import ASTEROID_2x from '~/assets/images/icons/rooms/png/no_floor/2x/asteroid@2x.png'
import COBOL_2x from '~/assets/images/icons/rooms/png/no_floor/2x/cobol@2x.png'
import DEFENDER_2x from '~/assets/images/icons/rooms/png/no_floor/2x/defender@2x.png'
import DONKEYKONG_2x from '~/assets/images/icons/rooms/png/no_floor/2x/donkey@2x.png'
import FROGGER_2x from '~/assets/images/icons/rooms/png/no_floor/2x/frogger@2x.png'
import GALAGA_2x from '~/assets/images/icons/rooms/png/no_floor/2x/galaga@2x.png'
import JAVA_2x from '~/assets/images/icons/rooms/png/no_floor/2x/java@2x.png'
import LEMMINGS_2x from '~/assets/images/icons/rooms/png/no_floor/2x/lemmings@2x.png'
import PACMAN_2x from '~/assets/images/icons/rooms/png/no_floor/2x/pac man@2x.png'
import PERL_2x from '~/assets/images/icons/rooms/png/no_floor/2x/perl@2x.png'
import PHP_2x from '~/assets/images/icons/rooms/png/no_floor/2x/php@2x.png'
import PYTHON_2x from '~/assets/images/icons/rooms/png/no_floor/2x/Python@2x.png'
import QBERT_2x from '~/assets/images/icons/rooms/png/no_floor/2x/qbert@2x.png'
import RUST_2x from '~/assets/images/icons/rooms/png/no_floor/2x/rust@2x.png'
import SPACEINVADERS_2x from '~/assets/images/icons/rooms/png/no_floor/2x/invaders@2x.png'
import SUPERMARIO_2x from '~/assets/images/icons/rooms/png/no_floor/2x/super mario@2x.png'
import TETRIS_2x from '~/assets/images/icons/rooms/png/no_floor/2x/tetris@2x.png'
import CISCO_2x from '~/assets/images/icons/rooms/png/no_floor/2x/cisco@2x.png'
import SNAKE_2x from '~/assets/images/icons/rooms/png/no_floor/2x/snake@2x.png'
import STARTUPITALIA_2x from '~/assets/images/icons/rooms/png/no_floor/2x/startupitalia@2x.png'
import CALLINGBOOTH_2x from '~/assets/images/icons/rooms/png/no_floor/2x/callingbooth@2x.png'



import PropTypes, { InferProps } from 'prop-types'
import React from 'react'

import {
	GYM_ROOM_ID,
	INVADER_ROOM_ID,
	LEMMINGS_ROOM_ID,
	QBERT_ROOM_ID,
	SNAKE_ROOM_ID,
	SUPER_MARIO_ROOM_ID,
	TETRIS_ROOM_ID,
	DONKEY_KONG_ROOM_ID,
	DEFENDER_ROOM_ID,
	RUST_CB_ID,
	PYTHON_CB_ID,
	PHP_CB_ID,
	PERL_CB_ID,
	JAVA_CB_ID,
	COBOL_CB_ID,
	SCHOOL_ROOM_ID,
	SPACE_ROOM_ID,
	FROGGER_ID,
	PACMAN_ROOM_ID,
	CISCO_ROOM_ID,
	STARTUPITALIA_ID,
	ARKANOID_ID,
} from '~/constants'
import { Height } from '@material-ui/icons'

const RoomIconProps = {
	resourceId: PropTypes.number.isRequired,
}

// This is the avatar for the booking resources on Nexudus
const RoomIcon: React.FC<InferProps<typeof RoomIconProps>> = ({
	resourceId,
}) => {
	switch (resourceId) {
		case GYM_ROOM_ID:
			return <Dumbbell />
		case SNAKE_ROOM_ID:
			return <img srcSet={`${SNAKE} 1x,${SNAKE_2x} 2x `} alt="room icon" />
		case INVADER_ROOM_ID:
			return <img srcSet={`${SPACEINVADERS} 1x,${SPACEINVADERS_2x} 2x `} alt="room icon" />
		case LEMMINGS_ROOM_ID:
			return <img srcSet={`${LEMMINGS} 1x,${LEMMINGS_2x} 2x `} alt="room icon" />
		case QBERT_ROOM_ID:
			return <img srcSet={`${QBERT} 1x,${QBERT_2x} 2x `} alt="room icon" />
		case SUPER_MARIO_ROOM_ID:
			return <img srcSet={`${SUPERMARIO} 1x,${SUPERMARIO_2x} 2x `} alt="room icon" />
		case TETRIS_ROOM_ID:
			return <img srcSet={`${TETRIS} 1x,${TETRIS_2x} 2x `} alt="room icon" />
		case DONKEY_KONG_ROOM_ID:
			return <img srcSet={`${DONKEYKONG} 1x,${DONKEYKONG_2x} 2x`} alt="room icon" />
		case DEFENDER_ROOM_ID:
			return <img srcSet={`${DEFENDER} 1x,${DEFENDER_2x} 2x `} alt="room icon" />
		case CISCO_ROOM_ID:
			return <img srcSet={`${CISCO} 1x,${CISCO_2x} 2x `} alt="room icon" />
		case RUST_CB_ID: case PYTHON_CB_ID: case PHP_CB_ID: case JAVA_CB_ID: case PERL_CB_ID: case COBOL_CB_ID:
			return <img srcSet={`${CALLINGBOOTH} 1x,${CALLINGBOOTH_2x} 2x `} alt="room icon" />
		case PACMAN_ROOM_ID:
			return <img srcSet={`${PACMAN} 1x,${PACMAN_2x} 2x `} alt="room icon" />
		case FROGGER_ID:
			return <img srcSet={`${FROGGER} 1x,${FROGGER_2x} 2x `} alt="room icon" />
		case STARTUPITALIA_ID:
			return <img srcSet={`${STARTUPITALIA} 1x,${STARTUPITALIA_2x} 2x `} alt="room icon" />
		case SCHOOL_ROOM_ID:
			return <School />
		case SPACE_ROOM_ID:
			return <SpaceStation />
		case ARKANOID_ID:
			return <img src={`${ARKANOID}`} alt="room icon" width={32} height={32}/>
		default:
			return <img src={PACMAN} alt="room icon" />
	}
}

export default RoomIcon
