import {
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemProps,
	Box,
} from '@material-ui/core'
import { Moment } from 'moment'
import PropTypes, { InferProps } from 'prop-types'
import React from 'react'
import { momentPropType } from '~/utils'
import RoomAvatar from '~/components/room-avatar'

import { makeStyles } from "@material-ui/core/styles";
import { ReorderSharp } from '@material-ui/icons'

const DATE_SHORT_FORMAT = 'short'
const DATE_LONG_FORMAT = 'long'

const getDateTextFromFormat = (
	startDate: Moment,
	endDate: Moment,
	dateFormat?: string | null
): string => {
	switch (dateFormat) {
		case DATE_SHORT_FORMAT:
			return `Dalle ${startDate.format('LT')} alle ${endDate.format('LT')}`
		case DATE_LONG_FORMAT:
			return `${startDate.format('DD MMMM')}, ${startDate.format('LT')} - ${endDate.format('LT')}`
		default:
			throw new Error(`Invalid date format, provided ${dateFormat}.`)
	}
}

const BookingsListItemProps = {
	dateFormat: PropTypes.oneOf([DATE_SHORT_FORMAT, DATE_LONG_FORMAT]),
	endDate: momentPropType.isRequired,
	resourceId: PropTypes.number.isRequired,
	resourceName: PropTypes.string.isRequired,
	startDate: momentPropType.isRequired,
	resourceType: PropTypes.string,
}

const useStyles = makeStyles((theme) => ({
	coworkerName: {
		color: "#0A24EB"
	},
	bookingDate: {
		color: "#0C0832"
	}
}))


const BookingsListItem = <C extends React.ElementType>(
	props: ListItemProps<C> &
		InferProps<typeof BookingsListItemProps> & {
			endDate: Moment;
			startDate: Moment;
		}
) => {
	const classes = useStyles()
	const {
		dateFormat = DATE_LONG_FORMAT,
		endDate,
		resourceId,
		resourceName,
		resourceType,
		coworkerFullName,
		startDate,
		...rest
	} = props

	const dateText = getDateTextFromFormat(startDate, endDate, dateFormat)

	const resourcesIcon = () => {
		if (resourceType) {
			return (
				<RoomAvatar resourceId={resourceId} resourceTypeId={resourceType}/>
			)
		}else{
			return <RoomAvatar resourceId={resourceId} />
		}

	}

	return (
		<>
		<ListItem {...rest}>
			<ListItemAvatar>
				{resourcesIcon()}
			</ListItemAvatar>

			<ListItemText
				primary={resourceName}
				secondary={
					<React.Fragment>
						<Box className={classes.bookingDate}>{dateText}</Box>
						<Box className={classes.coworkerName}>{coworkerFullName}</Box>
					</React.Fragment>
				}
			/>
		</ListItem>
		</>
	)
}

BookingsListItem.propTypes = BookingsListItemProps

export default BookingsListItem
