import {Box, Divider, Grid, LinearProgress, Typography,} from '@material-ui/core'
import React, {Suspense, useEffect, useState} from 'react'
import ErrorBoundary from '~/components/error-boundary'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import Header from "~/components/header"
import {makeStyles} from "@material-ui/core/styles";
import useApi from "~/hooks/use-api";

const QRCode = require('qrcode.react');

const useStyles = makeStyles(() => ({
		h10: {marginBottom: '10px', backgroundColor: 'transparent'},
		h20: {marginBottom: '20px', backgroundColor: 'transparent'},
		h30: {marginBottom: '30px', backgroundColor: 'transparent'},
		h40: {marginBottom: '40px', backgroundColor: 'transparent'},
		h50: {marginBottom: '50px', backgroundColor: 'transparent'},
		h60: {marginBottom: '60px', backgroundColor: 'transparent'},
		whiteBorder: {
			border: "20px solid white",
			borderRadius: "20px",
		},
		qrCodeContainer: {
			minHeight: "210px",
		},
	})
)

const QRCodeTab: React.FC = () => {
	const classes = useStyles()
	const [userProfile, setUserProfile] = useState<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null>(null)
	const benefitsData = useApi<any>('/coins');
	useEffect(() => {
		const ref = firebase.firestore().collection('users').doc(firebase.auth().currentUser?.uid)
		ref.get().then((snapshot) => {
			setUserProfile(snapshot)
		})
	}, [])

	return (
		<Box className={"greenModalStyle"} style={{paddingTop: '50px'}}>
			<Header
				title={""}
				align={"center"}
			/>
			<Box margin={3}>
				<Grid item xs={12}>
					<Typography align="center">
						<img src="/images/the_Stellar_logo.png" alt="" style={{width: '80%'}}/>
					</Typography>
				</Grid>
			</Box>
			<Divider className={classes.h20}></Divider>

			<Box margin={2}>
				<Grid container>
					<Grid item xs={12}>
						<Typography align="center">
							Questo è il tuo QR code identificativo.<br/>
							Potrai utilizzarlo per ritirare i tuoi ordini e usufruire
							delle scontistiche a te riservate.
						</Typography>
					</Grid>
				</Grid>

				<Divider className={classes.h60}></Divider>

				<Grid container>
					<Grid item xs={12} className={classes.qrCodeContainer}>
						<Suspense fallback={<LinearProgress/>}>
							<ErrorBoundary>
								<Typography align="center">
									{userProfile?.get('fidelity') &&
										<QRCode value={userProfile?.get('fidelity')} size={200}
												className={classes.whiteBorder}></QRCode>
									}
								</Typography>
							</ErrorBoundary>
						</Suspense>
					</Grid>
				</Grid>
				<Divider className={classes.h40}></Divider>
				{(benefitsData && benefitsData.data) &&
					<Grid container justifyContent="center">
						<Grid item xl={12}>
							<a href={benefitsData.data.fidelity_links.apple_pass_url} target="_blank">
								<img
									src="/images/button-one-line-large_2x%201@2x.png"
									alt="Add to apple pass"
									style={{width: '300px'}}/>
							</a>
							<Divider className={classes.h10}></Divider>
						</Grid>

						<Grid item xl={12}>
							<a href={benefitsData.data.fidelity_links.android_wallet_url} target="_blank">
								<img
									src="/images/enGB_add_to_google_wallet_wallet-button%201@2x.png"
									alt="Add to google wallet"
									style={{width: '300px'}}/>
							</a>
						</Grid>
					</Grid>
				}
			</Box>
		</Box>
	)
}

export default QRCodeTab

