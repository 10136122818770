import {SET_NOTIFICATIONS, UNSET_NOTIFICATIONS, NotificationsActionTypes} from './types'
import  {Notification} from '~/models/index'

export function setNotifications(notifications: Notification[]): NotificationsActionTypes {
	return {
		type: SET_NOTIFICATIONS,
		payload: notifications
	}
}

export function unsetNotifications(): NotificationsActionTypes {
	return {
		type: UNSET_NOTIFICATIONS,
		payload: null
	}
}
