import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as BookMeetingRoomIconSVG } from '~/assets/images/icons/booking-meeting.svg'


export function MeetingIcon(props: SvgIconProps) {
	return (
		<>
			<SvgIcon {...props}>
				<BookMeetingRoomIconSVG />
			</SvgIcon>
		</>
	)
}