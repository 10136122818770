import {
	Box,
	Card,
	CardHeader,
	LinearProgress,
	Typography,
} from '@material-ui/core'
import React, { Suspense } from 'react'

import ErrorBoundary from '~/components/error-boundary'

import TodayBookingsList from './bookings-list'
import EventsGridList from './events-grid-list'
import RoomsLabelButton from './rooms-label-button'

const HomeTab: React.FC = () => (
	<Box paddingBottom={16}>
		<ErrorBoundary>
			<Suspense fallback={<LinearProgress />}>
			<EventsGridList />
			</Suspense>
		</ErrorBoundary>

		<Box padding={2}>
			<Typography gutterBottom variant='h5' className={'home'}>
				Le tue shortcut
			</Typography>
			<RoomsLabelButton />
		</Box>

		<Box padding={1}>
			<Card variant='outlined' className={'home MuiCard-rounded'}>
				<CardHeader title='Meeting room attive' />
				<ErrorBoundary>
					<Suspense fallback={<LinearProgress />}>
						<TodayBookingsList />
					</Suspense>
				</ErrorBoundary>
			</Card>
		</Box>
	</Box>
)

export default HomeTab
