import {Box, Divider, Grid, LinearProgress,} from '@material-ui/core'
import React, {Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import Flex from '~/components/flex'
import ErrorBoundary from '~/components/error-boundary'
import Header from "~/components/header";
import InviteToTeam from "~/routes/dashboard/profile/team/invite";
import TeamList from "~/components/team-list/team-list";
import {useSelector} from "react-redux";
import {RootState} from "~/store";

const TeamTab: React.FC = () => {
	const profileInfo = useSelector((state: RootState) => {return state?.user?.user;});
	const loading = useSelector((state: RootState) => {return state?.user?.loading;});

	return (
		<Grid container justifyContent={"center"}>
				<Grid item xs={12} md={6}>
					<Flex paddingTop={2} paddingBottom={4}>
						<Box>
							<Header title='Team' className={"modalHeader"}/>
							<Divider/>
						</Box>
						<Suspense fallback={<LinearProgress/>} >
							<ErrorBoundary>
							{ !loading && profileInfo && <TeamList teamData={profileInfo.Teams[0]} idUser={profileInfo.Id}/>}
							</ErrorBoundary>
						</Suspense>
						<Divider/>
					</Flex>
				</Grid>
		</Grid>
	)
}

const TeamSwitch: React.FC = () => (
	<Routes>
		<Route element={<TeamTab/>} path='/'/>
		<Route path='invite'>
			<Route element={<InviteToTeam/>} path=":id"/>
		</Route>
	</Routes>
)

export default TeamSwitch

