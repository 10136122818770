// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import {setNotifications} from "~/store/notifications/actions";
import { Notification } from '~/models'
import {store} from '~/store'
import moment from 'moment'


export const GetUserLastNotifyTabOpen = async () : Promise<moment.Moment|null> => {
    const user = await firebase.auth().currentUser
    if (user){

        const firebaseUser =await firebase
            .firestore()
            .collection('users')
            .doc(user.uid).get()
        return moment(firebaseUser.get('lastNotifyTabOpen'))
    }
    return null
}

export const SetUserLastNotifyTabOpen = async () : Promise<void> =>{
    const user = await firebase.auth().currentUser
    if (user){
        await firebase
            .firestore()
            .collection('users')
            .doc(user.uid).update({lastNotifyTabOpen:moment().toString()})
    }
}

export const GetNotifications = async () : Promise<Notification[]> => {

    const notifications = store.getState().notifications.notifications

    if (!notifications){

        const nots: Notification[] = []
        await firebase.firestore().collection('notifications').orderBy("createDate", 'desc').limit(15)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    nots.push(new Notification(data.title, data.pubDate, data.content, data.contentSnippet, data.description, data.link, data.guid, data.createDate, data.imgUrl, data.type))
                })
            })
        setNotifications(nots)
        return nots
   }

    return notifications
}
