import { Box, Grid, GridProps, useTheme } from '@material-ui/core'
import { CalendarCheck, Dumbbell, ProjectorScreen } from 'mdi-material-ui'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from "react-redux";
import { RootState } from "~/store";
import LabelButton from '~/components/label-button'
import { GYM_ROOM_COLOR } from '~/theme/palette'
import {
	BookMeetingEventIcon,
	BookMeetingRoomIcon,
	BookDailyPassIcon,
	UserManualShortcutIcon,
	ConventionsShortcutIcon,
	CowoShortcutIcon
} from "~/components/icons/shortcut-icons";
import useBreakpoint from '~/hooks/use-breakpoint'
const BREAKPOINT_MIN_COLS = 1.25
const RoomsLabelButton: React.FC<GridProps> = (props) => {
	const breakpoint = useBreakpoint()
	const theme = useTheme()
	const cols =  theme.breakpoints.keys.indexOf(breakpoint) + BREAKPOINT_MIN_COLS
	const profileInfo = useSelector((state: RootState) => { return state?.user?.user; });
	const handleClickOnUserManual = () => {
		window.open('https://docs.nanabianca.it/', '_blank');
	}
	const handleClickOnConventions = () => {
		window.open('https://docs.nanabianca.it/convenzioni', '_blank');
	}
	const handleClickOnCowo = () => {
		window.open('https://docs.nanabianca.it/network', '_blank');
	}
	const canBookFlexi = useCallback(() => {
		return (profileInfo && profileInfo.TariffNames !== null && profileInfo.TariffNames.search("Flexi") > -1)
	}, [profileInfo])
	
	return (
		<>
			<Grid {...props} container spacing={3} style={{overflowX: 'scroll', maxHeight: '150px', flexWrap: 'nowrap', scrollSnapType: 'x mandatory',}}>
				<Grid item xs={12}>
					<LabelButton
						color='primary'
						component={Link}
						label='Meeting room'
						to='/bookings/meeting'
					>

						<BookMeetingRoomIcon fontSize={"large"} />
					</LabelButton>
				</Grid>

				 {/* <Grid item>
			<Box color={GYM_ROOM_COLOR}>
				<LabelButton
					color='inherit'
					component={Link}
					label='Gym room'
					to='/bookings/gym'
				>
					<Dumbbell />
				</LabelButton>
			</Box>
		</Grid> */}


				{(canBookFlexi() &&
					<Grid item xs={12}>
						<LabelButton
							color='secondary'
							component={Link}
							label='Flexi desk'
							to='/bookings/dailypass'
						>
							<BookDailyPassIcon />
						</LabelButton>
					</Grid>
				)}

				<Grid item xs={12}>
					<LabelButton
						color='secondary'
						onClick={handleClickOnUserManual}
						label='User Manual'
					>
						<UserManualShortcutIcon />
					</LabelButton>
				</Grid>
				<Grid item xs={12}>
					<LabelButton
						color='secondary'
						onClick={handleClickOnConventions}
						label='Convenzioni'
					>
						<ConventionsShortcutIcon />
					</LabelButton>
				</Grid>
				<Grid item xs={12}>
					<LabelButton
						color='secondary'
						onClick={handleClickOnCowo}
						label='Network'
					>
						<CowoShortcutIcon />
					</LabelButton>
				</Grid>

			</Grid>
		</>
	)

}

export default RoomsLabelButton
