import { Avatar, Box } from '@material-ui/core'
import PropTypes, { InferProps } from 'prop-types'
import React, {useState, useCallback, useEffect } from 'react'

import RoomIcon from '~/components/room-icon'
import { GYM_ROOM_ID } from '~/constants'
import { GYM_ROOM_COLOR } from '~/theme/palette'
import { DAILYPASS_FIRST_FLOOR_ID, DAILYPASS_SECOND_FLOOR_ID, DAILYPASS_THIRD_FLOOR_ID } from "~/constants";
import { ReactComponent as DailyPassIconSVG } from '~/assets/images/icons/dailypass.svg'


const getBackgroundColor = (resourceId: number): string => {
	switch (resourceId) {
		case GYM_ROOM_ID:
			return GYM_ROOM_COLOR

		default:
			return 'default'
	}
}

const RoomAvatarProps = {
	resourceId: PropTypes.number.isRequired,
	resourceTypeId: PropTypes.string
}

const checkResouceType = (resourceTypeId: any) => {
	if (resourceTypeId!== undefined && 
		(resourceTypeId=== 'desk' ||
			resourceTypeId === String(DAILYPASS_FIRST_FLOOR_ID) || 
			resourceTypeId === String(DAILYPASS_SECOND_FLOOR_ID) || 
			resourceTypeId === String(DAILYPASS_THIRD_FLOOR_ID))){
				return true
			}
	return false

}

const RoomAvatar: React.FC<InferProps<typeof RoomAvatarProps>> = ({
	resourceId,
	resourceTypeId
}) => {
	const [isDesk, setIsDesk] = useState(checkResouceType(resourceTypeId))
	const bgcolor = getBackgroundColor(resourceId)

	return (
		<Box bgcolor={bgcolor} clone>
			<Avatar variant="square" style={{backgroundColor: "transparent", width: "46px", height: "46px"}}>
				{!isDesk &&(
					<RoomIcon resourceId={resourceId}/>
				)}
				{isDesk &&(
					<DailyPassIconSVG/>
				)}
			</Avatar>
		</Box>
	)
}

RoomAvatar.propTypes = RoomAvatarProps

export default RoomAvatar
