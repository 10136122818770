import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import USERMANUAL from '~/assets/images/icons/user-manual.png'
import USERMANUAL_2x from '~/assets/images/icons/user-manual@2x.png'
import { ReactComponent as UserManualIcoSvg } from '~/assets/images/icons/user-manual.svg'

export function UserManualIcon(props: SvgIconProps) {
	return (
		<>
			<SvgIcon {...props}>
				<UserManualIcoSvg />
			</SvgIcon>
		</>
	)
}