import React, {useState} from "react";
import {Backdrop, Box, Button, CircularProgress, FormControl, Grid, Input, InputLabel} from "@material-ui/core";
import ApiInstance from "~/api";
import ErrorSnackbar from "~/components/error-snackbar";
import Header from "~/components/header";

interface AccountHealth {
	user_firebase_exists: boolean;
	contact_exists: boolean;
	user_exists: boolean;
	user_has_credentials: boolean;
	user_password_is_in_sync: boolean;
	coworker_exists: boolean;
	nexudus_user_exists: boolean;
	nexudus_credentials_are_aligned: boolean;
	strooka_user_exists: boolean;
	delivery_addresses_match: boolean;
	strooka_credentials_aligned: boolean;
}

export const AdminAccountHealth: React.FC<unknown> = ({children}) => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const [email, setEmail] = useState("")
	const [accountHealth, setAccountHealth] = useState<AccountHealth | null>(null)

	function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
		const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
		if (expression.test(String(event.target.value).toLowerCase())) {
			setEmail(event.target.value)
		}
	}

	async function validateAccount() {
		setLoading(true)
		setError(null)
		setAccountHealth(null)
		console.log('validate account for ', email)
		try {
			const value = await ApiInstance.post("/admin/account-health", {email: email})
			setAccountHealth(value.data as AccountHealth)
		} catch (error) {
			setError(`Impossible to fetch account informations ${error}`)
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	async function repairAccount() {
		setLoading(true)
		setError(null)
		console.log('repair account for ', email)
		try {
			const value = await ApiInstance.post("/admin/account-repair", {email: email})
			console.log(value.data)
			await validateAccount()
		} catch (error) {
			setError(`Impossible to repair account ${error}`)
			console.error(`Impossible to repair account ${error}`)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<Header title="User account health" className={"modalHeader"}/>

			<Box padding={1}>
				<ErrorSnackbar error={error}/>
				<Backdrop open={loading}><CircularProgress color='inherit'/></Backdrop>
				<Grid container>
					<Grid item>
						<FormControl>
							<InputLabel htmlFor="email">Email address</InputLabel>
							<Input id="user-email-address" aria-describedby="User email address"
								   onChange={handleEmailChange} type={"email"} autoComplete={"off"}/>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item>
						<Button variant="contained" color="primary" disabled={!email} onClick={validateAccount}>
							Verify
						</Button>
					</Grid>
				</Grid>

				{accountHealth && (
					<Box marginTop={5}>
						<Grid container>
							<Grid item xs={10}>Firebase user exists</Grid>
							<Grid item xs={2}>{accountHealth?.user_firebase_exists ? "yes" : "no"}</Grid>

							<Grid item xs={10}>User exists</Grid>
							<Grid item xs={2}>{accountHealth?.user_exists ? "yes" : "no"}</Grid>

							<Grid item xs={10}>User has credentials</Grid>
							<Grid item xs={2}>{accountHealth?.user_has_credentials ? "yes" : "no"}</Grid>

							<Grid item xs={10}>Coworker exists</Grid>
							<Grid item xs={2}>{accountHealth?.coworker_exists ? "yes" : "no"}</Grid>

							<Grid item xs={10}>Nexudus user exists</Grid>
							<Grid item xs={2}>{accountHealth?.nexudus_user_exists ? "yes" : "no"}</Grid>

							<Grid item xs={10}>Nexudus credentials are aligned</Grid>
							<Grid item xs={2}>{accountHealth?.nexudus_credentials_are_aligned ? "yes" : "no"}</Grid>

							<Grid item xs={10}>Strooka user exists</Grid>
							<Grid item xs={2}>{accountHealth?.strooka_user_exists ? "yes" : "no"}</Grid>

							<Grid item xs={10}>Strooka addresses match Nexudus ones</Grid>
							<Grid item xs={2}>{accountHealth?.delivery_addresses_match ? "yes" : "no"}</Grid>

							<Grid item xs={10}>Strooka credentials are aligned</Grid>
							<Grid item xs={2}>{accountHealth?.strooka_credentials_aligned ? "yes" : "no"}</Grid>
						</Grid>

						<Button variant="contained" color="primary" onClick={repairAccount}>
							Repair
						</Button>

					</Box>

				)}
			</Box>
		</>
	)
}
