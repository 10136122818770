import {AppBar, AppBarProps, IconButton, makeStyles, Toolbar, Typography,} from '@material-ui/core'

import clsx from 'clsx'
import {ArrowLeft} from 'mdi-material-ui'
import PropTypes, {InferProps, string} from 'prop-types'
import React, {useCallback} from 'react'
import {Helmet} from 'react-helmet'
import {useNavigate, useLocation} from 'react-router-dom'

import SlideOnScroll from './slide-on-scroll'

const useStyles = makeStyles(() => ({
	appBar: {
		paddingTop: '8px',
	},
	title: {
		flexGrow: 1,
		fontSize: '24px!important'
	},
}))

const HeaderBackButton: React.FC = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const handleClick = useCallback(() => {
		navigate(-1)
	}, [navigate])

	return (
		<IconButton color='inherit' edge='start' onClick={handleClick}>
			<ArrowLeft htmlColor={location.pathname.match(/\/profile\/qrcode/) ? '#ffffff': '#0A24EB'}/>
		</IconButton>
	)
}

const HeaderProps = {
	endButton: PropTypes.element,
	startButton: PropTypes.element,
	title: PropTypes.string,
	align: PropTypes.string,
}

const Header: React.FC<AppBarProps & InferProps<typeof HeaderProps>> = ({
	children = null,
	className,
	color = 'inherit',
	endButton = null,
	position = 'sticky',
	startButton = <HeaderBackButton />,
	title,
	align,
	...rest
}) => {
	const classes = useStyles()
	const castedAlign = (align || "inherit") as ("left" | "right" | "inherit" | "center" | "justify" | undefined)

	return (
		<>
			{title && (
				<Helmet>
					<title>
						{title}
					</title>
				</Helmet>
			)}

			<SlideOnScroll>
				<AppBar
					{...rest}
					className={clsx(classes.appBar, className)}
					color={color}
					position={position}
				>
					<Toolbar>
						{startButton}

						<Typography
							className={classes.title}
							variant='h4'
							align={castedAlign}
						>
							{title}
						</Typography>

						{endButton}
					</Toolbar>

					{children}
				</AppBar>
			</SlideOnScroll>
		</>
	)
}

Header.propTypes = HeaderProps

export default Header
