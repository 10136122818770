import React, {
	useState,
	useEffect, Suspense,
} from 'react'
import {
	Grid,
	Button,
	makeStyles, DialogTitle, DialogContent, DialogActions, Dialog, LinearProgress, CircularProgress, Backdrop,
} from '@material-ui/core'

import ApiInstance from '~/api'
import Flex from '~/components/flex'
import {useNavigate} from "react-router";
import useApi from "~/hooks/use-api";
import ErrorBoundary from "~/components/error-boundary";
import Header from "~/components/header";
import {useSearchParam} from "react-use";

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%',
	},
	grid: {
		padding: theme.spacing(3),
	},
	button: {
		width: '100%',
		marginTop: '50px',
	},
}))

const JoinTeam: React.FC = () => {
	const navigate = useNavigate()
	const classes = useStyles()
	const [groupName, setGroupName] = useState<any>(null);
	const [inviteName, setInviteName] = useState<any>(null);

	const [loading, setLoading] = useState(false)
	const [showResultDialog, setShowResultDialog] = useState(false)
	const [dialogText, setDialogText] = useState("");

	const [abilitateSendingForm, setAbilitateSendingForm] = useState(false)

	const [successInvitation, setSuccessInvitation] = useState(false);
	const [description, setDescription] = useState("Caricamento...")

	console.log()
	// TODO gestisci errori? manca codice, sei già nel gruppo, errore invito, invito scaduto

	const invitationTokenPar = useSearchParam('invitationToken');
	console.log(invitationTokenPar)

	//const userData = useApi<any>('/user/profile');

	const getInfo = () => {
		try{
			ApiInstance.get(
				'/team-manager/coworker/invitation/view',{
					cache: false,
					params: { invitationToken: invitationTokenPar },
				}
			).then( (responseView:any) => {
				if (responseView && responseView.data){
					if(responseView.data.teamId){
						try {
							ApiInstance.get(
								'/team-manager/team',{
									cache: false,
									params: { teamId: responseView.data.teamId },
								}
							).then( (response)=> {
								const dataResponse = response.data ? response.data : null;
								console.log(dataResponse);
								setGroupName( dataResponse.Name? dataResponse.Name: null )
								setInviteName(dataResponse.CoworkerBillingNames? dataResponse.CoworkerBillingNames: null);
								// const isInGroup = userData.data.Teams ? userData.data.Teams.filter( (team: any) =>{ return(team.Id === dataResponse.Id)} ): Array(0);
								// console.log(isInGroup, userData);
								// if (isInGroup.length>0){
								// 	setDescription("Fai già parte del gruppo in cui sei stato invitato")
								// } else {
									setAbilitateSendingForm(true);
								// }
							}).catch((e) => {
								console.log(e)
								if (dialogText === "") {
									setShowResultDialog(true);
									setDialogText("si è verificato un errore: l'invito non è corretto");
									setDescription("L'invito non è corretto")
								}
							});
						} catch (e) {
							console.log(e)
							if (dialogText === "") {
								setShowResultDialog(true);
								setDialogText("si è verificato un errore: l'invito non è corretto");
								setDescription("L'invito non è corretto")
							}
						}
					} else {
						if (dialogText === "") {
							setShowResultDialog(true);
							setDialogText("si è verificato un errore: l'invito non è corretto");
							setDescription("L'invito non è corretto")
						}
					}
				} else {
					if (dialogText === "") {
						setShowResultDialog(true);
						setDialogText("si è verificato un errore: l'invito non è corretto");
						setDescription("L'invito non è corretto")
					}
				}
			}).catch((e) => {
				console.log(e)
				if (dialogText === "") {
					setShowResultDialog(true);
					setDialogText("si è verificato un errore: l'invito non è corretto");
					setDescription("L'invito non è corretto")
				}
			});

		} catch (e){
			console.log(e)
			if (dialogText === "") {
				setShowResultDialog(true);
				setDialogText("si è verificato un errore: l'invito non è corretto");
				setDescription("L'invito non è corretto")
			}
		}
	}

	useEffect(()=>{
		getInfo();

		return () => {
			setAbilitateSendingForm(false)
		}
	}, [invitationTokenPar])

	const handleSubmit = () => {
		setLoading(true);
		try {
			ApiInstance.get(
				'/team-manager/coworker/invitation/accept',
				{
					cache:false,
					params: {
						invitationToken: invitationTokenPar
					}
				}
			).then ((response) =>{
				if(response !== undefined){
					const dataResponse = response.data ? response.data : null;
					console.log(dataResponse);
					console.log(response);
					setSuccessInvitation(true)
					setLoading(false);
					setShowResultDialog(true);
					setDialogText("Ti sei iscritto al nuovo gruppo correttamente");


				} else {
					setShowResultDialog(true);
					setDialogText("si è verificato un errore: l'invito non è corretto");
				}
			}).catch( (error) => {
				console.log(error)
				setShowResultDialog(true);
				setDialogText("si è verificato un errore: l'invito non è corretto");
			});

		} catch (error) {
			console.log(error);
			setShowResultDialog(true);
			setDialogText("si è verificato un errore: l'invito non è corretto");

		}
	}
	const handleResultDialogClose = () => {
		setShowResultDialog(false)
		if(successInvitation){
			navigate("/")
		}
	}

	return (
		<>
			<ErrorBoundary>
				<Suspense fallback={<LinearProgress />}>
					<Header title='Invito al Team' className={"modalHeader"}/>
				</Suspense>
			</ErrorBoundary>
			<Dialog onClose={handleResultDialogClose} open={showResultDialog}>
				<DialogTitle>Invito utente</DialogTitle>
				<DialogContent>
					{dialogText}
				</DialogContent>
				<DialogActions>
					<Button color='primary' onClick={handleResultDialogClose}>
						Chiudi
					</Button>
				</DialogActions>
			</Dialog>

			<Flex paddingTop={2} paddingBottom={4}>

					<Grid
						container
						justifyContent='center'
						className={classes.grid}
						spacing={4}
					>
						<Grid item xs={12}>
							Sei stato invitato nel team
							{groupName?<strong> {groupName}</strong> : null}
							<br />
							Clicca qui sotto per confermare la tua partecipazione.<br /><br />
							<b>Importante.</b> Si può essere affiliati ad un solo team per volta. Accettando questa affiliazione verrai automaticamente cancellato da ogni eventuale team precedente.
						</Grid>
						{abilitateSendingForm ? <form className={classes.form}>
							<Grid item xs={12}>
								<Button
									className={classes.button}
									onClick={handleSubmit}
									variant='contained'
									color='primary'
								>
									Unisciti al Team
								</Button>
							</Grid>
						</form> :
						<Grid item xs={12}>
							{description}
						</Grid>
						}
					</Grid>

			</Flex>
			<Backdrop open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</>
	);
}

export default JoinTeam;
