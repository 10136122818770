import React from 'react'
import CardSetupForm from '~/routes/dashboard/profile/wallet/CardSetupForm'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import {STRIPE_PUBLISHABLE_KEY} from "~/settings";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

const WalletAddPaymentMethod: React.FC = () => {
	return (
		<Elements stripe={stripePromise}>
			<CardSetupForm />
		</Elements>
	)
}

export default WalletAddPaymentMethod
