import {combineReducers} from 'redux';
import userStates from "~/store/user/reducers";
import bookingStates from "~/store/bookings/reducers";
import notificationsStates from "~/store/notifications/reducers";
import {configureStore} from "@reduxjs/toolkit";


const reducers = combineReducers({
	user: userStates,
	bookings: bookingStates,
	notifications: notificationsStates
});
export const store = configureStore({
	reducer: reducers
});
export type RootState = ReturnType<typeof reducers>
