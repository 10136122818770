import NbWifiDesktop from '~/components/icons/devices/nb-wifi-desktop'
import NbWifiCast from '~/components/icons/devices/nb-wifi-cast'
import NbWifiBot from '~/components/icons/devices/nb-wifi-bot'
import NbWifiGame from '~/components/icons/devices/nb-wifi-game'
import NbWifiLaptop from '~/components/icons/devices/nb-wifi-laptop'
import NbWifiPhone from '~/components/icons/devices/nb-wifi-phone'
import NbWifiPrinter from '~/components/icons/devices/nb-wifi-printer'
import NbWifiTablet from '~/components/icons/devices/nb-wifi-tablet'
import PropTypes, { InferProps } from 'prop-types'
import React from 'react'
import { SvgIconProps } from '@material-ui/core'
import { DEVICES } from '~/constants'

const DeviceIconProps = {
	resourceId: PropTypes.string.isRequired,
}

const DeviceIcon: React.FC<InferProps<SvgIconProps & typeof DeviceIconProps>> = ({
	resourceId,
	...rest
}) => {
	switch (resourceId) {
		case DEVICES.DESKTOP:
			return <NbWifiDesktop {...rest}/>
		case DEVICES.LAPTOP:
			return <NbWifiLaptop {...rest}/>
		case DEVICES.PHONE:
			return <NbWifiPhone {...rest}/>
		case DEVICES.TABLET:
			return <NbWifiTablet {...rest}/>
		case DEVICES.GAME:
			return <NbWifiGame {...rest}/>
		case DEVICES.CAST:
			return <NbWifiCast {...rest}/>
		case DEVICES.PRINTER:
			return <NbWifiPrinter {...rest}/>
		case DEVICES.BOT:
			return <NbWifiBot {...rest}/>
		default:
			return <NbWifiPhone {...rest}/>
	}
}

export default DeviceIcon
