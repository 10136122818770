import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

import { ReactComponent as ConventionsIcoSvg } from '~/assets/images/icons/conventions2.svg'

export function ConventionsIcon(props: SvgIconProps) {
	return (
		<>
			<SvgIcon {...props}>
				<ConventionsIcoSvg />
			</SvgIcon>
		</>
	)
}
