import React, { useState } from "react";

import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Grid,
    TextField
} from "@material-ui/core";
import { Send } from 'mdi-material-ui';
import ErrorSnackbar from "~/components/error-snackbar";
import { Notification, } from '~/models'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import Header from '~/components/header'


export const SendNotification: React.FC<unknown> = ({ children }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    //const [img_url, setContent] = useState()
    const handleSendNotification = async() => {
        setLoading(true)
		setError(null)
        const id = uuid()
        const link = `https://${window.location.host}/notifications?notification_id=${id}`
        const not = {
            title:title,
            createDate: moment().unix(),
            content: content,
            guid: id,
            pubDate:moment().toString(),
            imgUrl: '',
            link: link,
			type: 'SYSTEM'
        }
        try{
            await firebase
            .firestore()
            .collection('notifications')
            .doc(id).set(not)

        }catch(error){
            setError(`Impossible to send ${error}`)
        } finally {
			setLoading(false)
            setTitle('')
            setContent('')
		}
    }

    return (
        <>
            <Header title='Send Notification' className={"modalHeader"}/>
            <Box padding={1}>
                <ErrorSnackbar error={error} />
                <Backdrop open={loading}><CircularProgress color='inherit' /></Backdrop>
                <h1>Send Notification</h1>
                <Grid container spacing={2}>
                    <Grid item xs={12} xl={12}>
                        <TextField
                            label="Title"
                            variant="filled"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} xl={12}>
                        <TextField
                            minRows={3}
                            multiline={true}
                            variant="filled"
                            label="Content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />

                    </Grid>
                    <Grid item xs={12} xl={12}>
                        <Button variant="contained" color="primary" disabled={!title} type='submit' onClick={handleSendNotification}>
                            <Send fontSize={"small"} /> Send
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
