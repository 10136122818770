import { LinearProgress } from '@material-ui/core'
import React, { Suspense } from 'react'

import ErrorBoundary from '~/components/error-boundary'
import EventsGrid from './grid'
import BookingsFab from '~/components/bookings-fab'

const EventsTab: React.FC = () => {
	return (
		<>
			<ErrorBoundary>
				<Suspense fallback={<LinearProgress />}>
					<EventsGrid />
				</Suspense>
			</ErrorBoundary>
		</>
	)
}

export default EventsTab
