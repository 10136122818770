import React, {Suspense, useEffect, useState} from 'react'
import Header from '~/components/header'
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	LinearProgress,
	ListItemAvatar,
	makeStyles,
	Typography
} from '@material-ui/core'
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app'
import {DEVICES} from '~/constants'
import {useNavigate, useParams} from 'react-router-dom'
import DeviceAvatar from '~/components/device-avatar'
import {Close} from 'mdi-material-ui'
import {Device} from '~/models'
import PasswordManager from '~/components/password-manager'

const useStyles = makeStyles((theme) => {
    return {
        grid:{
            marginTop: '24px',
        },
        gridItem:{
            display: 'grid',
        },
        deviceInfo:{
            marginBottom: '45px',
            display: 'grid',
        },
        buttonGrid:{
            marginTop: '90px',
            padding: '10px',
        },
        buttonDelete:{
            marginTop: '20px',
        },
        buttonUpdate:{
            marginBottom: '20px',
            marginTop: '24px',
        },
        deviceName:{
            display: 'grid',
            paddingLeft: '25px !important',
        },
    }
})

const getUserUid = async () => {
    const user = await firebase.auth().currentUser

	return user!.uid
}

const getDevice = async (documentId: string) => {
    const userUid = await getUserUid()

    const documentRef = firebase
    .firestore()
    .collection('network-credentials')
    .doc(userUid)
    .collection('credentials-list')
    .doc(documentId)
	let device = new Device('', '', '', '')
	const document = await documentRef.get()
    const data = document.data()
    if (data)
        device = new Device(document.id, data.deviceName, data.deviceIcon, data.username)

    return device
}

const deleteDevice = async (documentID: string) =>{
    const user = await firebase.auth().currentUser
    if (user){
        const documentRef = firebase
		.firestore()
		.collection('network-credentials')
		.doc(user.uid)
        .collection('credentials-list' )

        await documentRef.doc(documentID).delete()
    }

}

const PasswordUpdate: React.FC = () => {
    const {documentId} = useParams()
    const [device, setDevice] = useState<Device>()
    const [loading, setLoading] = useState(true)
    const [passwordManager, setPasswordManager] = useState(false)
    const [confirmDialog, setConfirmDialog] = useState(false)
    const navigate = useNavigate()

    const classes = useStyles()

    const [icons] = useState([
        DEVICES.DESKTOP,
        DEVICES.LAPTOP,
        DEVICES.PHONE,
        DEVICES.TABLET,
        DEVICES.GAME,
        DEVICES.CAST,
        DEVICES.PRINTER,
        DEVICES.BOT,
    ])

    const handleDevice = async () => {
        if(documentId){
            const data = await getDevice(documentId)
                if (data){
                    setDevice(data)
                }
                setLoading(false)
        }
    }

    const updatePasswordClick = () =>{
        setPasswordManager(true)
    }

    const confirmDialogClose = () => {
        setConfirmDialog(false)
    }

    const confirmDialogOk = () => {
        setConfirmDialog(false)
        if(device){
            deleteDevice(device.documentId)
            navigate(-1)
        }
    }

    const deleteDeviceClick = async () =>{
        setConfirmDialog(true)
    }

    useEffect(() => {
            handleDevice()
    }, [])

    const deviceIcon = () => {
        if (device)
            return( <DeviceAvatar style={{ fontSize: 60 }}
            resourceId={device.deviceIcon}
        />)
        return <></>
    }

    const passworManager = () => {
        if (device){
            return <PasswordManager open={passwordManager}
            deviceName={device.deviceName}
            username={device.username}
            deviceIcon={device.deviceIcon}
            documentId={device.documentId}/>
        }
    }

    return (
        <>
            <Header title='Gestisci password' className={"modalHeader"}/>
            <Backdrop  open={loading}>
				<CircularProgress color='inherit' />
			</Backdrop>

            {passworManager()}

            <Suspense fallback={<LinearProgress/>}>
             <Box className={"gridUpdatePassword"} paddingX={1}>
                <Grid container /* className='gridUpdatePassword' */>
                    <Grid item xs={12} className={classes.gridItem}>
                        <ListItemAvatar>
                                {deviceIcon()}
                            </ListItemAvatar>
                    </Grid>
                    <Grid item xs={12} className={classes.deviceInfo}>
                        <Typography variant='subtitle1'>{device?.deviceName}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonGrid}>
                        <Typography color='textPrimary' variant='subtitle2'>
							Se hai dimeticato la tua password non ti preoccupare.
                            Premi “Rinnova la tua password” per crearne una nuova
                        </Typography>
                    </Grid>
                    <Grid item  xs={12} className={classes.gridItem}>
                        <Button
                            onClick={() => updatePasswordClick()}
                            color="primary"
                            className={classes.buttonUpdate}
                        >
                            Rinnova la tua password
						</Button>
                    </Grid>
                    <Grid item xs={12}>
                    <Divider/>
                        <Button
                            onClick={() => deleteDeviceClick()}
                            startIcon={<Close htmlColor="#8F0232"/>}
                            className={classes.buttonDelete}
                        >
                            Elimina dispositivo
						</Button>
                    </Grid>
                </Grid>
                </Box>
            </Suspense>

            <Dialog
                open={confirmDialog}
                onClose={confirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
        <DialogTitle id="alert-dialog-title">{"Vuoi cancellare il tuo dispositivo?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} className={classes.gridItem}>
                        Se rimuoverai il tuo dispositivo perderai l’accesso a internet.
                    </Grid>
                    <Grid item xs={4} >
                        <ListItemAvatar>
                                {deviceIcon()}
                            </ListItemAvatar>
                    </Grid>

                    <Grid item xs={8} >
                        <Typography variant="button">{device?.deviceName}</Typography>
                    </Grid>
            </Grid>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {confirmDialogClose()}} color="secondary">
            Indietro
          </Button>
          <Button onClick={() => {confirmDialogOk()}} color="primary" autoFocus>
            Si, cancella
          </Button>
        </DialogActions>
      </Dialog>

        </>
    )
}

export default PasswordUpdate
